import { Result } from "neverthrow";
import ReportGenerator from "./ReportGenerator";
import ReportData from "../ReportData";
import TextReportOutput from "./TextReportOutput";
import ValidationError from "../../errors/ValidationError";

abstract class TextReportGenerator extends ReportGenerator {
  generate(
    data: ReportData,
    version: string | undefined = this.defaultVersion,
  ): Result<TextReportOutput, ValidationError> {
    return this.checkRequiredFields(data)
      .map((validatedData: ReportData) => this.getReportText(validatedData, version))
      .map((text) => new TextReportOutput(text));
  }

  protected abstract getReportText(data: ReportData, version: string | undefined): string;
}

export default TextReportGenerator;
