import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import DouglasSeaScale from "../../../../../../domain/units/DouglasSeaScale";

const fieldName = "Sea State";

const SeaStateFormItemProvider: FormItemProvider<DouglasSeaScale> = (props: FormItemProviderProps<DouglasSeaScale>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="SeaState"
    inputToResult={notEmptyInputToResultWrapper(fieldName, DouglasSeaScale.fromNumber)}
    outputToInput={unwrapValue}
    allowNegative={false}
    integersOnly={true}
    inputsEqual={eqeqeq}
    addonAfter="douglas"
  />
);

export default React.memo(SeaStateFormItemProvider);
