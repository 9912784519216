export const dedent = (str: string): string => {
  const indent = str.match(/^\n([ \t])+/)?.[0];
  if (indent === undefined) {
    return str;
  }
  return str.replace(new RegExp(indent, "gm"), "\n").trim();
};

export const indent = (str: string, amount: number, tabs: boolean = false): string => {
  return str.replace(/(\n|^)([^\n]+)/g, `$1${(tabs ? "\t" : " ").repeat(amount)}$2`);
};