import React, {
  useMemo,
  useState,
} from "react";
import { Route, Switch } from "react-router-dom";
import { Spin } from "antd";
import { SyncContext } from "./hooks/useSync";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Routes from "./routes";
import MainPage from "./MainPage";
import LoginPage from "./LoginPage";
import { runMigrations } from "./runMigrations";

const startup = async (): Promise<void> => {
  await runMigrations();
};

const App: React.FC = () => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useMemo(() => {
    setIsLoading(true);
    startup()
      .then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <SyncContext.Provider
      value={{ isSyncing, setIsSyncing }}
    >
      <Switch>
        <Route path={Routes.Login.template} component={LoginPage} exact={Routes.Login.exact} />
        <AuthenticatedRoute>
          <MainPage />
        </AuthenticatedRoute>
      </Switch>
    </SyncContext.Provider>
  );
};

export default App;
