import React from "react";
import ErrorWrapper from "../../../ErrorWrapper";
import {
  useSingleFormItem,
  UseSingleFormItemProps,
} from "../../../useSingleFormItem";
import { FormItemProps } from "../../../FormItem";
import SelectInput from "../../../SelectInput";
import { Options } from "../../../../Options";
import CargoUnit from "../../../../../../domain/CargoUnit";

type Props =
  & UseSingleFormItemProps<CargoUnit, CargoUnit>
  & FormItemProps<CargoUnit>
  & { options: Options<CargoUnit> };

const UnitSelect: React.FC<Props> = (props) => {
  const hook = useSingleFormItem(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <SelectInput
        isError
        onChange={hook.onInputChange}
        options={props.options}
        placeholder="Unit"
        value={hook.input}
      />
    </ErrorWrapper>
  );
};

export default UnitSelect;