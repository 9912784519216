import {
  err,
  ok,
  Result,
} from "neverthrow";
import qs from "qs";
import HttpMethod from "./HttpMethod";
import FetchErrors from "../../shared/errors/FetchErrors";
import HttpStatusError from "../../shared/errors/HttpStatusError";
import NetworkError from "../../shared/errors/NetworkError";
import minimumDelay from "../../shared/utils/minimumDelay";
import {
  deserialize,
  SerializationError,
  serialize,
} from "../../shared/utils/serialization";

import {
  GetAisPositionRequest,
  GetAisPositionResponse,
} from "../../server/crew/position/GetAisPosition";

import BaseError from "../../shared/errors/BaseError";
import {
  SyncReportsRequest,
  SyncReportsResponse,
} from "../../server/crew/reports/SyncReports";
import { showErrorNotification } from "./utils/notifications";
import {
  PingRequest,
  PingResponse,
} from "../../server/Ping";
import {
  SendActivationCodeRequest,
  SendActivationCodeResponse,
} from "../../server/crew/auth/SendActivationCode";
import {
  GetAuthTokenRequest,
  GetAuthTokenResponse,
} from "../../server/crew/auth/GetAuthToken";
import { getLocalAuthToken } from "./auth";
import { getLocalVersion } from "./localVersion";
import { SendActivationNotificationResponse } from "../../server/crew/auth/SendActivationNotification";

const BASE_URL = process.env.REACT_APP_API_URL;

type NotificationProps =
  | { showErrorNotification: boolean, notificationTitle: string }
  | { showErrorNotification?: false, notificationTitle?: never };

type RequestOptions = NotificationProps & {};

const trimLeadingSlash = (str: string): string => str.replace(/^\//, "");

export const makeRequest = async <Req, Res>(
  method: HttpMethod,
  path: string,
  originalRequest: Req,
  options: RequestOptions & {
    includeRequestInQuery?: boolean,
  } = {},
): Promise<Result<Res, FetchErrors>> => {
  const serializedRequestResult = serialize(originalRequest);
  if (serializedRequestResult.isErr()) {
    return err(serializedRequestResult.error);
  }

  const canHaveBody = (
    method === HttpMethod.POST ||
    method === HttpMethod.PUT ||
    method === HttpMethod.PATCH
  );
  let url = `${BASE_URL}/${trimLeadingSlash(path)}`;
  if (!canHaveBody && options.includeRequestInQuery) {
    url += `?${qs.stringify(serializedRequestResult.value)}`;
  }

  const localVersion = await getLocalVersion();

  let headers: HeadersInit = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Local-Version": localVersion != null ? `v${String(localVersion)}` : "unknown version",
  };

  const authToken = getLocalAuthToken();
  if (authToken !== null) {
    headers = {
      ...headers,
      "Authorization": `Bearer ${authToken}`,
    };
  }

  const fetchOptions: RequestInit = {
    method,
    body: canHaveBody ? JSON.stringify(serializedRequestResult.value) : undefined,
    headers,
  };

  const checkResponse = async (response: Response): Promise<Result<Res, FetchErrors>> => {
    let body;
    try {
      body = await response.json();
    } catch (error) {
      return err(new HttpStatusError(response.status, response.statusText));
    }
    if (response.ok) {
      return deserialize(body as Res)
        .andThen<Res, SerializationError>(ok);
    }
    const bodyError: BaseError | undefined = body?.error;
    const message = bodyError?.userFacing ? bodyError.message : response.statusText;
    return err(new HttpStatusError(
      response.status,
      message,
      {
        body,
        userFacing: bodyError?.userFacing,
      },
    ));
  };

  let response;
  try {
    response = await minimumDelay(
      fetch(url, fetchOptions),
      process.env.NODE_ENV === "production" ? 0 : 200,
    );
  } catch (error) {
    const networkError = new NetworkError({
      innerError: error as Error,
    });
    if (options.showErrorNotification) {
      showErrorNotification(options.notificationTitle, networkError);
    }
    return err(networkError);
  }

  return (await checkResponse(response))
    .mapErr((error) => {
      if (options.showErrorNotification) {
        showErrorNotification(options.notificationTitle, error);
      }
      return error;
    });
};

/* ------------------------------------------------------------ */

export const ping = (): Promise<Result<PingResponse, FetchErrors>> => {
  return makeRequest(HttpMethod.GET, `/ping`, new PingRequest());
};

export const syncReports = (
  request: SyncReportsRequest,
  options?: RequestOptions,
): Promise<Result<SyncReportsResponse, FetchErrors>> => {
  return makeRequest(HttpMethod.POST, `/crew/reports/sync`, request, options);
};

export const getAisPosition = (
  request: GetAisPositionRequest,
  options?: RequestOptions,
): Promise<Result<GetAisPositionResponse, FetchErrors>> => {
  return makeRequest(HttpMethod.GET, `/crew/position/${request.imoNumber.value}`, request, options);
};

export const sendActivationCode = (
  request: SendActivationCodeRequest,
  options?: RequestOptions,
): Promise<Result<SendActivationCodeResponse, FetchErrors>> => {
  return makeRequest(HttpMethod.POST, `/crew/auth/sendActivationCode`, request, options);
};

export const getAuthToken = (
  request: GetAuthTokenRequest,
  options?: RequestOptions,
): Promise<Result<GetAuthTokenResponse, FetchErrors>> => {
  return makeRequest(HttpMethod.POST, `/crew/auth/getAuthToken`, request, options);
};

export const sendActivationNotification = (
  request: SendActivationCodeRequest,
  options?: RequestOptions,
): Promise<Result<SendActivationNotificationResponse, FetchErrors>> => {
  return makeRequest(HttpMethod.POST, "/crew/auth/sendActivationNotification", request, options);
};
