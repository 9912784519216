import React, {
  useCallback,
  useContext,
} from "react";
import { syncWithServer } from "../localReportStorage";
import logger from "../../../shared/utils/logger";
import NetworkError from "../../../shared/errors/NetworkError";

type UseSyncHook = {
  isSyncing: boolean,
  sync: () => Promise<void>,
};

type SyncContextType = {
  isSyncing: boolean,
  setIsSyncing:(newIsSyncing: boolean) => void
};

const syncContextDefaultValue: SyncContextType = {
  isSyncing: false,
  setIsSyncing: () => {},
};

export const SyncContext = React.createContext<SyncContextType>(syncContextDefaultValue);

export const useSync = (): UseSyncHook => {
  const { isSyncing, setIsSyncing } = useContext(SyncContext);

  const sync = useCallback(async (): Promise<void> => {
    const handleError = (error: Error): void => {
      if (error instanceof NetworkError) {
        logger.warn("Sync failed. We are offline.");
      } else {
        logger.error("Sync failed.", error);
      }
    };

    try {
      setIsSyncing(true);
      const result = await syncWithServer();
      if (result.isErr()) {
        handleError(result.error);
        return;
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSyncing(false);
    }
  }, []);
  
  return {
    isSyncing,
    sync,
  };
};