import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<Hours>()
export class Hours extends NumberUnit {
  static serialize(unit: Hours): Result<Serialized, SerializationError> {
    return super._serialize(unit, Hours);
  }

  static deserialize(serialized: Serialized): Result<Hours, SerializationError> {
    return super._deserialize(serialized, Hours);
  }
}