import React, {
  useCallback,
  useEffect,
} from "react";
import {
  Grid,
  Popconfirm,
  Select,
} from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import styled from "styled-components";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import {
  err,
  ok,
  Result,
} from "neverthrow";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import {
  DirtyInfo,
  FormItemProps,
} from "../../FormItem";
import NumericInput from "../../../misc-components/NumericInput";
import IconButton from "../../../misc-components/IconButton";
import {
  removeAtIndex,
  replaceAtIndex,
} from "../../../../../shared/utils/array";
import RequiredError from "../../../../../domain/errors/RequiredError";
import ErrorWrapper from "../../ErrorWrapper";
import ValidationError from "../../../../../domain/errors/ValidationError";
import ConsumptionItem from "../../../../../domain/ConsumptionItem";
import FuelType, { fuelTypeOptions } from "../../../../../domain/FuelType";
import EngineType from "../../../../../domain/EngineType";
import ConsumptionReason from "../../../../../domain/ConsumptionReason";
import {
  Id,
  newId,
} from "../../../../../domain/Id";
import { deepEqual } from "../../../../../shared/utils/equality";
import { Hours } from "../../../../../domain/units/Hours";
import { Tons } from "../../../../../domain/units/Tons";
import {
  useSingleFormItem,
  UseSingleFormItemHook,
} from "../../useSingleFormItem";
import { notEmptyInputToResultWrapper } from "../../InputToResult";
import RequiredStar from "../../../misc-components/RequiredStar";
import Table from "../../../misc-components/Table";

type ConsumptionItemInput = {
  id: Id,
  dirty: DirtyInfo,
  engine?: EngineType,
  fuel?: FuelType,
  reason?: ConsumptionReason,
  time?: Hours,
  consumption?: Tons,
};

type Props = FormItemProps<ConsumptionItem[]> & {
  fuelTypes: FuelType[],
  engineTypes: EngineType[],
  reasonTypes: ConsumptionReason[],
  requiredConsumptionEngineTypes?: EngineType[],
  showConsumptionEngine: boolean,
  showConsumptionReason: boolean,
  showConsumptionTime: boolean,
  title?: string,
};

type Hook = UseSingleFormItemHook<ConsumptionItemInput[], ConsumptionItem[]> & {
  addConsumptionItem: () => void,
  tableColumns: ColumnsType<ConsumptionItemInput>,
};

/* ------------------------------------------------------------ */

const outputToInput = (value: ConsumptionItem[]): ConsumptionItemInput[] => {
  return value.map((consItem) => ({
    ...consItem,
    dirty: { value: true },
  }));
};

const getInputToResult = (settings: {
  anyRequired: boolean,
  requiredConsumptionEngineTypes?: EngineType[],
  showConsumptionReason?: boolean,
  showConsumptionEngine?: boolean,
  showConsumptionTime?: boolean,
}): ((input: ConsumptionItemInput[]) => Result<ConsumptionItem[], ValidationError[]>) => {
  return useCallback((input) => {
    if ((input === undefined || input.length === 0) && !settings.anyRequired) {
      return ok([]);
    }
    const missingTypes = (settings.requiredConsumptionEngineTypes ?? [])
      .filter((rit) => !(input ?? []).some((cii) => cii.engine === rit));
    if (missingTypes.length > 0) {
      return err([new ValidationError(`Missing consumption entries for the following engine types: ${missingTypes.join(
        ", ",
      )}`, { userFacing: true })]);
    }
    let consumptionItems: ConsumptionItem[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const consumptionInputItem of (input ?? [])) {
      // TODO: This error handling is kinda dumb, not very specific.
      if (
        consumptionInputItem.fuel === undefined ||
        consumptionInputItem.consumption === undefined ||
        (consumptionInputItem.engine === undefined && settings.showConsumptionEngine) ||
        (consumptionInputItem.reason === undefined && settings.showConsumptionReason) ||
        (consumptionInputItem.time === undefined && settings.showConsumptionTime)
      ) {
        return err([new RequiredError(
          "A consumption entry is missing one or more fields, please check for errors.",
          { userFacing: true },
        )]);
      }

      const newConsumptionItem = new ConsumptionItem(
        consumptionInputItem.id,
        consumptionInputItem.fuel,
        consumptionInputItem.consumption,
        consumptionInputItem.engine,
        consumptionInputItem.reason,
        consumptionInputItem.time,
      );
      consumptionItems = [
        ...consumptionItems,
        newConsumptionItem,
      ];
    }
    return ok(consumptionItems);
  }, [
    settings.anyRequired,
    settings.requiredConsumptionEngineTypes,
    settings.showConsumptionReason,
    settings.showConsumptionTime,
    settings.showConsumptionEngine,
  ]);
};

/* ------------------------------------------------------------ */

const useConsumptionItemsFormItem = (props: Props): Hook => {
  const {
    reasonTypes,
    engineTypes,
    requiredConsumptionEngineTypes,
    showConsumptionReason,
    showConsumptionTime,
    showConsumptionEngine,
  } = props;

  const anyRequired = (
    props.required &&
    requiredConsumptionEngineTypes !== undefined &&
    requiredConsumptionEngineTypes.length > 0
  );

  const hook = useSingleFormItem<ConsumptionItemInput[], ConsumptionItem[]>({
    ...props,
    inputToResult: notEmptyInputToResultWrapper(
      "Consumption Items",
      getInputToResult({
        anyRequired,
        requiredConsumptionEngineTypes,
        showConsumptionReason,
        showConsumptionTime,
        showConsumptionEngine,
      }),
    ),
    outputToInput,
    inputsEqual: deepEqual,
    dontAutoUpdateDirty: true,
  });

  // Set initial input
  useEffect(() => {
    const initialInput: ConsumptionItemInput[] | undefined = anyRequired
      ? requiredConsumptionEngineTypes!.map((type) => ({
        id: newId(),
        engine: type,
        dirty: { value: false },
      }))
      : undefined;
    hook.onInputChange(initialInput);
  }, []);

  // On dirty update
  useEffect(() => {
    hook._setInput((oldInput) => {
      if (oldInput === undefined) {
        return oldInput;
      }
      return oldInput.map((item) => ({ ...item, dirty: props.dirty }));
    });
  }, [props.dirty]);

  // On input change
  useEffect(() => {
    if (hook.input === undefined) {
      return;
    }
    const newInput = hook.input.map((item) => {
      const allFilled = (
        item.engine !== undefined &&
        item.fuel !== undefined &&
        item.reason !== undefined &&
        item.time !== undefined &&
        item.consumption !== undefined
      );
      return ({ ...item, dirty: item.dirty ?? { value: allFilled } });
    });
    if (!deepEqual(newInput, hook.input)) {
      hook.onInputChange(newInput);
    }
  }, [hook.input]);

  const addConsumptionItem = (): void => {
    props.onDirtyChange({ value: false });
    hook.onInputChange([
      ...(hook.input ?? []),
      {
        id: newId(),
        dirty: { value: false },
      },
    ]);
  };

  const renderConsumptionItemType = (
    type: EngineType | undefined,
    item: ConsumptionItemInput,
    index: number,
  ): React.ReactNode => (
    <Select
      className={item.dirty.value && type === undefined ? "error" : undefined}
      value={type}
      options={engineTypes.map((t) => ({ label: t, value: t }))}
      placeholder="Engine Type"
      onChange={(newType: EngineType): void => {
        const newItem: ConsumptionItemInput = {
          ...item,
          engine: newType,
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}
    />
  );

  const renderFuel = (
    fuel: FuelType | undefined,
    item: ConsumptionItemInput,
    index: number,
  ): React.ReactNode => (
    <Select
      className={item.dirty.value && fuel === undefined ? "error" : undefined}
      value={fuel}
      placeholder="Fuel Grade"
      onChange={(newFuel: FuelType): void => {
        const newItem = {
          ...item,
          fuel: newFuel,
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}>
      { Object.entries(fuelTypeOptions).map(([groupLabel, groupOptions]) => (
        <Select.OptGroup
          label={groupLabel}
          key={groupLabel}
        >
          {
            groupOptions.map((option) => (
              <Select.Option
                key={option.label}
                value={option.value}
              >
                {option.label}
              </Select.Option>
            ))
          }
        </Select.OptGroup>
      ))}
    </Select>
  );

  const renderReason = (
    reason: ConsumptionReason | undefined,
    item: ConsumptionItemInput,
    index: number,
  ): React.ReactNode => (
    <Select
      className={item.dirty.value && reason === undefined ? "error" : undefined}
      value={reason}
      options={reasonTypes.map((rt) => ({ label: rt, value: rt }))}
      placeholder="Used For"
      onChange={(newReason: ConsumptionReason): void => {
        const newItem: ConsumptionItemInput = {
          ...item,
          reason: newReason,
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}
    />
  );

  const renderTime = (
    time: Hours | undefined,
    item: ConsumptionItemInput,
    index: number,
  ): React.ReactNode => (
    <NumericInput
      className={item.dirty.value && time === undefined ? "error" : undefined}
      value={time?.value}
      allowNegative={false}
      placeholder="hours"
      onChange={(newTime: number | undefined): void => {
        const newItem: ConsumptionItemInput = {
          ...item,
          time: newTime === undefined ? undefined : new Hours(newTime),
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}
    />
  );

  const renderConsumption = (
    consumption: Tons | undefined,
    item: ConsumptionItemInput,
    index: number,
  ): React.ReactNode => (
    <NumericInput
      className={item.dirty.value && consumption === undefined ? "error" : undefined}
      value={consumption?.value}
      allowNegative={false}
      separateThousands={true}
      placeholder="tons"
      onChange={(newConsumption: number | undefined): void => {
        const newItem: ConsumptionItemInput = {
          ...item,
          consumption: newConsumption === undefined ? undefined : new Tons(newConsumption),
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}
    />
  );

  const renderDelete = (_: unknown, item: ConsumptionItemInput, index: number): React.ReactNode => (
    <Popconfirm
      title="Really delete this entry?"
      onConfirm={(): void => {
        const newInput = hook.input !== undefined
          ? removeAtIndex(hook.input, index)
          : undefined;
        hook.onInputChange(newInput);
      }}
      okText="Yes"
      cancelText="No"

    >
      <IconButton
        danger={true}
        icon={faTrash}
        type="link"
      />
    </Popconfirm>
  );

  const tableColumns: ColumnsType<ConsumptionItemInput> = [
    (
      props.showConsumptionEngine
        ? {
          title: "Engine Type",
          dataIndex: "engine",
          render: renderConsumptionItemType,
          width: "20%",
        } : undefined
    ),
    (
      props.showConsumptionReason
        ? {
          title: "Used For",
          dataIndex: "reason",
          render: renderReason,
          width: "20%",
        } : undefined
    ),
    {
      title: "Fuel Grade",
      dataIndex: "fuel",
      render: renderFuel,
    },
    {
      title: "Consumption",
      dataIndex: "consumption",
      render: renderConsumption,
      width: "15%",
    },
    (
      props.showConsumptionTime
        ? {
          title: "Hours",
          dataIndex: "time",
          render: renderTime,
          width: "15%",
        } : undefined
    ),
    {
      title: "",
      render: renderDelete,
      width: "40px",
    },
  ].filter((c) => c !== undefined) as ColumnsType<ConsumptionItemInput>;

  return {
    ...hook,
    addConsumptionItem,
    tableColumns,
  };
};

/* ------------------------------------------------------------ */

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  & td > * {
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: flex-end;
  
  & > h3 {
    flex: 1 1 0px;
    margin: 0;
  }
  
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const ConsumptionItemsFormItem = (props: Props): React.ReactElement => {
  const hook = useConsumptionItemsFormItem(props);
  const screens = Grid.useBreakpoint();

  return (
    <ErrorWrapper
      isError={hook.isError}
      displayRequiredErrors={false}
      errors={hook.error}
    >
      <TableWrapper>
        <ButtonsWrapper>
          <h3>{props.title ?? "Consumption"}{props.required && <RequiredStar />}</h3>
          <IconButton
            icon={faPlus}
            type="primary"
            onClick={hook.addConsumptionItem}
          >
            Add entry
          </IconButton>
        </ButtonsWrapper>
        <Table
          columns={hook.tableColumns}
          dataSource={hook.input}
          pagination={false}
          useCards={screens.xs}
          className={(hook.isError && hook.error === undefined) ? "error" : undefined}
          rowKey="id"
          locale={{
            emptyText: "No consumption entries added",
          }}
          tableLayout="fixed"
          size="small"
        />
      </TableWrapper>
    </ErrorWrapper>
  );
};

export default ConsumptionItemsFormItem;
