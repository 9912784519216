import {
  combineWithAllErrors,
  ok,
  Result,
} from "neverthrow";
import Latitude from "./Latitude";
import Longitude from "./Longitude";
import ValidationError from "../errors/ValidationError";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";

@serializableDecorator<Coordinates>()
export class Coordinates {
  public constructor(
    public readonly lat: Latitude,
    public readonly long: Longitude,
  ) {}

  static serialize(coordinates: Coordinates): Result<Serialized, SerializationError> {
    return ok({
      lat: coordinates.lat.toDecimalDegrees({ round: false }),
      long: coordinates.long.toDecimalDegrees({ round: false }),
      __className: Coordinates.name,
    });
  }

  static deserialize(serialized: Serialized): Result<Coordinates, SerializationError> {
    const results: [
      Result<Latitude, ValidationError[]>,
      Result<Longitude, ValidationError[]>,
    ] = [
      Latitude.fromDecimalDegrees(serialized.lat),
      Longitude.fromDecimalDegrees(serialized.long),
    ];
    return combineWithAllErrors(results)
      .map(([lat, long]) => new Coordinates(lat, long))
      .mapErr((validationErrors) => new SerializationError(
        "Could not serialize Coordinate.",
        validationErrors.flat(),
      ));
  }

  public equals(that: Coordinates): boolean {
    return this.lat.equals(that.lat) && this.long.equals(that.long);
  }

  public toDecimalString(): string {
    return `${this.lat.toDecimalString()}, ${this.long.toDecimalDegrees()}`;
  }

  public toDDMString(): string {
    return `${this.lat.toDDMString()}, ${this.long.toDDMString()}`;
  }

  public toDMSString(): string {
    return `${this.lat.toDMSString()}, ${this.long.toDMSString()}`;
  }
}
