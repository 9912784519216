import * as localforage from "localforage";
import {
  getLocalVersion,
  setLocalVersion,
} from "./localVersion";

export const CODE_VERSION = 7;

export const runMigrations = async (): Promise<void> => {
  const localVersion = await getLocalVersion();

  if (localVersion == null) {
    await localforage.clear();
    await setLocalVersion(CODE_VERSION);
    return;
  }

  if (localVersion < 7) {
    await localforage.clear();
  }

  await setLocalVersion(CODE_VERSION);
};
