import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";
import { round } from "../../shared/utils/math";

@serializableDecorator<Knots>()
class Knots extends NumberUnit {
  static fromMetersPerSecond(mps: number): Knots {
    return new Knots(round(mps * 1.9438445, 2));
  }

  static serialize(unit: Knots): Result<Serialized, SerializationError> {
    return super._serialize(unit, Knots);
  }

  static deserialize(serialized: Serialized): Result<Knots, SerializationError> {
    return super._deserialize(serialized, Knots);
  }
}

export default Knots;