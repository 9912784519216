import {
  Result,
} from "neverthrow";
import React, {
  useMemo,
} from "react";
import { Coordinates } from "../../../../../../domain/position/Coordinates";
import {
  FormItemProviderPropsWithFieldStates,
} from "../../../FormItemProvider";
import DMSFormItem from "./DMS/DMSFormItem";
import { getAisPosition } from "../../../../api";
import DDFormItem from "./DD/DDFormItem";
import DDMFormItem from "./DDM/DDMFormItem";

function PositionFormItemProvider(props: FormItemProviderPropsWithFieldStates<Coordinates>): React.ReactElement {
  const { settings, fieldStates } = props;

  const getAisPositionWrapper = useMemo(() => {
    const imoResult = fieldStates.IMONumber?.result;
    if (imoResult?.isOk()) {
      return async (): Promise<Result<Coordinates, Error>> => getAisPosition(
        { imoNumber: imoResult.value },
        { showErrorNotification: true, notificationTitle: "Failed to get AIS position" },
      );
    }
    return undefined;
  }, [fieldStates.IMONumber?.result?.isOk()]);

  switch (settings.coordinateType) {
    case "DD":
      return (
        <DDFormItem
          {...props}
          getAisPosition={getAisPositionWrapper}
        />
      );
    case "DDM":
      return (
        <DDMFormItem
          {...props}
          getAisPosition={getAisPositionWrapper}
        />
      );
    default:
    case "DMS":
      return (
        <DMSFormItem
          {...props}
          getAisPosition={getAisPositionWrapper}
        />
      );
  }
}

export default React.memo(PositionFormItemProvider);