import {
  err,
  ok,
  Result,
} from "neverthrow";
import { DateTime } from "luxon";
import ValidationError from "../../../../../../domain/errors/ValidationError";
import {
  DATE_FORMAT,
  daysInMonth,
} from "../../../../../../shared/utils/date";

const validPattern = /^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/;

class DateString {
  private constructor(public value: string) {}

  public static fromDateTime(input: DateTime): DateString {
    return DateString.fromString(input.toFormat(DATE_FORMAT))._unsafeUnwrap();
  }

  public static fromString(input: string): Result<DateString, ValidationError[]> {
    if (!validPattern.test(input)) {
      return err([new ValidationError("Date must have format YYYY-MM-DD.\nExample: 2021-07-30", { userFacing: true })]);
    }

    const [year, month, day] = input.split("-").map(Number);

    const nanErrors: ValidationError[] = [];
    if (Number.isNaN(year)) {
      nanErrors.push(new ValidationError("Invalid year, not a number.", { userFacing: true }));
    }
    if (Number.isNaN(month)) {
      nanErrors.push(new ValidationError("Invalid month, not a number.", { userFacing: true }));
    }
    if (Number.isNaN(day)) {
      nanErrors.push(new ValidationError("Invalid day, not a number.", { userFacing: true }));
    }
    if (nanErrors.length > 0) {
      return err(nanErrors);
    }

    const rangeErrors: ValidationError[] = [];
    if (year < 1970 || year > 2999) {
      rangeErrors.push(new ValidationError("Year must be between 1970 and 2999.", { userFacing: true }));
    }
    if (month < 1 || month > 12) {
      rangeErrors.push(new ValidationError("Month must be between 01 and 12.", { userFacing: true }));
    }
    const maxDays = daysInMonth(year, month);
    if (day < 1 || day > maxDays) {
      rangeErrors.push(new ValidationError(`Days must be between 01 and ${maxDays}.`, { userFacing: true }));
    }
    if (rangeErrors.length > 0) {
      return err(rangeErrors);
    }

    return ok(new DateString(input));
  }

  public equals(that: DateString): boolean {
    return this.value === that.value;
  }
}

export default DateString;