import {
  err,
  ok,
  Result,
} from "neverthrow";
import ValidationError from "./errors/ValidationError";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";

const validPattern = /^[0-9]{7}$/;

@serializableDecorator<ImoNumber>()
class ImoNumber {
  private constructor(
    public readonly value: string,
  ) {}

  public static fromString(input: string): Result<ImoNumber, ValidationError[]> {
    if (!validPattern.test(input)) {
      return err([new ValidationError(`Invalid IMO Number. Must be exactly 7 digits.`, { userFacing: true })]);
    }
    return ok(new ImoNumber(input.toUpperCase()));
  }

  static serialize(imoNumber: ImoNumber): Result<Serialized, SerializationError> {
    return ok({ value: imoNumber.value, __className: ImoNumber.name });
  }

  static deserialize(serialized: Serialized): Result<ImoNumber, SerializationError> {
    return ok(new ImoNumber(serialized.value));
  }
}

export default ImoNumber;