import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<Tons>()
export class Tons extends NumberUnit {
  static serialize(unit: Tons): Result<Serialized, SerializationError> {
    return super._serialize(unit, Tons);
  }

  static deserialize(serialized: Serialized): Result<Tons, SerializationError> {
    return super._deserialize(serialized, Tons);
  }
}