import React, {
  ReactNode,
  useEffect,
} from "react";
import styled from "styled-components";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DateTime } from "luxon";

import { useHistory } from "react-router-dom";
import Report from "../../../../domain/reports/Report";
import ReportType from "../../../../domain/reports/ReportType";
import Routes from "../../routes";
import useFetch from "../../hooks/useFetch";
import FetchErrors from "../../../../shared/errors/FetchErrors";
import DataStatus from "../../misc-components/DataStatus";
import {
  DATE_TIME_OFFSET_FORMAT,
} from "../../../../shared/utils/date";
import {
  getAllReports,
  subscribeToStorageEvent,
} from "../../localReportStorage";
import byReportDate from "../../../../shared/utils/byReportDate";
import CloudSyncStatus from "../../../../domain/CloudSyncStatus";
import CloudStatusText from "../../misc-components/CloudStatusText";

type Props = {};

type Hook = {
  navigateToReport: (report: Report) => void,
  reports?: Report[],
  reportsLoading: boolean,
  reportsError?: FetchErrors,
};

export const useReportsListPage = (_props: Props): Hook => {
  const history = useHistory();

  const {
    data: reports,
    error: reportsError,
    loading: reportsLoading,
    refresh: refreshReports,
  } = useFetch(getAllReports);

  useEffect(() => {
    const unsubscribe = subscribeToStorageEvent("sync", refreshReports);
    return unsubscribe;
  }, []);

  const navigateToReport = (report: Report): void => {
    history.push(Routes.Report.generate(report.id));
  };

  return {
    navigateToReport,
    reports,
    reportsLoading,
    reportsError,
  };
};

/* ------------------------------------------------------------ */

const StyledReportsListPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    padding: 0 20px;
    max-width: 1200px;
  }

  & .ant-table-row:hover {
    cursor: pointer;
  }

  & .ant-table-cell {
    padding: 10px 16px;
  }
`;

const ReportsListPage: React.FC<Props> = (props) => {
  const hook = useReportsListPage(props);

  const renderType = (type: ReportType): ReactNode => {
    return type;
  };

  const renderCreatedAt = (createdAt: DateTime): ReactNode => {
    return <span>{createdAt.toFormat(DATE_TIME_OFFSET_FORMAT)} <i>({createdAt.toRelativeCalendar()})</i></span>;
  };

  const renderStatus = (status: CloudSyncStatus): ReactNode => {
    return <CloudStatusText cloudStatus={status} tooltipMode="underline" />;
  };

  const columns: ColumnsType<Report> = [
    {
      title: "Type",
      dataIndex: "type",
      render: renderType,
      sorter: (a: Report, b: Report): number => a.type.localeCompare(b.type),
      filters: Object.values(ReportType).map((reportType) => ({
        value: reportType,
        text: reportType,
      })),
      onFilter: (type, report): boolean => report.type === type,
    },
    {
      title: "Report Date",
      dataIndex: ["data", "ReportDate"],
      render: renderCreatedAt,
      defaultSortOrder: "descend",
      sorter: byReportDate("asc"),
    },
    {
      title: "Status",
      dataIndex: "cloudStatus",
      render: renderStatus,
      sorter: (a: Report, b: Report): number => a.cloudStatus.localeCompare(b.cloudStatus),
      filters: [
        { value: CloudSyncStatus.Synced, text: CloudSyncStatus.Synced },
        { value: CloudSyncStatus.AwaitingConnection, text: CloudSyncStatus.AwaitingConnection },
        { value: CloudSyncStatus.Failed, text: CloudSyncStatus.Failed },
      ],
      onFilter: (status, report): boolean => report.cloudStatus === status,
    },
  ];

  // TODO: Better error text
  return (
    <StyledReportsListPage>
      <div>
        <h1>Previous Reports</h1>
        <Table
          className="clickable"
          columns={columns}
          dataSource={hook.reports}
          locale={{
            emptyText: (
              <DataStatus
                emptyText="No previous reports."
                errorText="Could not load reports."
                loadingText="Loading reports..."
                isLoading={hook.reportsLoading}
                isError={hook.reportsError !== undefined}
                isEmpty={hook.reports === undefined || hook.reports.length === 0}
              />
            ),
          }}
          rowKey="id"
          tableLayout="fixed"
          onRow={(report: Report): any => ({
            onClick: (): void => hook.navigateToReport(report),
          })}
        />
      </div>
    </StyledReportsListPage>
  );
};

export default ReportsListPage;
