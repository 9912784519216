import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import ImoNumber from "../../../../../../domain/ImoNumber";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import GenericTextFormItemProvider from "../../_generic/text/GenericTextFormItemProvider";

const fieldName = "IMO Number";

const IMONumberFormItemProvider: FormItemProvider<ImoNumber> = (props: FormItemProviderProps<ImoNumber>) => (
  <GenericTextFormItemProvider
    {...props}
    fieldType="IMONumber"
    fieldName={fieldName}
    inputToResult={notEmptyInputToResultWrapper(fieldName, ImoNumber.fromString)}
    outputToInput={unwrapValue}
    inputsEqual={eqeqeq}
    suggestPrevious={true}
    outputToSuggestionText={unwrapValue}
    autoFillPrevious={true}
    disabled={props.result?.isOk() ?? false}
  />
);

export default React.memo(IMONumberFormItemProvider);