import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Input,
  InputProps,
} from "antd";
import {
  stringToNumber,
  stringToValidNumericString,
} from "../../../shared/utils/stringToNumber";

import useUndo from "../hooks/useUndo";

type NotNullReturning = {
  returnUndefinedOnEmpty: false,
  onChange: (newValue: number) => void,
};

type NullReturning = {
  returnUndefinedOnEmpty?: true,
  onChange?: (newValue?: number) => void,
};

type BaseNumericInputProps = {
  value?: number,
  allowNegative?: boolean,
  integersOnly?: boolean,
  separateThousands?: boolean,
  className?: string;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  placeholder?: string;
};

type ignoredKeys =
  | "value"
  | "onChange"
  | "className"
  | "addonBefore"
  | "addonAfter"
  | "placeholder";

type Props =
  {
    inputProps?: Omit<InputProps, ignoredKeys>,
  }
  & BaseNumericInputProps
  & (NotNullReturning | NullReturning);

const NumericInput: React.FC<Props> = ({
  value,
  allowNegative = true,
  integersOnly = false,
  separateThousands = false,
  returnUndefinedOnEmpty = true,
  className,
  addonBefore,
  addonAfter,
  placeholder,
  onChange,
  inputProps = {},
}) => {
  const getFormattedValue = useCallback((rawValue: string) => (
    stringToValidNumericString(rawValue, {
      integersOnly,
      allowNegative,
      separateThousands,
    })
  ), [allowNegative, integersOnly, separateThousands]);

  const getNumberValue = useCallback((rawValue: string) => (
    stringToNumber(rawValue, {
      integersOnly,
      allowNegative,
    })
  ), [allowNegative, integersOnly]);

  const [controlledValue, setControlledValue] = useState(value != null ? getFormattedValue(String(value)) : "");

  const updateValue = (newValue: string): void => {
    setControlledValue(newValue);
    if (onChange === undefined) {
      return;
    }
    if (returnUndefinedOnEmpty && newValue === "") {
      // @ts-ignore
      onChange(undefined);
    } else {
      onChange(getNumberValue(newValue));
    }
  };

  const [handleChange, handleKeyDown, addUndoValue] = useUndo(updateValue, value != null ? String(value) : "", getFormattedValue);

  useEffect(() => {
    if (value == null) {
      setControlledValue("");
      return;
    }

    if (value === 0 && controlledValue === "") {
      setControlledValue("0");
      return;
    }

    if (getNumberValue(String(value)) === getNumberValue(controlledValue)) {
      return;
    }

    const newValue = getFormattedValue(String(value));
    setControlledValue(newValue);
    addUndoValue(newValue);
  }, [controlledValue, value]);

  return (
    <Input
      {...inputProps}
      type="text"
      value={controlledValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      className={className}
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      placeholder={placeholder}
    />
  );
};

export default NumericInput;
