export function removeAtIndex<T>(arr: T[], index: number): T[] {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function replaceAtIndex<T>(arr: T[], index: number, element: T): T[] {
  return [...arr.slice(0, index), element, ...arr.slice(index + 1)];
}

export function insertAtIndex<T>(arr: T[], index: number, element: T): T[] {
  return [...arr.slice(0, index), element, ...arr.slice(index)];
}