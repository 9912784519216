import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import Port from "../../../../../../domain/Port";
import PortFormItemProvider from "../PortFormItemProvider";

const fieldName = "Arrival Port";

const ArrivalPortFormItemProvider: FormItemProvider<Port> = (props: FormItemProviderProps<Port>) => (
  <PortFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="ArrivalPort"
  />
);

export default React.memo(ArrivalPortFormItemProvider);
