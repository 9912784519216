import { ok } from "neverthrow";
import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import identity from "../../../../../../shared/utils/identity";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import GenericTextFormItemProvider from "../../_generic/text/GenericTextFormItemProvider";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Voyage Charterer";

const VoyageChartererFormItemProvider: FormItemProvider<string> = (props: FormItemProviderProps<string>) => (
  <GenericTextFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="VoyageCharterer"
    suggestPrevious={true}
    outputToSuggestionText={identity}
    inputToResult={notEmptyInputToResultWrapper(fieldName, ok)}
    outputToInput={identity}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(VoyageChartererFormItemProvider);