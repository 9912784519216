import * as joi from "joiful";
import {
  Column,
  Entity,
  PrimaryColumn,
} from "typeorm";
import {
  combine,
  ok,
  Result,
} from "neverthrow";
import { DateTime } from "luxon";
import ReportData from "./ReportData";
import {
  deserialize,
  serializableDecorator,
  SerializationError,
  serialize,
  Serialized,
} from "../../shared/utils/serialization";
import serializeTransformer from "../../shared/utils/transformers/serializeTransformer";
import dateTimeTransformer from "../../shared/utils/transformers/dateTimeTransformer";
import ReportType from "./ReportType";
import ReportStatus from "./ReportStatus";
import CloudSyncStatus from "../CloudSyncStatus";
import ReportFormatToSend from "./ReportFormatToSend";
import { Id } from "../Id";

@Entity()
@serializableDecorator<Report>()
class Report {
  @joi.string().required()
  @PrimaryColumn("text")
  id!: Id;

  @joi.object().required() // TODO: Better validation
  @Column({
    type: "simple-json",
    transformer: serializeTransformer<ReportData>(),
  })
  data!: ReportData;

  @joi.string().required()
  @Column("text")
  type!: ReportType;

  @joi.boolean().required()
  @Column("boolean")
  deleted!: boolean;

  @joi.object().required()
  @Column({
    type: "text",
    transformer: dateTimeTransformer,
  })
  createdAt!: DateTime;

  @joi.number().required()
  @Column("int")
  updatedAtTimestamp!: number;

  @joi.string().required()
  @Column("text")
  status!: ReportStatus;

  @joi.string().required()
  @Column("text")
  imoNumber!: string;

  @joi.string().required()
  @Column("text")
  cloudStatus!: CloudSyncStatus;

  @joi.array().required()
  @Column({
    type: "simple-json",
    transformer: serializeTransformer<ReportFormatToSend[]>(),
  })
  reportFormatsToSend!: ReportFormatToSend[];

  public static create(props: {
    id: Id,
    type: ReportType,
    data: ReportData,
    imoNumber: string,
    createdAt: DateTime,
    updatedAtTimestamp: number,
    status: ReportStatus,
    cloudStatus: CloudSyncStatus,
    reportFormatsToSend: ReportFormatToSend[],
    deleted: boolean,
  }): Report {
    return Object.assign(new Report(), props);
  }

  static serialize(report: Report): Result<Serialized, SerializationError> {
    const results = [
      serialize(report.data),
      serialize(report.createdAt),
      serialize(report.reportFormatsToSend),
    ];
    return combine(results)
      .andThen(([
        data,
        createdAt,
        reportFormatsToSend,
      ]) => ok({
        __className: Report.name,
        id: report.id,
        type: report.type,
        status: report.status,
        cloudStatus: report.cloudStatus,
        updatedAtTimestamp: report.updatedAtTimestamp,
        imoNumber: report.imoNumber,
        deleted: report.deleted,
        data,
        createdAt,
        reportFormatsToSend,
      }));
  }

  static deserialize(serialized: Serialized): Result<Report, SerializationError> {
    const results = [
      deserialize(serialized.data),
      deserialize(serialized.createdAt),
      deserialize(serialized.reportFormatsToSend),
    ];
    return combine(results)
      .map(([
        data,
        createdAt,
        reportFormatsToSend,
      ]) => Report.create({
        id: serialized.id,
        type: serialized.type,
        data,
        createdAt,
        updatedAtTimestamp: serialized.updatedAtTimestamp,
        status: serialized.status,
        cloudStatus: serialized.cloudStatus,
        imoNumber: serialized.imoNumber,
        deleted: serialized.deleted,
        reportFormatsToSend,
      }));
  }
}

export default Report;