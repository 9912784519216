import {
  err,
  ok,
  Result,
} from "neverthrow";
import {
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  DirtyInfo,
  FormItemProps,
} from "./FormItem";
import ValidationError from "../../../domain/errors/ValidationError";
import Suggestion from "./Suggestion";

/* ------------------------------------------------------------ */

type ChildInputOutputTypes = {
  input: any,
  output: any,
};

export type ChildInputTypes = {
  [key: string]: ChildInputOutputTypes,
};

type ChildResult<T extends ChildInputOutputTypes> = Result<T["output"], ValidationError[]>;

export type Inputs<T extends ChildInputTypes> = {
  [key in keyof T]: T[key]["input"]
};

export type Outputs<T extends ChildInputTypes> = {
  [key in keyof T]: T[key]["output"]
};

type ChildrenHookData<CIT extends ChildInputTypes> = {
  [key in keyof CIT]: {
    result: ChildResult<CIT[key]> | null | undefined,
    onResultChange: (newResult: ChildResult<CIT[key]> | null | undefined) => void,
    dirty: DirtyInfo,
    onDirtyChange: (newDirty: DirtyInfo) => void,
  }
};

type ResultStates<T extends ChildInputTypes> = {
  [key in keyof T]: ChildResult<T[key]> | null | undefined
};

type DirtyStates<T extends ChildInputTypes> = {
  [key in keyof T]: DirtyInfo
};

/* ------------------------------------------------------------ */

export type UseCompositeFormItemProps<CIT extends ChildInputTypes, O> = {
  childOutputsToResult: (outputs: Outputs<CIT>) => Result<O, ValidationError[]>,
  outputToChildOutputs: (output: O) => Outputs<CIT>,
  childOutputsEqual: (o1: Outputs<CIT>, o2: Outputs<CIT>) => boolean,
  dataKeys: (keyof CIT)[],
};

export type UseCompositeFormItemHook<CIT extends ChildInputTypes, O> = {
  isOk: boolean,
  isError: boolean,
  error: ValidationError[] | undefined,
  pickSuggestion: (suggestion: Suggestion<O>) => void,
  showSuggestions: boolean,
} & ChildrenHookData<CIT>;

type Props<CIT extends ChildInputTypes, O> =
  & UseCompositeFormItemProps<CIT, O>
  & FormItemProps<O>;

/* ------------------------------------------------------------ */

export const useCompositeFormItem = <CIT extends ChildInputTypes, Output>(
  props: Props<CIT, Output>,
): UseCompositeFormItemHook<CIT, Output> => {
  const {
    result,
    onResultChange,
    dirty,
    onDirtyChange,
    childOutputsToResult,
    outputToChildOutputs,
    childOutputsEqual,
    dataKeys,
  } = props;

  const initialResultStates = dataKeys.reduce(
    (acc, key) => ({ ...acc, [key]: undefined }),
    {} as ResultStates<CIT>,
  );
  const initialDirtyStates = dataKeys.reduce(
    (acc, key) => ({ ...acc, [key]: { value: false } }),
    {} as DirtyStates<CIT>,
  );
  const [resultStates, setResultStates] = useState<ResultStates<CIT>>(initialResultStates);
  const [dirtyStates, setDirtyStates] = useState<DirtyStates<CIT>>(initialDirtyStates);

  const clearChildResults = (): void => {
    setResultStates((prevState) => (
      Object.keys(prevState)
        .reduce((acc, key) => ({
          ...acc,
          [key]: null,
        }), {} as ResultStates<CIT>)
    ));
  };

  const setAllChildDirtyStates = (newDirty: DirtyInfo): void => {
    setDirtyStates((prevState) => (
      Object.keys(prevState)
        .reduce((acc, key) => ({
          ...acc,
          [key]: newDirty,
        }), {} as DirtyStates<CIT>)
    ));
  };

  const childOutputsToChildResults = (outputs: Outputs<CIT>): ResultStates<CIT> => {
    return Object.entries(outputs)
      .reduce((acc, [key, output]) => ({
        ...acc,
        [key]: ok(output),
      }), {} as Outputs<CIT>);
  };

  const childResultsToChildOutputs = (
    childResults: ResultStates<CIT>,
  ): Result<Outputs<CIT>, undefined | ValidationError[]> => {
    const outputs = {} as Outputs<CIT>;
    for (const key of Object.keys(childResults)) {
      const childResult = childResults[key];
      if (childResult?.isOk()) {
        outputs[key as keyof ResultStates<CIT>] = childResult.value;
      } else if (childResult?.isErr()) {
        return err(childResult.error);
      } else {
        return err(undefined);
      }
    }
    return ok(outputs);
  };

  const pickSuggestion = useCallback((suggestion: Suggestion<Output>): void => {
    props.onResultChange(ok(suggestion.value));
  }, [props.onResultChange]);

  // On resultStates update
  useEffect(() => {
    if (
      Object.values(resultStates).every((v) => v === undefined || v === null) &&
      (!props.required || !props.dirty.value)
    ) {
      onResultChange(undefined);
      return;
    }
    const newChildOutputsResult = childResultsToChildOutputs(resultStates);
    if (newChildOutputsResult.isErr()) {
      if (newChildOutputsResult.error !== undefined) {
        onResultChange(err([new ValidationError("", { userFacing: true })]));
        onDirtyChange({ value: true, dontDirtyChildren: true });
      }
      return;
    }
    const newResult = childOutputsToResult(newChildOutputsResult.value);
    onDirtyChange({ value: true });
    onResultChange(newResult);
  }, [resultStates, onDirtyChange, onResultChange, childOutputsToResult]);

  // On result update
  useEffect(() => {
    if (result === null) {
      clearChildResults();
      return;
    }

    if (result?.isOk()) {
      const newChildOutputs = outputToChildOutputs(result.value);
      const oldChildOutputsResult = childResultsToChildOutputs(resultStates);
      if (
        oldChildOutputsResult.isOk() &&
        childOutputsEqual(oldChildOutputsResult.value, newChildOutputs)
      ) {
        return;
      }
      const newResults = childOutputsToChildResults(newChildOutputs);
      setResultStates(newResults);
    }
  }, [result, childOutputsEqual, outputToChildOutputs]);

  // On "dirty" update
  useEffect(() => {
    if (!dirty.dontDirtyChildren) {
      setAllChildDirtyStates(dirty);
    }
  }, [dirty]);
  
  const childrenHookData: ChildrenHookData<CIT> = dataKeys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        result: resultStates[key],
        onResultChange: useCallback((newResult): void => (
          setResultStates((prevState) => ({
            ...prevState,
            [key]: newResult,
          }))
        ), []),
        dirty: dirtyStates[key],
        onDirtyChange: useCallback((newDirty): void => (
          setDirtyStates((prevState) => ({
            ...prevState,
            [key]: newDirty,
          }))
        ), []),
      },
    };
  }, {} as ChildrenHookData<CIT>);

  const isOk = result?.isOk() ?? false;

  const allChildrenDirty = Object.values(childrenHookData).every((x) => x?.dirty?.value);
  const resultError = (result?.isErr() ?? allChildrenDirty);
  const inputEmpty = (!props.required && Object.values(childrenHookData).every((x) => x === undefined));
  const isError = (dirty.value && resultError) && (!inputEmpty || allChildrenDirty);

  const error = result?.isErr() ? result.error : undefined;

  const showSuggestions = !result?.isOk();

  return {
    ...childrenHookData,
    isOk,
    isError,
    error,
    pickSuggestion,
    showSuggestions,
  };
};