import { ValueTransformer } from "typeorm";
import {
  deserialize,
  serialize,
  Serialized,
} from "../serialization";

const serializeTransformer = <T>(): ValueTransformer => ({
  // Reading from the database
  from: (serialized: Serialized): T => deserialize(serialized)._unsafeUnwrap(),
  // Writing to the database
  to: (data: T): Serialized => serialize(data)._unsafeUnwrap(),
});

export default serializeTransformer;