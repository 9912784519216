import ReportFormatConfig from "./ReportFormatConfig";
import ReportType from "./ReportType";
import ClearVoyageNoonReportGenerator from "./generators/clearvoyage/ClearVoyageNoonReportGenerator";
import ClearVoyageArrivalReportGenerator from "./generators/clearvoyage/ClearVoyageArrivalReportGenerator";
import ClearVoyageDepartureReportGenerator from "./generators/clearvoyage/ClearVoyageDepartureReportGenerator";
import ClearVoyageNoonInPortReportGenerator from "./generators/clearvoyage/ClearVoyageNoonInPortReportGenerator";
import ClearVoyageBunkeringReportGenerator
  from "./generators/clearvoyage/ClearVoyageBunkeringReportGenerator";

import ClearVoyageDeliveryReportGenerator
  from "./generators/clearvoyage/ClearVoyageDeliveryReportGenerator";
import ClearVoyageRedeliveryReportGenerator
  from "./generators/clearvoyage/ClearVoyageRedeliveryReportGenerator";
import ClearVoyageStatementOfFactsReportGenerator
  from "./generators/clearvoyage/ClearVoyageStatementOfFactsReportGenerator";
import ClearVoyageBerthingReportGenerator from "./generators/clearvoyage/ClearVoyageBerthingReportGenerator";

const reportTypeToReportFormatConfigsMap: Record<ReportType, ReportFormatConfig[]> = {
  [ReportType.NoonAtSea]: [
    {
      generator: new ClearVoyageNoonReportGenerator(),
      version: (new ClearVoyageNoonReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
  [ReportType.NoonInPort]: [
    {
      generator: new ClearVoyageNoonInPortReportGenerator(),
      version: (new ClearVoyageNoonInPortReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
  [ReportType.Berthing]: [
    {
      generator: new ClearVoyageBerthingReportGenerator(),
      version: (new ClearVoyageBerthingReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
  [ReportType.Arrival]: [
    {
      generator: new ClearVoyageArrivalReportGenerator(),
      version: (new ClearVoyageArrivalReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
  [ReportType.Departure]: [
    {
      generator: new ClearVoyageDepartureReportGenerator(),
      version: (new ClearVoyageDepartureReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
  [ReportType.Bunkering]: [
    {
      generator: new ClearVoyageBunkeringReportGenerator(),
      version: (new ClearVoyageBunkeringReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
  [ReportType.Delivery]: [
    {
      generator: new ClearVoyageDeliveryReportGenerator(),
      version: (new ClearVoyageDeliveryReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
  [ReportType.Redelivery]: [
    {
      generator: new ClearVoyageRedeliveryReportGenerator(),
      version: (new ClearVoyageRedeliveryReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
  [ReportType.StatementOfFacts]: [
    {
      generator: new ClearVoyageStatementOfFactsReportGenerator(),
      version: (new ClearVoyageStatementOfFactsReportGenerator()).defaultVersion,
      recipients: [],
      sendable: false,
    },
  ],
};

const reportTypeToReportFormatConfigs = (reportType: ReportType): ReportFormatConfig[] => (
  reportTypeToReportFormatConfigsMap[reportType]
);

export default reportTypeToReportFormatConfigs;
