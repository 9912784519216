import { ok } from "neverthrow";
import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { Percent } from "../../../../../../domain/units/Percent";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Avg. Main Engine Load";

const MainEngineLoadFormItemProvider: FormItemProvider<Percent> = (props: FormItemProviderProps<Percent>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="MainEngineLoad"
    separateThousands={true}
    allowNegative={false}
    integersOnly={false}
    addonAfter="%"
    inputToResult={notEmptyInputToResultWrapper(fieldName, (input: number) => ok(new Percent(input)))}
    outputToInput={unwrapValue}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(MainEngineLoadFormItemProvider);