enum FuelType {
  HS_FO = "HSFO",
  LS_FO = "LSFO",
  VLS_FO = "VLSFO",
  ULS_FO = "ULSFO",
  HS_DO = "HSDO",
  LS_DO = "LSDO",
  VLS_DO = "VLSDO",
  ULS_DO = "ULSDO",
  LNG = "LNG",
}

export const fuelTypeOptions = {
  "Black Fuels": [
    {
      label: "HS FO (≤ 3.5% sulfur)",
      value: FuelType.HS_FO,
    },
    {
      label: "LS FO (≤ 1.0% sulfur)",
      value: FuelType.LS_FO,
    },
    {
      label: "VLS FO (≤ 0.5% sulfur)",
      value: FuelType.VLS_FO,
    },
    {
      label: "ULS FO (≤ 0.1% sulfur)",
      value: FuelType.ULS_FO,
    },
  ],
  "Clean Fuels": [
    {
      label: "HS DO (> 0.5% sulfur)",
      value: FuelType.HS_DO,
    },
    {
      label: "LS DO/MGO (≤ 0.5% sulfur)",
      value: FuelType.LS_DO,
    },
    {
      label: "VLS DO/MGO (≤ 0.1% sulfur)",
      value: FuelType.VLS_DO,
    },
    {
      label: "ULS DO/MGO (≤ ~0.001% sulfur)",
      value: FuelType.ULS_DO,
    },
  ],
  "LNG": [
    {
      label: "LNG",
      value: FuelType.LNG,
    },
  ],
};

export default FuelType;