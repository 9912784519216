import {
  err,
  ok,
  Result,
} from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";
import { Coordinates } from "./position/Coordinates";
import Port from "./Port";
import ValidationError from "./errors/ValidationError";
import ports from "./ports.json";
import Latitude from "./position/Latitude";
import Longitude from "./position/Longitude";
import { Degrees } from "./units/Degrees";

@serializableDecorator<NamedLocation>()
export class NamedLocation {
  private constructor(
    public readonly location: Coordinates,
    public readonly name: string,
    public readonly fromPort: boolean,
  ) {}

  static fromPosition(location: Coordinates, name: string): Result<NamedLocation, ValidationError> {
    return ok(new NamedLocation(
      location,
      name,
      false,
    ));
  }

  static fromPort(port: Port): Result<NamedLocation, ValidationError> {
    const portData = ports.find((p) => p.id === port.id);

    if (portData == null) {
      return err(new ValidationError("Location data for port not found.", { userFacing: false }));
    }

    const latResult = Latitude.fromDecimalDegrees(new Degrees(portData.latitude));
    const longResult = Longitude.fromDecimalDegrees(new Degrees(portData.longitude));

    if (latResult.isErr() || longResult.isErr()) {
      return err(new ValidationError("Invalid location.", { userFacing: false }));
    }

    const position: Coordinates = new Coordinates(latResult.value, longResult.value);

    return ok(new NamedLocation(
      position,
      portData.name,
      true,
    ));
  }

  static serialize(namedLocation: NamedLocation): Result<Serialized, SerializationError> {
    const serializedLocationResult = Coordinates.serialize(namedLocation.location);

    if (serializedLocationResult.isErr()) {
      return err(serializedLocationResult.error);
    }

    return ok({
      location: serializedLocationResult.value,
      name: namedLocation.name,
      fromPort: namedLocation.fromPort,
      __className: NamedLocation.name,
    });
  }

  static deserialize(serialized: Serialized): Result<NamedLocation, SerializationError> {
    if (serialized.location == null) {
      return err(new SerializationError("Location is empty."));
    }

    const deserializedLocationResult = Coordinates.deserialize(serialized.location);

    if (deserializedLocationResult.isErr()) {
      return err(deserializedLocationResult.error);
    }

    return ok(new NamedLocation(
      deserializedLocationResult.value,
      serialized.name ?? "",
      serialized.fromPort,
    ));
  }

  public equals(that: NamedLocation): boolean {
    return this.location.equals(that.location) && this.name === that.name;
  }
}
