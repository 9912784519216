import React from "react";
import Circle from "./Circle";
import TooltipSpan from "./TooltipSpan";
import TooltipIcon from "./TooltipIcon";

const StatusSpan: React.FC<{
  color: string | undefined,
  text: string,
  tooltip: string,
  tooltipMode: "underline" | "icon",
}> = ({
  color,
  text,
  tooltip,
  tooltipMode,
}) => (
  <span style={{
    display: "inline-flex",
    alignItems: "center",
  }}>
    <Circle
      size="1em"
      color={color}
      style={{
        marginRight: "7px",
      }}
    />
    { tooltipMode === "underline" && (
      <TooltipSpan
        tooltip={tooltip}
        style={{
          marginRight: "5px",
        }}
      >
        {text}
      </TooltipSpan>
    )}
    { tooltipMode === "icon" && (
      <>
        <span
          style={{
            marginRight: "5px",
          }}
        >
          {text}
        </span>
        <TooltipIcon
          tooltip={tooltip}
        />
      </>
    )}
  </span>
);

export default StatusSpan;