import {
  err,
  ok,
  Result,
} from "neverthrow";
import ValidationError from "./errors/ValidationError";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";

const validPattern = /^([a-z]|[0-9])+$/i;

@serializableDecorator<CallSign>()
class CallSign {
  private constructor(
    public readonly value: string,
  ) {}

  public static fromString(input: string): Result<CallSign, ValidationError[]> {
    if (!validPattern.test(input)) {
      return err([new ValidationError(`Call Sign must only contain letters and numbers.`, { userFacing: true })]);
    }
    return ok(new CallSign(input.toUpperCase()));
  }

  static serialize(callSign: CallSign): Result<Serialized, SerializationError> {
    return ok({ value: callSign.value, __className: CallSign.name });
  }

  static deserialize(serialized: Serialized): Result<CallSign, SerializationError> {
    return ok(new CallSign(serialized.value));
  }
}

export default CallSign;