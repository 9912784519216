import React from "react";
import {
  Button,
  Card,
  Col,
  Empty,
  Row as AntRow,
  Space,
  Table as AntdTable,
} from "antd";
import { TableProps as AntTableProps } from "antd/lib/table";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export type TableProps<Row> = {
  useCards?: boolean;
  getRowHref?: (row: Row) => string;
  emptyText?: string | React.ReactNode;
} & AntTableProps<Row>;

const Table = <Row extends object>({
  useCards = false,
  getRowHref,
  emptyText,
  dataSource,
  columns,
  ...props
}: TableProps<Row>) => {
  const history = useHistory();
  if (useCards) {
    const hasLink = getRowHref != null && typeof getRowHref === "function";

    const noBottomPadding = styled.div`
        .noBottomPadding :global(.ant-card-body) {
            padding: 0;
        }
    `;
    const rowWithPadding = styled.div`
        padding: 15px;
    `;

    const bottomButton = styled.div`
        width: 100%;
        border-left: none;
        border-right: none;
        border-bottom: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background: rgb(0, 89, 255);
        color: white;
        font-weight: bold;
    `;

    return (
      <>
        {dataSource == null || columns == null || dataSource.length === 0 ? (
          <Empty description={emptyText} />
        ) : (
          <AntRow gutter={[16, 16]}>
            {dataSource.map((entry, index) => (
              <Col span={24}>
                <Card
                  className={hasLink ? noBottomPadding : ""}
                  size="small"
                >
                  <AntRow className={hasLink ? rowWithPadding : ""} gutter={[2, 4]}>
                    {columns.map((column) => {
                      let value;

                      // @ts-ignore
                      const dataIndex = column?.dataIndex;

                      value = dataIndex == null ? null : entry[dataIndex];

                      if (column.render != null && typeof column.render === "function" && entry != null) {
                        value = column.render(value, entry, index);
                      }

                      const { title } = column;

                      return (
                        <>
                          <Col span={10}>
                            {title != null && title !== "" && (
                              <b>{`${title}:`}</b>
                            )}
                          </Col>
                          <Col span={14}>
                            {value ?? "N/A"}
                          </Col>
                        </>
                      );
                    })}
                  </AntRow>
                  {hasLink && (
                    <Button
                      onClick={() => {
                        if (history == null || getRowHref == null || entry == null) {
                          return;
                        }

                        history.push(getRowHref(entry));
                      }}
                      className={bottomButton}
                    >
                      <Space>
                        Go to
                        <FontAwesomeIcon icon={faArrowRight as IconProp} />
                      </Space>
                    </Button>
                  )}
                </Card>
              </Col>
            ))}
          </AntRow>
        )}
      </>
    );
  }
  return (
    <AntdTable
      dataSource={dataSource}
      columns={columns}
      {...props}
    />
  );
};

export default Table;