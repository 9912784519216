import React from "react";
import { Result } from "neverthrow";
import styled from "styled-components";
import {
  FormItemProps,
} from "../../../../FormItem";
import { Coordinates } from "../../../../../../../domain/position/Coordinates";
import ErrorWrapper from "../../../../ErrorWrapper";
import FetchErrors from "../../../../../../../shared/errors/FetchErrors";
import FillWithAisButton from "../shared/FillWithAisButton";
import DDLatitudeFormItem from "./DDLatitudeFormItem";
import DDLongitudeFormItem from "./DDLongitudeFormItem";
import { usePositionFormItem } from "../shared/usePositionFormItem";

const StyledDMSFormItem = styled.div`
  display: flex;
  flex-direction: column;
  
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
  
  & > div.aisButton {
    align-self: flex-start;
    margin: 0px 30px 20px 180px;
  }
`;

type Props = 
  FormItemProps<Coordinates>
  & {
    getAisPosition: (() => Promise<Result<Coordinates, FetchErrors>>) | undefined
  };

const DDFormItem = (props: Props): React.ReactElement => {
  const hook = usePositionFormItem<number, number>(props);

  return (
    <ErrorWrapper isError={hook.isError} errors={hook.error}>
      <StyledDMSFormItem>
        <FillWithAisButton
          onClick={hook.fillWithAis}
          enabled={hook.canFillWithAis}
          loading={hook.aisLoading}
        />
        <DDLatitudeFormItem
          result={hook.lat.result}
          onResultChange={hook.lat.onResultChange}
          dirty={hook.lat.dirty}
          onDirtyChange={hook.lat.onDirtyChange}
          required={props.required}
        />
        <DDLongitudeFormItem
          result={hook.long.result}
          onResultChange={hook.long.onResultChange}
          dirty={hook.long.dirty}
          onDirtyChange={hook.long.onDirtyChange}
          required={props.required}
        />
      </StyledDMSFormItem>
    </ErrorWrapper>
  );
};

export default DDFormItem;