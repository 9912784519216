export type StringToNumberOptions = {
  allowNegative?: boolean,
  integersOnly?: boolean,
  separateThousands?: boolean,
};

export const stringToValidNumericString = (
  string: string,
  options: StringToNumberOptions = {},
): string => {
  const {
    allowNegative = true,
    integersOnly = false,
    separateThousands = false,
  } = options;

  let str = string;
  // Remove all non-valid characters
  str = str.replace(/[^0-9.,-]/g, "");
  // Remove all '.' that doesn't come after a digit
  str = str.replace(/([^0-9.]|^)\.+/g, "$1");

  // Remove all but the first '.'
  let dotIndex = str.indexOf(".");

  if (dotIndex > -1) {
    str = str.slice(0, dotIndex + 1) + str.slice(dotIndex + 1).replace(/\./g, "");
  }

  // Remove all ',' to the right of a '.'
  dotIndex = str.indexOf(".");
  if (dotIndex > -1) {
    str = str.slice(0, dotIndex + 1) + str.slice(dotIndex + 1).replace(/,/g, "");
  }

  // Remove all ',' unless it's the last character
  str = str.replace(/,(?!$)/g, "");
  // Remove all ',' if it's not after a digit
  str = str.replace(/([^0-9.]|^),+/g, "$1");
  // Remove all '-' unless it's the first character
  str = str.replace(/(?!^)-/g, "");
  // Remove all leading zeroes
  str = str.replace(/^0+(?=[1-9])/g, "");

  if (separateThousands) {
    // Insert thousand-separators
    // See: https://stackoverflow.com/a/10899795/4688606
    const parts = str.split(".");
    str = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] != null ? `.${parts[1]}` : "");
  } else {
    str = str.replace(/,/g, "");
  }

  if (integersOnly) {
    str = str.replace(/\..*/g, ""); // Remove anything after the decimal point
  }

  if (!allowNegative) {
    str = str.replace(/-/g, ""); // Remove the minus symbol
  }

  return str;
};

export const stringToNumber = (
  string: string,
  options: StringToNumberOptions = {},
): number => {
  const {
    allowNegative = true,
    integersOnly = false,
  } = options;

  const str = stringToValidNumericString(string, {
    allowNegative,
    integersOnly,
  });

  if (str == null || str === "") {
    return 0;
  }

  const num = Number(str);

  if (Number.isNaN(num)) {
    return 0;
  }

  return num;
};
