import React from "react";
import NumericInput from "../../../../../misc-components/NumericInput";
import ErrorWrapper from "../../../../ErrorWrapper";
import { Degrees } from "../../../../../../../domain/units/Degrees";
import {
  useSingleFormItem,
  UseSingleFormItemProps,
} from "../../../../useSingleFormItem";
import { FormItemProps } from "../../../../FormItem";

type Props =
  {
    decimal: boolean,
  }
  & UseSingleFormItemProps<number, Degrees>
  & FormItemProps<Degrees>;

const DegreesInput: React.FC<Props> = (props) => {
  const hook = useSingleFormItem(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <NumericInput
        addonAfter="°"
        placeholder="Degrees"
        className={hook.isError ? "error" : undefined}
        value={hook.input}
        onChange={(newInput: number | undefined): void => {
          hook.onInputChange(newInput);
        }}
        integersOnly={!props.decimal}
        separateThousands={false}
        allowNegative={true}
        returnUndefinedOnEmpty={true}
      />
    </ErrorWrapper>
  );
};

export default DegreesInput;