import React, {
  useCallback,
  useMemo,
} from "react";
import {
  Affix,
  Popconfirm,
  Steps,
} from "antd";
import styled from "styled-components";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faSave } from "@fortawesome/pro-solid-svg-icons/faSave";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { Prompt } from "react-router-dom";
import {
  ReportGroupBox,
  ReportGroupBoxContents,
} from "../ReportGroupBox";
import {
  useReportForm,
  UseReportFormProps,
} from "./useReportForm";
import { WrappedFormItemProvider } from "../FormItemProvider";
import IconButton from "../../misc-components/IconButton";
import { SIMPLE_MODE } from "../../constants";
import { FieldGroupWithStatus } from "./useProgressSteps";

const StyledReportForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
  & fieldset > :not(:last-child) {
    margin-bottom: 10px;
  }
  
  & fieldset > :not(:first-child) > h2 {
    padding-top: 15px;
  }
  
  & fieldset > :last-child {
    margin-bottom: 10px;
  }
  
  /* HANDLE DISABLED FORM */

  & fieldset[disabled] .hideWhenDisabled {
    display: none;
  }

  & fieldset[disabled] .ant-input,
  & fieldset[disabled] .ant-select,
  & fieldset[disabled] .ant-radio-wrapper,
  & fieldset[disabled] .ant-picker {
    cursor: default;
    pointer-events: none;
  }


  & fieldset[disabled] .ant-input,
  & fieldset[disabled] .ant-select .ant-select-selector,
  & fieldset[disabled] .ant-picker {
    background-color: rgba(0, 0, 0, 0.05);
  }

  & fieldset[disabled] .ant-input:hover,
  & fieldset[disabled] .ant-select:hover .ant-select-selector,
  & fieldset[disabled] .ant-radio:not(.ant-radio-checked):hover .ant-radio-inner {
    border-color: #d9d9d9;
  }

  & fieldset[disabled] button {
    display: none;
  }

  & fieldset[disabled] .suggestions {
    display: none;
  }

  & fieldset[disabled] .status-icon {
    display: none;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  button:not(:last-child) {
    margin-right: 10px;
  }
  
  & > div:not(.spacer) {
    background: whitesmoke;
    padding: 15px 0 25px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  & > div.spacer {
    background: linear-gradient(0deg, whitesmoke, transparent);
    height: 25px;
    width: 100%;
  }
`;

const Group = styled.div<{ formDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  
  ${({ formDisabled }): any => !formDisabled && `
    & > div {
      padding-top: 40px;
    }
  `}
`;

const FormProgress = styled.div`
  @media only screen and (max-width: 1350px) {
    visibility: collapse;
  }

  position: absolute;
  width: 160px;
  left: -180px;
  height: 100%;
  
  & .ant-steps-item-tail::after {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
  
  & > div {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: -20px;
  }
`;

const ReportForm: React.FC<UseReportFormProps> = (props) => {
  const hook = useReportForm(props);
  
  const buttons = props.disabled
    ? null
    : (
      <ButtonsWrapper>
        <div className="spacer" />
        <div>
          <Popconfirm
            title={"Really delete clear the form?\nThis will remove all data currently entered."}
            onConfirm={hook.clearForm}
            okText="Yes"
            cancelText="No"
          >
            <IconButton
              danger
              icon={faTrash}
            >
              Clear form
            </IconButton>
          </Popconfirm>
          <IconButton
            icon={faSave}
            onClick={hook.saveDraft}
            loading={hook.saveDraftLoading}
          >
            Save draft
          </IconButton>
          <IconButton
            icon={faCheck}
            type="primary"
            onClick={hook.finalizeReport}
            loading={hook.submitReportLoading}
          >
            {SIMPLE_MODE ? "Send report" : "Finalize report"}
          </IconButton>
        </div>
      </ButtonsWrapper>
    );

  const assignHeaderRef = useCallback(
    (element: Element | null, index: number) => {
      hook.headersListRef.current = [
        ...hook.headersListRef.current.slice(0, index),
        ...(element != null ? [element] : []),
        ...hook.headersListRef.current.slice(index + (element != null ? 1 : 0)),
      ];
    },
    [],
  );

  const fieldGroups = hook.fieldGroups.map((group: FieldGroupWithStatus, groupIndex: number) => (
    <Group key={group.header} formDisabled={props.disabled ?? false}>
      <h2 ref={(e) => assignHeaderRef(e, groupIndex)}>
        {group.header}
      </h2>
      <ReportGroupBox>
        {group.subGroups.map((subGroup, subGroupIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <ReportGroupBoxContents key={`${group.header}_subGroup_${subGroupIndex}`}>
            {subGroup.fields.map((field) => {
              const wrappedProvider: WrappedFormItemProvider<any> = useMemo(
                () => hook.getWrappedProvider(field.fieldType),
                [field.fieldType, hook.getWrappedProvider],
              );
              const Provider = wrappedProvider.provider;

              const onDirtyChange = useCallback(hook.getOnDirtyChange(field.fieldType), [field.fieldType]);
              const onResultChange = useCallback(hook.getOnResultChange(field.fieldType), [field.fieldType]);

              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${group.header}_subGroup_${subGroupIndex}_${field.fieldType}`}
                  style={{
                    marginBottom: "24px",
                  }}
                >
                  <Provider
                    settings={props.userSettings}
                    // @ts-ignore
                    fieldStates={wrappedProvider.usesFieldStates ? hook.fieldStates : undefined}
                    previousReport={props.previousReport}
                    onDirtyChange={onDirtyChange}
                    onResultChange={onResultChange}
                    dirty={hook.fieldStates[field.fieldType].dirty}
                    result={hook.fieldStates[field.fieldType].result}
                    required={hook.fieldStates[field.fieldType].required}
                    reportType={props.reportType}
                  />
                </div>
              );
            })}
            {/* eslint-disable-next-line react/no-array-index-key */}
            {subGroupIndex < group.subGroups.length && <br key={`${group.header}_spacer_${subGroupIndex}`} />}
          </ReportGroupBoxContents>
        ))}
      </ReportGroupBox>
    </Group>
  ));

  return (
    <>
      <Prompt
        when={hook.shouldBlockNavigation}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <StyledReportForm>
        <FormProgress>
          <div>
            <h2>Progress</h2>
            <Steps
              direction="vertical"
              current={hook.currentProgressStep}
              onChange={hook.onProgressStepClick}
            >
              {hook.fieldGroups.map((group) => (
                <Steps.Step
                  key={group.header}
                  title={group.header}
                  status={group.fillStatus}
                />
              ))}
            </Steps>
          </div>
        </FormProgress>
        <div>
          <fieldset disabled={props.disabled}>
            {fieldGroups}
          </fieldset>
        </div>
        <Affix
          offsetBottom={0}
          onChange={(isFloating): void => hook.setButtonsFloating(isFloating ?? false)}
        >
          {buttons}
        </Affix>
      </StyledReportForm>
    </>
  );
};

export default ReportForm;
