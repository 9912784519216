import React from "react";
import {
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Routes from "./routes";
import NewReportPage from "./pages/new-report/NewReportPage";
import ReportsListPage from "./pages/reports-list/ReportsListPage";
import ViewReportPage from "./pages/view-report/ViewReportPage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import EditReportPage from "./pages/edit-report/EditReportPage";
import SupportPage from "./pages/support/SupportPage";
import SelectReportPage from "./pages/select-report/SelectReportPage";

const ContentRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={Routes.SelectReport.generate()} />
      </Route>
      <Route path={Routes.ReportsList.template} component={ReportsListPage} exact={Routes.ReportsList.exact} />
      <Route path={Routes.Report.template} component={ViewReportPage} exact={Routes.Report.exact} />
      <Route path={Routes.EditReport.template} component={EditReportPage} exact={Routes.EditReport.exact} />
      <Route path={Routes.NewReport.template} component={NewReportPage} exact={Routes.NewReport.exact} />
      <Route path={Routes.SelectReport.template} component={SelectReportPage} exact={Routes.SelectReport.exact} />
      <Route path={Routes.Support.template} component={SupportPage} exact={Routes.Support.exact} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default ContentRouter;