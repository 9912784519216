import {
  err,
  ok,
  Result,
} from "neverthrow";
import ValidationError from "./errors/ValidationError";
import CardinalDirection from "./CardinalDirection";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";
import { Degrees } from "./units/Degrees";

@serializableDecorator<Direction>()
class Direction {
  private constructor(
    private degrees: Degrees,
  ) {}

  public static fromDegrees(input: Degrees, fieldName: string = "Direction"): Result<Direction, ValidationError[]> {
    if (input.value < 0) {
      return err([new ValidationError(`${fieldName} must be 0° or above.`, { userFacing: true })]);
    }
    if (input.value >= 360) {
      return err([new ValidationError(`${fieldName} must be below 360°.`, { userFacing: true })]);
    }
    return ok(new Direction(input));
  }

  public static fromCardinal(cardinalDirection: CardinalDirection): Result<Direction, ValidationError[]> {
    return ok(new Direction(new Degrees(cardinalDirection)));
  }

  static serialize(obj: Direction): Result<Serialized, SerializationError> {
    return ok({ degrees: obj.degrees, __className: Direction.name });
  }

  // Precision is how many cardinal points to chose from when snapping.

  static deserialize(serialized: Serialized): Result<Direction, SerializationError> {
    return ok(new Direction(serialized.degrees));
  }

  public toDegrees(): Degrees {
    return this.degrees;
  }

  // 4 would be N/E/S/W, 8 would be N/NE/E/SE/S/SW/W/NW, and so on
  public toCardinal(precision: 4 | 8 | 16): CardinalDirection {
    const dirCount = Object.keys(CardinalDirection).length / 2;
    const degreesPerDir = 360 / dirCount;
    const degreesPerLevel = 360 / precision;
    const dirsPerLevel = dirCount / precision;
    const index = (Math.round((this.degrees.value % 360) / degreesPerLevel) * dirsPerLevel) % dirCount;
    const snappedDegrees = index * degreesPerDir;
    const dir = CardinalDirection[CardinalDirection[snappedDegrees]];
    if (dir === undefined) {
      throw new Error(`Could not convert degrees (${this.degrees}°) to cardinal direction.`);
    }
    return dir;
  }
}

export default Direction;
