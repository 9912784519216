enum AnchorOrBerthAction {
  BerthArrival = "Arrival at berth",
  PilotOnBoard = "Pilot onboard",
  firstLineAshore = "1st line ashore",
}
  
export const anchorOrBerthActionOptions = [{ 
  label: AnchorOrBerthAction.BerthArrival, 
  value: AnchorOrBerthAction.BerthArrival, 
},
{
  label: AnchorOrBerthAction.PilotOnBoard,
  value: AnchorOrBerthAction.PilotOnBoard,
},
{
  label: AnchorOrBerthAction.firstLineAshore,
  value: AnchorOrBerthAction.firstLineAshore,
},
];
  
export default AnchorOrBerthAction;