import React from "react";
import { FormItemProvider } from "../../FormItemProvider";
import AnchorOrBerthActionsItemsFormItem from "./BerthingActionsItemsFormItem";
import AnchorOrBerthActionItem from "../../../../../domain/BerthingActionItem";

const AnchorOrBerthActionsItemsFormItemProvider: FormItemProvider<AnchorOrBerthActionItem[]> = (props) => {
  const { settings: _settings } = props;
  return (
    <AnchorOrBerthActionsItemsFormItem
      {...props}
    />
  );
};

export default React.memo(AnchorOrBerthActionsItemsFormItemProvider);