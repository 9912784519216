import TextReportGenerator from "../TextReportGenerator";
import ReportData from "../../ReportData";
import { saveClassByClassNameDecorator } from "../../../../shared/utils/classNameToConstructor";
import { DisplayedField } from "../../DisplayedField";

const DEFAULT_VERSION = "1";

const TITLE: string = "ClearVoyage Noon At Sea Report";

const FIELDS: DisplayedField[] = [
  { fieldType: "IMONumber", required: true },
  { fieldType: "Position", required: true },
  { fieldType: "Captain", required: true },
  { fieldType: "ReportDate", required: true },
  { fieldType: "Remarks", required: false },
  { fieldType: "ETAPort1", required: false },
  { fieldType: "ETADate1", required: false },
  { fieldType: "ETAPort2", required: false },
  { fieldType: "ETADate2", required: false },
  { fieldType: "LoadCondition", required: true },
  { fieldType: "MainEnginePower", required: false },
  { fieldType: "OrderedSpeed", required: false },
  { fieldType: "PresentSpeed", required: true },
  { fieldType: "Heading", required: true },
  { fieldType: "AverageSpeed", required: true },
  { fieldType: "Slip", required: false },
  { fieldType: "ConsumptionItems", required: true },
  { fieldType: "ROBItems", required: true },
  { fieldType: "AverageRPM", required: false },
  { fieldType: "WindDirection", required: true },
  { fieldType: "WindForce", required: true },
  { fieldType: "CurrentDirection", required: true },
  { fieldType: "CurrentSpeed", required: true },
  { fieldType: "SwellDirection", required: true },
  { fieldType: "SwellHeight", required: true },
  { fieldType: "SeaDirection", required: true },
  { fieldType: "SeaHeight", required: true },
];

@saveClassByClassNameDecorator()
class ClearVoyageNoonReportGenerator extends TextReportGenerator {
  public constructor() {
    super(TITLE, FIELDS, DEFAULT_VERSION);
  }

  getReportText(_data: ReportData, _version: string | undefined): string {
    return "";
  }
}

export default ClearVoyageNoonReportGenerator;
