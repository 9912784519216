import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<Percent>()
export class Percent extends NumberUnit {
  static serialize(unit: Percent): Result<Serialized, SerializationError> {
    return super._serialize(unit, Percent);
  }

  static deserialize(serialized: Serialized): Result<Percent, SerializationError> {
    return super._deserialize(serialized, Percent);
  }
}