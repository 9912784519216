import {
  ok,
  Result,
} from "neverthrow";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormItemProps } from "./FormItem";
import ValidationError from "../../../domain/errors/ValidationError";
import Suggestion from "./Suggestion";

export type UseSingleFormItemProps<I, O> = {
  inputToResult: (input: I | undefined) => Result<O, ValidationError[]>,
  outputToInput: (output: O) => I,
  inputsEqual: (i1: I | undefined, i2: I | undefined) => boolean,
  dontAutoUpdateDirty?: boolean,
};

export type UseSingleFormItemHook<I, O> = {
  input: I | undefined,
  onInputChange: (newInput: I | undefined | SetStateAction<I | undefined>) => void,
  isOk: boolean,
  isError: boolean,
  error: ValidationError[] | undefined,
  pickSuggestion: (suggestion: Suggestion<O>) => void,
  showSuggestions: boolean,
  _setInput: Dispatch<SetStateAction<I | undefined>>,
};

type Props<I, O> =
  & UseSingleFormItemProps<I, O>
  & FormItemProps<O>;

export const useSingleFormItem = <I, O>(
  props: Props<I, O>,
): UseSingleFormItemHook<I, O> => {
  const {
    onResultChange,
    inputToResult,
    onDirtyChange,
    result,
    outputToInput,
    dirty,
    inputsEqual,
    dontAutoUpdateDirty,
  } = props;

  const [input, setInput] = useState<I>();

  const updateInput = useCallback((newInputOrSetter: SetStateAction<I | undefined>) => {
    let newInput;

    setInput((prevInput) => {
      if (typeof newInputOrSetter === "function") {
        newInput = (newInputOrSetter as ((prevState: I | undefined) => I))(prevInput);
      } else {
        newInput = newInputOrSetter;
      }

      if (
        !((newInput === undefined && result != null) &&
        (!props.required || !props.dirty.value))
      ) {
        if (newInput != null) {
          const newResult = inputToResult(newInput);
          onResultChange(newResult);
          const oldInput = result?.isOk() ? outputToInput(result.value) : undefined;

          if (!dontAutoUpdateDirty && newInput !== oldInput) {
            onDirtyChange({ value: true });
          }
        }
      } else {
        onResultChange(undefined);
      }

      return newInput;
    });
  }, [onResultChange, onDirtyChange, inputToResult, dirty]);

  const pickSuggestion = useCallback((suggestion: Suggestion<O>): void => {
    props.onResultChange(ok(suggestion.value));
  }, [props.onResultChange]);

  // On result update
  useEffect(() => {
    if (result === null) {
      updateInput(undefined);
      return;
    }

    if (result?.isOk()) {
      const newInput = outputToInput(result.value);
      if (inputsEqual(newInput, input)) {
        return;
      }
      setInput(() => newInput);
    }
  }, [result, updateInput, setInput, outputToInput, inputsEqual]);

  const isOk = result?.isOk() ?? false;

  const resultError = (dirty.value && (result?.isErr() ?? true));
  const inputEmpty = (!props.required && input === undefined);
  const isError = resultError && !inputEmpty;

  const error = result?.isErr() ? result?.error : undefined;

  const showSuggestions = input === undefined;

  return {
    input,
    onInputChange: updateInput,
    isOk,
    isError,
    error,
    pickSuggestion,
    showSuggestions,
    _setInput: setInput,
  };
};