import { FieldType } from "./FieldTypes";

/* ------------------------------------------------------------ */

export type FieldTypeSubGroup = {
  fieldTypes: FieldType[],
};
export type FieldTypeGroup = {
  header: string,
  subGroups: FieldTypeSubGroup[],
};

/* ------------------------------------------------------------ */

export const FORM_STRUCTURE: FieldTypeGroup[] = [
  {
    header: "General",
    subGroups: [
      {
        fieldTypes: [
          "IMONumber",
          "VesselName",
          "CallSign",
          "Captain",
          "ReportDate",
          "Remarks",
        ],
      },
    ],
  },
  {
    header: "Position",
    subGroups: [
      {
        fieldTypes:
          [
            "Position",
            "NamedLocation",
          ],
      },
    ],
  },
  {
    header: "Ports",
    subGroups: [
      {
        fieldTypes:
          [
            "BerthName",
            "NumberOfTugs",
            "Anchorage",
            "CurrentPort",
            "DeparturePort",
            "DeparturePortETD",
            "ArrivalPort",
            "ArrivalPortETA",
          ],
      },
    ],
  },
  {
    header: "Activities",
    subGroups: [
      {
        fieldTypes: [
          "StatementOfFactsActivities",
        ],
      },
    ],
  },
  {
    header: "Upcoming Ports",
    subGroups: [
      {
        fieldTypes:
        [
          "ETAPort1",
          "ETADate1",
        ],
      },
      {
        fieldTypes:
          [
            "ETAPort2",
            "ETADate2",
          ],
      },
    ],
  },
  {
    header: "Condition",
    subGroups: [{
      fieldTypes:
        [
          "LoadCondition",
          "NextCondition",
          "DraftFore",
          "DraftMid",
          "DraftAft",
          "CargoWeight",
          "BallastWeight",
          "FreshWater",
        ],
    }],
  },
  {
    header: "Actions",
    subGroups: [{
      fieldTypes:
        [
          "AnchorOrBerthActionsItems",
        ],
    }],
  },
  {
    header: "Bunkering",
    subGroups: [
      {
        fieldTypes: [
          "StartOfBunkeringTime",
          "EndOfBunkeringTime",
        ],
      },
      {
        fieldTypes: [
          "BunkersReceived",
        ],
      },
    ],
  },
  {
    header: "Navigation",
    subGroups: [{
      fieldTypes:
        [
          "PresentSpeed",
          "Heading",
          "AverageSpeed",
          "ObservedDistance",
          "EngineDistance",
          "SteamingHours",
        ],
    }],
  },
  {
    header: "Weather",
    subGroups: [
      {
        fieldTypes:
          [
            "WindForce",
            "WindSpeed",
            "WindDirection",
          ],
      },
      {
        fieldTypes:
        [
          "SeaHeight",
          "SeaState",
          "SeaDirection",
        ],
      },
      {
        fieldTypes:
          [
            "SwellHeight",
            "SwellDirection",
          ],
      },
      {
        fieldTypes:
          [
            "CurrentSpeed",
            "CurrentDirection",
          ],
      },
      {
        fieldTypes:
          [
            "BadWeatherHours",
            "BadWeatherDistance",
            "Visibility",
          ],
      },
    ],
  },
  {
    header: "Performance",
    subGroups: [
      {
        fieldTypes:
          [
            "CurrentRPM",
            "AverageRPM",
            "Slip",
            "MainEngineMCR",
            "MainEnginePower",
            "MainEngineLoad",
          ],
      },
    ],
  },
  {
    header: "Cargo",
    subGroups: [
      {
        fieldTypes:
          [
            "CargoName",
          ],
      },
      {
        fieldTypes:
          [
            "CargoCommencementDate",
            "CargoCompletionDate",
          ],
      },
      {
        fieldTypes:
          [
            "CargoShipsQuantity",
            "CargoShoresQuantity",
            "CargoBillOfLadingQuantity",
            "ToBeLoadedDischarged",
            "TotalLoadedDischargedDay",
            "TotalLoadedDischarged",
          ],
      },
    ],
  },
  {
    header: "Consumption",
    subGroups: [
      {
        fieldTypes: [
          "ConsumptionItems",
          "ConsumptionItemsSOSP",
          "ConsumptionItemsEOSP",
        ],
      },
    ],
  },
  {
    header: "ROB",
    subGroups: [
      {
        fieldTypes:
          [
            "ROBItems",
          ],
      },
    ],
  },
  {
    header: "Charter Party",
    subGroups: [{
      fieldTypes:
        [
          "VoyageNumber",
          "VoyageCharterer",
          "CPDate",
          "OrderedSpeed",
          "SpeedInstructions",
          "OrderedRPM",
          "OrderedConsumptionItems",
        ],
    }],
  },
];
