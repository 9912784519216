import {
  err,
  ok,
  Result,
} from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";
import PortCode from "./PortCode";

export type PortId = number;

export type PortData = {
  id: number,
  name: string,
  unlocode: string,
  latitude: number,
  longitude: number
};

@serializableDecorator<Port>()
class Port {
  public constructor(
    public readonly id: PortId,
    public readonly name: string,
    public readonly portCode: PortCode | undefined,
  ) {}

  static fromPortData(portData: PortData): Port {
    let portCode;

    if (portData.unlocode != null && portData.unlocode !== "") {
      const portCodeResult = PortCode.fromString(portData.unlocode);
      if (portCodeResult.isOk()) {
        portCode = portCodeResult.value;
      }
    }

    return new Port(
      portData.id,
      portData.name,
      portCode,
    );
  }

  static serialize(obj: Port): Result<Serialized, SerializationError> {
    let serializedPortCodeResult;
    if (obj.portCode !== undefined) {
      serializedPortCodeResult = PortCode.serialize(obj.portCode);
      if (serializedPortCodeResult.isErr()) {
        return serializedPortCodeResult;
      }
    }

    return ok({
      __className: Port.name,
      portCode: serializedPortCodeResult?.value,
      id: obj.id,
      name: obj.name,
    });
  }

  static deserialize(serialized: Serialized): Result<Port, SerializationError> {
    let deserializedPortCodeResult;
    if (serialized.portCode !== undefined) {
      deserializedPortCodeResult = PortCode.deserialize(serialized.portCode);
      if (deserializedPortCodeResult.isErr()) {
        return err(deserializedPortCodeResult.error);
      }
    }

    return ok(new Port(
      serialized.id,
      serialized.name,
      deserializedPortCodeResult?.value,
    ));
  }
}

export default Port;
