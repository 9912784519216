import React from "react";
import { DateTime } from "luxon";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import GenericDateTimeFormItemProvider from "../../_generic/date/GenericDateTimeFormItemProvider";
import { DATE_TIME_OFFSET_FORMAT } from "../../../../../../shared/utils/date";
import assertUnreachable from "../../../../../../shared/utils/assertUnreachable";
import ReportType from "../../../../../../domain/reports/ReportType";

const getFieldName = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.NoonAtSea:
      return "Date";
    case ReportType.NoonInPort:
      return "Date";
    case ReportType.Berthing:
      return "Date";
    case ReportType.Arrival:
      return "Arrival Date";
    case ReportType.Departure:
      return "Departure Date";
    case ReportType.Delivery:
      return "Delivery Date";
    case ReportType.Redelivery:
      return "Redelivery Date";
    case ReportType.Bunkering:
      return "Date";
    case ReportType.StatementOfFacts:
      return "Date";

    default:
      assertUnreachable(reportType);
      return "Date";
  }
};

const ReportDateFormItemProvider: FormItemProvider<DateTime> = (props: FormItemProviderProps<DateTime>) => {
  const fieldName = getFieldName(props.reportType);

  return (
    <GenericDateTimeFormItemProvider
      {...props}
      fieldName={fieldName}
      fieldType="ReportDate"
      suggestPrevious={false}
      outputToSuggestionText={(o): string => o.toFormat(DATE_TIME_OFFSET_FORMAT)}
    />
  );
};

export default React.memo(ReportDateFormItemProvider);