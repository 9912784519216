import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import Port from "../../../../../../domain/Port";
import PortFormItemProvider from "../PortFormItemProvider";

const fieldName = "Departure Port";

const DeparturePortFormItemProvider: FormItemProvider<Port> = (props: FormItemProviderProps<Port>) => (
  <PortFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="DeparturePort"
  />
);

export default React.memo(DeparturePortFormItemProvider);
