import {
  combine,
  err,
  ok,
  Result,
} from "neverthrow";
import { DateTime } from "luxon";
import BaseError from "../errors/BaseError";
import ValidationError from "../../domain/errors/ValidationError";
import { indent } from "./indentation";

export type Serialized = { [key: string]: any } & { __className: string };

export class SerializationError extends BaseError {
  private __nominal!: void;

  constructor(
    message: string,
    public readonly validationErrors?: ValidationError[],
  ) {
    super(message);
  }
}

export interface Serializable<T> {
  serialize(obj: T): Result<Serialized, SerializationError>;

  deserialize(json: Serialized): Result<T, SerializationError>;
}

const classNameToClassMap: {
  [key: string]: Function & Serializable<any>
} = {};

export function getSerializableConstructor(className: string): Result<Function & Serializable<any>, SerializationError> {
  const constructor = classNameToClassMap[className];
  if (constructor === undefined) {
    return err(new SerializationError(`Class ${className} is not serializable.`));
  }
  return ok(constructor);
}

export function serializableDecorator<T>() {
  // This _generic stuff is done to ensure that it has the static methods of the Serializable interface.
  return <U extends Serializable<T>>(constructor: U & Function): void => {
    classNameToClassMap[constructor.name] = constructor;
  };
}

export function assertSerializedClass(obj?: any): void {
  if (obj?.__className === undefined) {
    throw Error(`Expected object to be a serialized class, but it was not.\n${indent(`Object: ${JSON.stringify(
      obj,
      null,
      2,
    )}`, 1)}`);
  }
}

export function serialize(original: any): Result<any, SerializationError> {
  if (original == null) {
    return ok(original);
  }

  if (typeof original.constructor.serialize === "function") {
    return original.constructor.serialize(original);
  }

  if (original.constructor === DateTime) {
    const iso = (original as DateTime).toISO({ includeOffset: true });
    return ok({ iso, __className: DateTime.name });
  }

  if (Array.isArray(original)) {
    return combine(original.map(serialize));
  }

  if (typeof original === "object") {
    const resultsObj: {
      [key: string]: Result<any, SerializationError>
    } = Object.entries(original)
      .reduce((acc, [key, v]) => {
        return {
          ...acc,
          [key]: serialize(v),
        };
      }, {});
    const combinedResult = combine(Object.values(resultsObj));
    if (combinedResult.isErr()) {
      return err(combinedResult.error);
    }
    return ok(
      Object.entries(resultsObj)
        .reduce((acc, [key, v]) => {
          return {
            ...acc,
            [key]: v._unsafeUnwrap(),
          };
        }, {}),
    );
  }

  if (![Number, String, DateTime, Boolean].includes(original.constructor)) {
    return err(new SerializationError(`Value of type "${original.constructor.name}" is not serializable.`));
  }

  return ok(original);
}

export function deserialize(original: any): Result<any, SerializationError> {
  if (original == null) {
    return ok(original);
  }

  if (original.__className !== undefined) {
    if (original.__className === DateTime.name) {
      const dateTime = DateTime.fromISO(original.iso, { setZone: true });
      return ok(dateTime);
    }

    return getSerializableConstructor(original.__className)
      .andThen((constructor) => constructor.deserialize(original));
  }

  if (Array.isArray(original)) {
    const array = original as any[];
    const result = combine(array.map(deserialize));
    // @ts-ignore
    return result as Result<T, SerializationError>;
  }

  if (typeof original === "object") {
    const resultsObj: {
      [key: string]: Result<any, SerializationError>,
    } = Object.entries(original)
      .reduce((acc, [key, v]) => {
        return {
          ...acc,
          [key]: deserialize(v),
        };
      }, {});
    const combinedResult = combine(Object.values(resultsObj));
    if (combinedResult.isErr()) {
      return err(combinedResult.error);
    }
    return ok(
      Object.entries(resultsObj)
        .reduce((acc, [key, v]) => {
          return {
            ...acc,
            [key]: v._unsafeUnwrap(),
          };
        }, {}),
    );
  }

  return ok(original);
}
