import {
  ok,
  Result,
} from "neverthrow";
import {
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";

export abstract class NumberUnit {
  constructor(
    public readonly value: number,
  ) {}

  protected static _serialize<T extends NumberUnit>(
    numberUnit: T,
    Type: new (value: number) => T,
  ): Result<Serialized, SerializationError> {
    return ok({ value: numberUnit.value, __className: Type.name });
  }

  protected static _deserialize<T extends NumberUnit>(
    serialized: Serialized,
    Type: new (value: number) => T,
  ): Result<T, SerializationError> {
    return ok(new Type(serialized.value));
  }

  public equals(that: NumberUnit): boolean {
    return this.value === that.value;
  }
}