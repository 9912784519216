import ReportType from "../../../domain/reports/ReportType";

const reportTypeToUrlMap: Record<ReportType, string> = {
  [ReportType.NoonAtSea]: "noon",
  [ReportType.NoonInPort]: "noon-in-port",
  [ReportType.Arrival]: "arrival",
  [ReportType.Departure]: "departure",
  [ReportType.Bunkering]: "bunkering",
  [ReportType.Delivery]: "delivery",
  [ReportType.Redelivery]: "redelivery",
  [ReportType.Berthing]: "berthing",
  [ReportType.StatementOfFacts]: "statement-of-facts",
};

export const reportTypeToUrl = (type: ReportType): string => {
  return reportTypeToUrlMap[type];
};

export const urlToReportType = (url: string): ReportType | undefined => {
  return Object.keys(reportTypeToUrlMap)
    .find((reportType) => (
      reportTypeToUrlMap[reportType] === url
    )) as ReportType | undefined;
};
