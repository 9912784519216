import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons/faExclamationTriangle";
import { faInbox } from "@fortawesome/pro-duotone-svg-icons/faInbox";
import styled from "styled-components";
import { Spin } from "antd";

type EmptyProps = { isEmpty: boolean, emptyText: string } | { isEmpty?: never, emptyText?: never };
type LoadingProps = { isLoading: boolean, loadingText: string } | { isLoading?: never, loadingText?: never };
type ErrorProps = { isError: boolean, errorText: string } | { isError?: never, errorText?: never };

type DataStatusProps = EmptyProps & LoadingProps & ErrorProps;

const StyledDataStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  font-size: 1.15em;
  color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  
  & > span {
    margin-top: 10px;
  }
`;

const DataStatus: React.FC<DataStatusProps> = ({
  emptyText,
  errorText,
  loadingText,
  isLoading,
  isError,
  isEmpty,
}) => {
  if (isLoading) {
    return (
      <StyledDataStatus>
        <Spin />
        <span>{loadingText}</span>
      </StyledDataStatus>
    );
  }

  if (isError) {
    return (
      <StyledDataStatus>
        <FontAwesomeIcon
          color="rgba(255, 0, 0, 0.5)"
          icon={faExclamationTriangle}
          size="2x"
        />
        <span>{errorText}</span>
      </StyledDataStatus>
    );
  }

  if (isEmpty) {
    return (
      <StyledDataStatus>
        <FontAwesomeIcon
          color="rgba(0, 0, 0, 0.1)"
          icon={faInbox}
          size="2x"
        />
        <span>{emptyText}</span>
      </StyledDataStatus>
    );
  }

  return null;
};

export default DataStatus;
