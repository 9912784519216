const minimumDelay = async <T>(promise: Promise<T>, minimumDelayMs: number): Promise<T> => {
  const startTime = Date.now();

  const result = await promise;

  const remainingDelay = Date.now() - startTime;

  if (remainingDelay < minimumDelayMs) {
    await new Promise((resolve) => setTimeout(resolve, minimumDelayMs - remainingDelay));
  }

  return result;
};

export default minimumDelay;
