import React, { useEffect } from "react";
import { Grid } from "antd";
import {
  useSync,
} from "./hooks/useSync";
import { subscribeToStorageEvent } from "./localReportStorage";
import Header from "./misc-components/Header";
import ContentRouter from "./ContentRouter";

const MainPage: React.FC = () => {
  const { sync } = useSync();
  const screens = Grid.useBreakpoint();

  const baseStyle = {
    padding: "50px 0",
    flex: "1 1 0px",
    overflow: "auto",
  };

  const mobileStyle = {
    padding: "50px 15px",
    flex: "1 1 0px",
    overflow: "auto",
  };

  useEffect(() => {
    subscribeToStorageEvent("save", sync);
    sync();
    const syncIntervalTime = process.env.NODE_ENV === "development"
      ? 60 * 1000
      : 60 * 60 * 1000;
    const syncInterval = setInterval(sync, syncIntervalTime);
    return (): void => clearInterval(syncInterval);
  }, []);
  
  return (
    <div style={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
    }}>
      <Header />
      <div style={screens.xs ? mobileStyle : baseStyle}>
        <ContentRouter />
      </div>
    </div>
  );
};

export default MainPage;