import moment from "moment";

export const toMomentInput = (dateString: string | undefined, format: "YYYY-MM-DD" | "HH:mm") => {
  let momentInput;
  if (
    dateString !== null &&
    dateString !== undefined &&
    dateString !== ""
  ) {
    const potentialInput = moment(dateString, format);

    if (potentialInput.isValid()) {
      momentInput = potentialInput;
    }
  }
  return momentInput;
};