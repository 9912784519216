import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import Suggestion from "./Suggestion";

export type SuggestionsWrapperProps<T> = {
  suggestions?: Suggestion<T>[],
  showSuggestions: boolean,
  pickSuggestion: (suggestion: Suggestion<T>) => void,
};

/* ------------------------------------------------------------ */

const SuggestionOption = styled.span`
  color: dodgerblue;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

function SuggestionsWrapper<T>(props: PropsWithChildren<SuggestionsWrapperProps<T>>): React.ReactElement {
  return (
    <div
      style={{
        flex: "1 1 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.children}
      {(
        props.showSuggestions &&
        props.suggestions !== undefined &&
        props.suggestions.length > 0
      ) && (
        <div className="suggestions">
          <span>Suggestions: </span>
          {props.suggestions.map((s, i) => {
            return (
              <React.Fragment key={s.text}>
                <SuggestionOption onClick={(): void => props.pickSuggestion(s)}>
                  {s.text}
                </SuggestionOption>
                {s.suffixText !== undefined && (<span> {s.suffixText}</span>)}
                {i < props.suggestions!.length - 1 && (<span>, </span>)}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SuggestionsWrapper;