const LOCAL_VERSION_KEY = "version";

export const getLocalVersion = async (): Promise<number | null> => {
  const versionString = await localStorage.getItem(LOCAL_VERSION_KEY);

  if (versionString === null) {
    return null;
  }

  return Number(versionString);
};

export const setLocalVersion = async (newVersion: number): Promise<void> => {
  await localStorage.setItem(LOCAL_VERSION_KEY, String(newVersion));
};
