import React, {
  useEffect,
  useMemo,
} from "react";
import { ok } from "neverthrow";
import Suggestion from "../../../Suggestion";
import {
  useSingleFormItem,
  UseSingleFormItemProps,
} from "../../../useSingleFormItem";
import {
  FormItemComponent,
  FormItemProps,
} from "../../../FormItem";
import SuggestionsWrapper from "../../../SuggestionsWrapper";
import StandardInputContainer from "../../../StandardInputContainer";
import { FormItemProviderProps } from "../../../FormItemProvider";
import { FieldType } from "../../../../../../domain/reports/FieldTypes";
import NumericInput from "../../../../misc-components/NumericInput";

type GenericNumberOptions = {
  allowNegative?: boolean,
  integersOnly?: boolean,
  separateThousands?: boolean,
  addonAfter?: React.ReactNode,
  addonBefore?: React.ReactNode,
};

type GenericNumberFormItemProps<O> =
  & FormItemProps<O>
  & UseSingleFormItemProps<number, O>
  & {
    fieldName: string,
  }
  & GenericNumberOptions;

function GenericNumberFormItem<O>(props: GenericNumberFormItemProps<O>): FormItemComponent<O> {
  const hook = useSingleFormItem<number, O>(props);

  return (
    <StandardInputContainer
      required={props.required}
      isOk={hook.isOk}
      isError={hook.isError}
      errors={hook.error}
      fieldName={props.fieldName}
    >
      <SuggestionsWrapper<O>
        pickSuggestion={hook.pickSuggestion}
        showSuggestions={hook.showSuggestions}
        suggestions={props.suggestions}
      >
        <NumericInput
          placeholder={props.fieldName}
          value={hook.input}
          onChange={hook.onInputChange}
          className={hook.isError ? "error" : undefined}
          separateThousands={props.separateThousands}
          addonAfter={props.addonAfter}
          addonBefore={props.addonBefore}
          allowNegative={props.allowNegative}
          integersOnly={props.integersOnly}
          returnUndefinedOnEmpty={true}
        />
      </SuggestionsWrapper>
    </StandardInputContainer>
  );
}

type GenericNumberFormItemProviderProps<O> =
  & FormItemProviderProps<O>
  & UseSingleFormItemProps<number, O>
  & GenericNumberOptions
  & {
    suggestPrevious?: boolean,
    autoFillPrevious?: boolean,
    fieldName: string,
    fieldType: FieldType,
  }
  & (
    {
      suggestPrevious: true,
      outputToSuggestionText: (output: O) => string
    } |
    {
      suggestPrevious?: false,
      outputToSuggestionText?: (output: O) => string
    }
  );

function GenericNumberFormItemProvider<O>(props: GenericNumberFormItemProviderProps<O>): React.ReactElement {
  const suggestions: Suggestion<O>[] = useMemo(() => {
    if (!props.suggestPrevious) {
      return [];
    }
    const potentialPrevValue = props.previousReport?.data?.[props.fieldType];
    if (potentialPrevValue === undefined || potentialPrevValue === null) {
      return [];
    }
    const prevValue: O = potentialPrevValue as unknown as O;
    return [{
      text: props.outputToSuggestionText(prevValue),
      value: prevValue,
    }];
  }, []);

  useEffect(() => {
    if (!props.autoFillPrevious) {
      return;
    }
    const potentialPrevValue = props.previousReport?.data?.[props.fieldType];
    if (
      potentialPrevValue === undefined ||
      potentialPrevValue === null ||
      (props.result?.isOk() && props.result.value !== undefined)
    ) {
      return;
    }
    const prevValue: O = potentialPrevValue as unknown as O;
    props.onResultChange(ok(prevValue));
  }, []);

  return (
    <GenericNumberFormItem
      suggestions={suggestions}
      {...props}
    />
  );
}

export default GenericNumberFormItemProvider;