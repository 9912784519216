import React from "react";
import { Select } from "antd";
import { Options } from "../Options";

type Props<I extends number | string> = {
  isError: boolean,
  placeholder: string,
  value: I | undefined,
  onChange: (value: I | undefined) => void,
  options: Options<I>,
  searchable?: boolean,
  allowClear?: boolean,
};

/* ------------------------------------------------------------ */

function SelectInput<I extends number | string>(props: Props<I>): React.ReactElement {
  return (
    <Select
      showSearch={props.searchable}
      className={props.isError ? "error" : undefined}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      allowClear={props.allowClear}
      filterOption={
        props.searchable
          ? (input, option): boolean => {
            if (option?.searchText == null) {
              return false;
            }

            return option.searchText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          : undefined
      }
    />
  );
}

export default SelectInput;
