import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { grey } from "@ant-design/colors";
import React from "react";

const TooltipIcon: React.FC<{ tooltip: string }> = ({ tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      <FontAwesomeIcon
        icon={faQuestionCircle}
        color={grey[0]}
        style={{
          cursor: "help",
        }}
      />
    </Tooltip>
  );
};

export default TooltipIcon;