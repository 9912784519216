import {
  useEffect,
  useState,
} from "react";
import { ping } from "../api";

const DELAY = 10 * 1000;

export const useIsOnline = (): boolean => {
  const [isOnline, setIsOnline] = useState(true);
  
  useEffect(() => {
    setInterval(async () => {
      (await ping()).match(
        () => setIsOnline(true),
        () => setIsOnline(false),
      );
    }, DELAY);
  }, []);

  return isOnline;
};