import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockTwelve } from "@fortawesome/pro-duotone-svg-icons/faClockTwelve";
import { faGreaterThan } from "@fortawesome/pro-duotone-svg-icons/faGreaterThan";
import { faAnchor } from "@fortawesome/pro-duotone-svg-icons/faAnchor";
import { faShip } from "@fortawesome/pro-duotone-svg-icons/faShip";
import { faFileAlt } from "@fortawesome/pro-duotone-svg-icons/faFileAlt";
import { NavLink } from "react-router-dom";
import { faDroplet } from "@fortawesome/pro-duotone-svg-icons/faDroplet";

import {
  Grid, Space, 
} from "antd";
import ReportType from "../../../../domain/reports/ReportType";
import Routes from "../../routes";

type SelectReportProps = {};

type SelectReportHook = {};

export const useSelectReportPage = (_props: SelectReportProps): SelectReportHook => {
  return {};
};

/* ------------------------------------------------------------ */

const reportTypeToIconMap = {
  [ReportType.NoonAtSea]: faClockTwelve,
  [ReportType.NoonInPort]: faClockTwelve,
  [ReportType.Arrival]: faAnchor,
  [ReportType.Departure]: faShip,
  [ReportType.Delivery]: faFileAlt,
  [ReportType.Redelivery]: faFileAlt,
  [ReportType.Bunkering]: faDroplet,
  [ReportType.StatementOfFacts]: faFileAlt,
  [ReportType.Berthing]: faFileAlt,
};

const StyledSelectReportPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h1 {
    margin-bottom: 30px;
  }
  
  h2 {
    margin-bottom: 25px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px 20px;

    background: white;
    border-radius: 5px;
    border: 1px solid hsl(0deg 0% 90%);
  }
  
  .reportsSideBySide {
    display: flex;
    flex-direction: row;
  }
`;

const StyledTypeSelect = styled.div<{ sideBySide: boolean, responsive: boolean }>`
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  background: hsl(0deg 0% 98%);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  font-size: 1.5em;
  width: ${(props) => {
    const fullWidth = 500;
    
    if (props.sideBySide) {
      const halfWidth = (fullWidth / 2) - 10;
      return `${halfWidth}px`;
    }

    if (props.responsive) {
      return "100%";
    }
    
    return `${fullWidth}px`;
  }};
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: ${(props) => {
    const vert = 18;
    const hori = 14;
    
    return (
      props.sideBySide ?
        `0 ${Math.ceil(hori / 2)}px ${vert}px ${Math.floor(hori / 2)}px` :
        `0 0 ${vert}px 0`
    );
  }};

  :hover {
    cursor: pointer;
    background: hsl(0deg 0% 100%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }
  
  & > span {
    flex: 1;
    margin-left: 15px;
    color: black;
  }
`;

const getReportElement = (report: ReportType, sideBySide: boolean, responsive: boolean): JSX.Element => (
  <NavLink
    key={report}
    to={Routes.NewReport.generate(report)}
  >
    <StyledTypeSelect
      sideBySide={sideBySide}
      responsive={responsive}
    >
      <FontAwesomeIcon
        fixedWidth
        icon={reportTypeToIconMap[report]}
      />
      <span>{report}</span>
      <FontAwesomeIcon
        width={10}
        height={10}
        icon={faGreaterThan}
      />
    </StyledTypeSelect>
  </NavLink>
);

const SelectReportPage: React.FC<SelectReportProps> = (props) => {
  const _hook = useSelectReportPage(props);
  const screens = Grid.useBreakpoint();

  return (
    <StyledSelectReportPage>
      <div>
        <h1>New Report</h1>
        <h2>Select Report Type</h2>
        {screens.xs ? (
          <Space direction="vertical">
            {getReportElement(ReportType.NoonAtSea, false, screens.xs)}
            {getReportElement(ReportType.NoonInPort, false, screens.xs)}
            {getReportElement(ReportType.Arrival, false, screens.xs)}
            {getReportElement(ReportType.Departure, false, screens.xs)}
            {getReportElement(ReportType.Delivery, false, screens.xs)}
            {getReportElement(ReportType.Redelivery, false, screens.xs)}
            {getReportElement(ReportType.StatementOfFacts, false, screens.xs)}
            {getReportElement(ReportType.Bunkering, false, screens.xs)}
            {getReportElement(ReportType.Berthing, false, screens.xs)}
          </Space>
        ) : (
          <>
            <div className="reportsSideBySide">
              {getReportElement(ReportType.NoonAtSea, true, false)}
              {getReportElement(ReportType.NoonInPort, true, false)}
            </div>
            <div className="reportsSideBySide">
              {getReportElement(ReportType.Arrival, true, false)}
              {getReportElement(ReportType.Departure, true, false)}
            </div>
            <div className="reportsSideBySide">
              {getReportElement(ReportType.Delivery, true, false)}
              {getReportElement(ReportType.Redelivery, true, false)}
            </div>
            {getReportElement(ReportType.StatementOfFacts, false, false)}
            {getReportElement(ReportType.Bunkering, false, false)}
            {getReportElement(ReportType.Berthing, false, false)}
          </>
        )}
      </div>
    </StyledSelectReportPage>
  );
};

export default SelectReportPage;
