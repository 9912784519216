import React from "react";
import { Result } from "neverthrow";
import styled from "styled-components";
import {
  FormItem,
  FormItemProps,
} from "../../../../FormItem";
import StandardInputContainer from "../../../../StandardInputContainer";
import Longitude from "../../../../../../../domain/position/Longitude";
import { Degrees } from "../../../../../../../domain/units/Degrees";
import {
  Outputs,
  useCompositeFormItem,
  UseCompositeFormItemHook,
} from "../../../../useCompositeFormItem";
import ValidationError from "../../../../../../../domain/errors/ValidationError";
import DegreesInput from "../shared/DegreesInput";
import { eqeqeq } from "../../../../../../../shared/utils/equality";
import { unwrapValue } from "../../../../../../../shared/utils/unwrapValue";
import { notEmptyInputToResultWrapper } from "../../../../InputToResult";

type ChildInputTypes = {
  degrees: {
    input: number,
    output: Degrees,
  },
};

const childOutputsEqual = (outputs1: Outputs<ChildInputTypes>, outputs2: Outputs<ChildInputTypes>): boolean => (
  outputs1.degrees.equals(outputs2.degrees)
);

const childOutputsToResult = (outputs: Outputs<ChildInputTypes>): Result<Longitude, ValidationError[]> => (
  Longitude.fromDecimalDegrees(outputs.degrees)
);

const outputToChildOutputs = (output: Longitude): Outputs<ChildInputTypes> => ({
  degrees: output.toDecimalDegrees(),
});

export type DDFormItemProps =
  & FormItemProps<Longitude>;

export type DDFormItemHook =
  {
    degreesInputToResult: (input: number | undefined) => Result<Degrees, ValidationError[]>,
  }
  & UseCompositeFormItemHook<ChildInputTypes, Longitude>;

/* ------------------------------------------------------------ */

export const useDDLongitudeFormItem = (props: DDFormItemProps): DDFormItemHook => {
  const hook = useCompositeFormItem<ChildInputTypes, Longitude>({
    ...props,
    dataKeys: ["degrees"],
    outputToChildOutputs,
    childOutputsToResult,
    childOutputsEqual,
  });

  const degreesInputToResult = notEmptyInputToResultWrapper("Longitude", (input: number) => (
    Longitude.validateDecimalDegrees(new Degrees(input))
  ));

  return {
    ...hook,
    degreesInputToResult,
  };
};

/* ------------------------------------------------------------ */

const InputsWrapper = styled.div`
  & {
    display: flex;
    flex-direction: row;
  }
  
  & > *:not(:last-child) {
    flex: 2 1 0px;
    margin-right: 10px;
  }
  
  & > *:last-child {
    flex: 1 1 0px;
  }
`;

const DDLongitudeFormItem: FormItem<Longitude> = (props) => {
  const hook = useDDLongitudeFormItem({
    ...props,
  });

  return (
    <StandardInputContainer
      fieldName="Longitude"
      required={props.required}
      isError={hook.isError}
      isOk={hook.isOk}
    >
      <InputsWrapper>
        <DegreesInput
          result={hook.degrees.result}
          onResultChange={hook.degrees.onResultChange}
          dirty={hook.degrees.dirty}
          onDirtyChange={hook.degrees.onDirtyChange}
          outputToInput={unwrapValue}
          inputToResult={hook.degreesInputToResult}
          inputsEqual={eqeqeq}
          required={props.required}
          decimal={true}
        />
      </InputsWrapper>
    </StandardInputContainer>
  );
};

export default DDLongitudeFormItem;