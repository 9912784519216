import { ok } from "neverthrow";
import React from "react";
import { eqeqeq } from "../../../../../shared/utils/equality";
import identity from "../../../../../shared/utils/identity";
import { FormItemProvider, FormItemProviderProps } from "../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../InputToResult";
import GenericNumberFormItemProvider from "../_generic/number/GenericNumberFormItemProvider";

const fieldName = "Number of tugs";

const CurrentRPMFormItemProvider: FormItemProvider<number> = (props: FormItemProviderProps<number>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="NumberOfTugs"
    inputToResult={notEmptyInputToResultWrapper(fieldName, ok)}
    outputToInput={identity}
    separateThousands={false}
    allowNegative={false}
    integersOnly={true}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(CurrentRPMFormItemProvider);