import {
  ok,
  Result,
} from "neverthrow";
import { DateTime } from "luxon";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";

export const STATEMENT_OF_FACTS_ACTIVITY_TYPE_ARRAY = [
  "All fast",
  "Anchored",
  "Arrived pilot station",
  "Berthed",
  "Cargo documents aboard",
  "Commenced discharging",
  "Commenced loading",
  "Completed discharging",
  "Completed loading",
  "Departed pilot station",
  "First line",
  "Free pratique granted",
  "Notice of Readiness",
  "Pilot disembarked",
  "Pilot onboard",
  "Unberthed",
] as const;

export type StatementOfFactActivityType = typeof STATEMENT_OF_FACTS_ACTIVITY_TYPE_ARRAY[number];

@serializableDecorator<StatementOfFactActivity>()
class StatementOfFactActivity {
  public constructor(
    public id: string,
    public type: StatementOfFactActivityType,
    public date: DateTime,
    public note?: string,
  ) {}

  static serialize(obj: StatementOfFactActivity): Result<Serialized, SerializationError> {
    return ok({
      id: obj.id,
      type: obj.type,
      date: obj.date.toISO({ includeOffset: true }),
      note: obj.note,
      __className: StatementOfFactActivity.name,
    });
  }

  static deserialize(serialized: Serialized): Result<StatementOfFactActivity, SerializationError> {
    return ok(new StatementOfFactActivity(
      serialized.id,
      serialized.type,
      DateTime.fromISO(serialized.date, { setZone: true }),
      serialized.note,
    ));
  }
}

export default StatementOfFactActivity;