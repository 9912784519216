import { DateTime } from "luxon";
import ReportData from "../../domain/reports/ReportData";
import ImoNumber from "../../domain/ImoNumber";
import CallSign from "../../domain/CallSign";
import { Coordinates } from "../../domain/position/Coordinates";
import Latitude from "../../domain/position/Latitude";
import Longitude from "../../domain/position/Longitude";
import Hemisphere from "../../domain/position/Hemisphere";
import LoadCondition from "../../domain/LoadCondition";
import Direction from "../../domain/Direction";
import CardinalDirection from "../../domain/CardinalDirection";
import FuelType from "../../domain/FuelType";
import EngineType from "../../domain/EngineType";
import Knots from "../../domain/units/Knots";
import ConsumptionReason from "../../domain/ConsumptionReason";
import { newId } from "../../domain/Id";
import { Meters } from "../../domain/units/Meters";
import { KiloWattHours } from "../../domain/units/KiloWattHours";
import { NauticalMiles } from "../../domain/units/NauticalMiles";
import { Degrees } from "../../domain/units/Degrees";
import { Hours } from "../../domain/units/Hours";
import { Percent } from "../../domain/units/Percent";
import { Tons } from "../../domain/units/Tons";
import { Seconds } from "../../domain/units/Seconds";
import { Minutes } from "../../domain/units/Minutes";
import Beaufort from "../../domain/units/Beaufort";
import SpeedInstructions, { SpeedInstructionOptions } from "../../domain/SpeedInstructions";
import Port from "../../domain/Port";
import ports from "../../domain/ports.json";
import DouglasSeaScale from "../../domain/units/DouglasSeaScale";
import { CargoQuantity } from "../../domain/units/CargoQuantity";
import { Quantity } from "../../domain/units/Quantity";
import CargoUnit from "../../domain/CargoUnit";
import { NamedLocation } from "../../domain/NamedLocation";
import StatementOfFactActivity from "../../domain/StatementOfFactActivity";
import AnchorOrBerthAction from "../../domain/BerthingAction";

export const STH_EXAMPLE: ReportData = {
  // GENERAL
  VesselName: "STH Montreal",
  IMONumber: ImoNumber.fromString("9800635")._unsafeUnwrap(),
  CallSign: CallSign.fromString("V7A4398")._unsafeUnwrap(),
  ReportDate: DateTime.fromISO("2019-03-28T12:00:00+05:30"),
  Captain: "Kaev, Athur",
  Position: new Coordinates(
    Latitude.fromDegreesMinutesSeconds({
      degrees: new Degrees(5),
      minutes: new Minutes(52),
      seconds: new Seconds(0),
      hemisphere: Hemisphere.N,
    })._unsafeUnwrap(),
    Longitude.fromDegreesMinutesSeconds({
      degrees: new Degrees(81),
      minutes: new Minutes(55),
      seconds: new Seconds(0),
      hemisphere: Hemisphere.E,
    })._unsafeUnwrap(),
  ),
  Remarks: "Vessel Rolling easily. Ship's time retard by 30 mins.",

  // CONDITION
  LoadCondition: LoadCondition.Laden,
  CargoWeight: new Tons(1100),
  BallastWeight: new Tons(900),
  DraftAft: new Meters(2.2),
  DraftFore: new Meters(2.1),

  // CP
  VoyageNumber: "01-2019",
  CPDate: DateTime.fromISO("2019-02-26"),
  OrderedSpeed: new Knots(12.0),
  SpeedInstructions: new SpeedInstructions(SpeedInstructionOptions.Eco),
  OrderedRPM: 83,
  OrderedConsumptionItems: [
    {
      id: newId(),
      fuel: FuelType.HS_FO,
      consumptionPerDay: new Tons(18.2),
    },
    {
      id: newId(),
      fuel: FuelType.LS_DO,
      consumptionPerDay: new Tons(1.7),
    },
  ],

  // NAVIGATION
  AverageSpeed: new Knots(12.16),
  PresentSpeed: new Knots(12.16),
  ObservedDistance: new NauticalMiles(298),
  SteamingHours: new Hours(24.5),
  Heading: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),

  // PERFORMANCE
  CurrentRPM: 82.86,
  AverageRPM: 83,
  Slip: new Percent(7.91),
  MainEnginePower: new KiloWattHours(4100),
  MainEngineLoad: new Percent(90),

  // WEATHER
  SeaDirection: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),
  SeaState: DouglasSeaScale.fromNumber(1)._unsafeUnwrap(),
  WindSpeed: Knots.fromMetersPerSecond(12.5),
  WindDirection: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),
  CurrentSpeed: new Knots(0.2),
  CurrentDirection: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),
  WindForce: Beaufort.fromNumber(3)._unsafeUnwrap(),
  SwellDirection: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),
  SwellHeight: new Meters(1.5),

  // ROB
  ROBItems: [
    {
      id: newId(),
      fuel: FuelType.HS_FO,
      quantity: new Tons(1346),
    },
    {
      id: newId(),
      fuel: FuelType.VLS_FO,
      quantity: new Tons(0),
    },
    {
      id: newId(),
      fuel: FuelType.LS_DO,
      quantity: new Tons(114.8),
    },
    {
      id: newId(),
      fuel: FuelType.LS_DO,
      quantity: new Tons(0),
    },
  ],

  // CONSUMPTION
  ConsumptionItems: [
    {
      id: newId(),
      engine: EngineType.MainEngine,
      fuel: FuelType.HS_FO,
      reason: ConsumptionReason.Propulsion,
      consumption: new Tons(18.2),
      time: new Hours(24),
    },
    {
      id: newId(),
      engine: EngineType.AuxEngine,
      fuel: FuelType.HS_FO,
      reason: ConsumptionReason.Propulsion,
      consumption: new Tons(1.7),
      time: new Hours(24),
    },
  ],

  // PORTS
  ArrivalPortETA: DateTime.fromISO("2019-03-23T16:00:00"),
  ArrivalPort: Port.fromPortData(ports.find((p) => p.name === "MUMBAI")!),
};

export const DEV_DATA: ReportData = {
  VesselName: "STH Montreal",
  CallSign: CallSign.fromString("V7A4398")._unsafeUnwrap(),
  ReportDate: DateTime.fromISO("2019-03-28T12:00:00+05:30"),
  Captain: "Kaev, Athur",
  Position: new Coordinates(
    Latitude.fromDegreesMinutesSeconds({
      degrees: new Degrees(5),
      minutes: new Minutes(52),
      seconds: new Seconds(0),
      hemisphere: Hemisphere.N,
    })._unsafeUnwrap(),
    Longitude.fromDegreesMinutesSeconds({
      degrees: new Degrees(81),
      minutes: new Minutes(55),
      seconds: new Seconds(0),
      hemisphere: Hemisphere.E,
    })._unsafeUnwrap(),
  ),
  NamedLocation: NamedLocation.fromPort(
    Port.fromPortData(
      ports.find((p) => p.name === "SINGAPORE")!,
    ),
  )._unsafeUnwrap(),
  Remarks: "Vessel Rolling easily. Ship's time retard by 30 mins.",

  // CONDITION
  LoadCondition: LoadCondition.Laden,
  CargoWeight: new Tons(0),
  BallastWeight: new Tons(900),
  FreshWater: new Tons(100),
  DraftAft: new Meters(2.2),
  DraftMid: new Meters(2.15),
  DraftFore: new Meters(2.1),

  // CP
  VoyageNumber: "01-2019",
  CPDate: DateTime.fromISO("2019-02-26"),
  OrderedSpeed: new Knots(12.0),
  SpeedInstructions: new SpeedInstructions(SpeedInstructionOptions.Eco),
  OrderedRPM: 83,
  OrderedConsumptionItems: [
    {
      id: newId(),
      fuel: FuelType.HS_FO,
      consumptionPerDay: new Tons(18.2),
    },
    {
      id: newId(),
      fuel: FuelType.LS_DO,
      consumptionPerDay: new Tons(1.7),
    },
  ],

  // NAVIGATION
  AverageSpeed: new Knots(12.16),
  ObservedDistance: new NauticalMiles(298),
  SteamingHours: new Hours(24.5),
  Heading: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),

  // ETA
  ETAPort1: Port.fromPortData(ports.find((p) => p.name === "SINGAPORE")!),
  ETADate1: DateTime.fromISO("2022-06-26"),
  ETAPort2: Port.fromPortData(ports.find((p) => p.name === "CHIBA")!),
  ETADate2: DateTime.fromISO("2022-07-06"),

  // PERFORMANCE
  CurrentRPM: 82.86,
  AverageRPM: 83,
  Slip: new Percent(7.91),
  MainEnginePower: new KiloWattHours(4100),
  MainEngineLoad: new Percent(90),

  // WEATHER
  SeaDirection: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),
  SeaState: DouglasSeaScale.fromNumber(3)._unsafeUnwrap(),
  WindSpeed: Knots.fromMetersPerSecond(12.5),
  WindDirection: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),
  WindForce: Beaufort.fromNumber(3)._unsafeUnwrap(),
  CurrentSpeed: new Knots(0.2),
  CurrentDirection: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),
  SwellDirection: Direction.fromCardinal(CardinalDirection.ENE)._unsafeUnwrap(),
  SwellHeight: new Meters(1.5),

  // ROB
  ROBItems: [
    {
      id: newId(),
      fuel: FuelType.HS_FO,
      quantity: new Tons(1346),
    },
    {
      id: newId(),
      fuel: FuelType.VLS_FO,
      quantity: new Tons(0),
    },
    {
      id: newId(),
      fuel: FuelType.LS_DO,
      quantity: new Tons(114.8),
    },
    {
      id: newId(),
      fuel: FuelType.HS_DO,
      quantity: new Tons(0),
    },
  ],

  // BUNKERING
  BunkersReceived: [
    {
      id: newId(),
      fuel: FuelType.HS_FO,
      quantity: new Tons(200),
    },
    {
      id: newId(),
      fuel: FuelType.LS_FO,
      quantity: new Tons(60),
    },
  ],
  StartOfBunkeringTime: DateTime.fromISO("2022-06-23T16:00:00"),
  EndOfBunkeringTime: DateTime.fromISO("2022-06-23T17:00:00"),

  // CONSUMPTION
  ConsumptionItems: [
    {
      id: newId(),
      engine: EngineType.MainEngine,
      fuel: FuelType.HS_FO,
      reason: ConsumptionReason.Propulsion,
      consumption: new Tons(18.2),
      time: new Hours(24),
    },
    {
      id: newId(),
      engine: EngineType.AuxEngine,
      fuel: FuelType.HS_FO,
      reason: ConsumptionReason.Propulsion,
      consumption: new Tons(1.7),
      time: new Hours(24),
    },
  ],
  ConsumptionItemsSOSP: [
    {
      id: newId(),
      engine: EngineType.MainEngine,
      fuel: FuelType.HS_FO,
      reason: ConsumptionReason.Propulsion,
      consumption: new Tons(18.2),
      time: new Hours(24),
    },
    {
      id: newId(),
      engine: EngineType.AuxEngine,
      fuel: FuelType.HS_FO,
      reason: ConsumptionReason.Propulsion,
      consumption: new Tons(1.7),
      time: new Hours(24),
    },
  ],
  ConsumptionItemsEOSP: [
    {
      id: newId(),
      engine: EngineType.MainEngine,
      fuel: FuelType.HS_FO,
      reason: ConsumptionReason.Propulsion,
      consumption: new Tons(18.2),
      time: new Hours(24),
    },
    {
      id: newId(),
      engine: EngineType.AuxEngine,
      fuel: FuelType.HS_FO,
      reason: ConsumptionReason.Propulsion,
      consumption: new Tons(1.7),
      time: new Hours(24),
    },
  ],

  // PORTS
  DeparturePortETD: DateTime.fromISO("2019-03-23T16:00:00"),
  ArrivalPortETA: DateTime.fromISO("2019-03-23T16:00:00"),
  ArrivalPort: Port.fromPortData(ports.find((p) => p.name === "MUMBAI")!),

  VoyageCharterer: "Some Charterer",
  EngineDistance: new NauticalMiles(313),
  MainEngineMCR: new Percent(90),
  SeaHeight: new Meters(1),
  BadWeatherHours: new Hours(1),
  BadWeatherDistance: new NauticalMiles(30),
  Visibility: new NauticalMiles(30),
  DeparturePort: Port.fromPortData(ports.find((p) => p.name === "SINGAPORE")!),
  CurrentPort: Port.fromPortData(ports.find((p) => p.name === "SINGAPORE")!),

  // CARGO
  CargoName: "Caustic Soda",
  CargoBillOfLadingQuantity: new CargoQuantity(new Quantity(6300), CargoUnit.Tons),
  CargoShipsQuantity: new CargoQuantity(new Quantity(6300), CargoUnit.Unit),
  CargoShoresQuantity: new CargoQuantity(new Quantity(6300), CargoUnit.m3),
  CargoCompletionDate: DateTime.fromISO("2019-03-28T16:00:00+05:30"),
  CargoCommencementDate: DateTime.fromISO("2019-03-28T18:00:00+05:30"),
  ToBeLoadedDischarged: new CargoQuantity(new Quantity(6300), CargoUnit.Tons),
  TotalLoadedDischarged: new CargoQuantity(new Quantity(6300), CargoUnit.Tons),
  TotalLoadedDischargedDay: new CargoQuantity(new Quantity(6300), CargoUnit.Tons),

  // ANCHORING & BERTHING
  AnchorOrBerthActionsItems: [
    {
      id: "111",
      action: AnchorOrBerthAction.BerthArrival,
      actionDate: DateTime.fromISO("2022-07-05T18:00:00+05:30"),
    },
    {
      id: "234",
      action: AnchorOrBerthAction.PilotOnBoard,
      actionDate: DateTime.fromISO("2022-07-06T19:00:00+05:30"),
    },
  ],

  // STATEMENT OF FACTS
  StatementOfFactsActivities: [
    new StatementOfFactActivity(
      "1",
      "All fast",
      DateTime.now(),
      "This is a note!",
    ),
  ],
};
