import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<Meters>()
export class Meters extends NumberUnit {
  static serialize(unit: Meters): Result<Serialized, SerializationError> {
    return super._serialize(unit, Meters);
  }

  static deserialize(serialized: Serialized): Result<Meters, SerializationError> {
    return super._deserialize(serialized, Meters);
  }
}