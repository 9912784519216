import TextReportGenerator from "../TextReportGenerator";
import ReportData from "../../ReportData";
import { saveClassByClassNameDecorator } from "../../../../shared/utils/classNameToConstructor";
import { DisplayedField } from "../../DisplayedField";

const DEFAULT_VERSION = "1";

const TITLE: string = "ClearVoyage Berthing Report";

const FIELDS: DisplayedField[] = [
  { fieldType: "IMONumber", required: true },
  { fieldType: "Captain", required: true },
  { fieldType: "ReportDate", required: true },
  { fieldType: "LoadCondition", required: true },
  { fieldType: "NumberOfTugs", required: false },
  { fieldType: "BerthName", required: true },
  { fieldType: "NamedLocation", required: true },
  { fieldType: "AnchorOrBerthActionsItems", required: true },
  { fieldType: "ROBItems", required: true },
  { fieldType: "Remarks", required: false },
];

@saveClassByClassNameDecorator()
class ClearVoyageBerthingReportGenerator extends TextReportGenerator {
  public constructor() {
    super(TITLE, FIELDS, DEFAULT_VERSION);
  }

  getReportText(_data: ReportData, _version: string | undefined): string {
    return "";
  }
}

export default ClearVoyageBerthingReportGenerator;