import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<Seconds>()
export class Seconds extends NumberUnit {
  static serialize(unit: Seconds): Result<Serialized, SerializationError> {
    return super._serialize(unit, Seconds);
  }

  static deserialize(serialized: Serialized): Result<Seconds, SerializationError> {
    return super._deserialize(serialized, Seconds);
  }
}