import { ok } from "neverthrow";
import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import identity from "../../../../../../shared/utils/identity";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Average RPM";

const AverageRPMFormItemProvider: FormItemProvider<number> = (props: FormItemProviderProps<number>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="AverageRPM"
    inputToResult={notEmptyInputToResultWrapper(fieldName, ok)}
    outputToInput={identity}
    separateThousands={true}
    allowNegative={false}
    integersOnly={false}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(AverageRPMFormItemProvider);