import React from "react";
import { Result } from "neverthrow";
import styled from "styled-components";
import {
  FormItemProps,
} from "../../../../FormItem";
import { Coordinates } from "../../../../../../../domain/position/Coordinates";
import { LatDMS } from "../../../../../../../domain/position/Latitude";
import { LongDMS } from "../../../../../../../domain/position/Longitude";
import ErrorWrapper from "../../../../ErrorWrapper";
import FetchErrors from "../../../../../../../shared/errors/FetchErrors";
import FillWithAisButton from "../shared/FillWithAisButton";
import DMSLatitudeFormItem from "./DMSLatitudeFormItem";
import DMSLongitudeFormItem from "./DMSLongitudeFormItem";
import { usePositionFormItem } from "../shared/usePositionFormItem";

const StyledDMSFormItem = styled.div`
  display: flex;
  flex-direction: column;
  
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
  
  & > div.aisButton {
    align-self: flex-start;
    margin: 0px 30px 20px 180px;
  }
`;

type Props =
  FormItemProps<Coordinates>
  & {
    getAisPosition: (() => Promise<Result<Coordinates, FetchErrors>>) | undefined
  };

const DMSFormItem = (props: Props): React.ReactElement => {
  const hook = usePositionFormItem<LatDMS, LongDMS>(props);

  return (
    <ErrorWrapper isError={hook.isError} errors={hook.error}>
      <StyledDMSFormItem>
        <FillWithAisButton
          onClick={hook.fillWithAis}
          enabled={hook.canFillWithAis}
          loading={hook.aisLoading}
        />
        <DMSLatitudeFormItem
          {...hook.lat}
          required={props.required}
        />
        <DMSLongitudeFormItem
          {...hook.long}
          required={props.required}
        />
      </StyledDMSFormItem>
    </ErrorWrapper>
  );
};

export default DMSFormItem;