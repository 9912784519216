import React from "react";
import {
  DatePicker,
} from "antd";

import { Moment } from "moment";
import ErrorWrapper from "../../../ErrorWrapper";

import DateString from "./DateString";
import { FormItemProps } from "../../../FormItem";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import {
  useSingleFormItem,
  UseSingleFormItemHook,
} from "../../../useSingleFormItem";
import { toMomentInput } from "../../../../utils/toMomentInput";

type Props = FormItemProps<DateString> & {};

type Hook = UseSingleFormItemHook<string, DateString> & {
  momentInput: Moment | undefined,
};

const outputToInput = (ds: DateString): string => ds.value;

export const useDateInput = (props: Props): Hook => {
  const inputToResult = notEmptyInputToResultWrapper("Date", DateString.fromString);

  const hook = useSingleFormItem<string, DateString>({
    ...props,
    inputsEqual: eqeqeq,
    outputToInput,
    inputToResult,
  });

  const momentInput = toMomentInput(hook.input, "YYYY-MM-DD");

  return {
    ...hook,
    momentInput,
  };
};

/* ------------------------------------------------------------ */

const DateInput: React.FC<Props> = (props) => {
  const hook = useDateInput(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <DatePicker
        placeholder="YYYY-MM-DD"
        className={hook.isError ? "error" : undefined}
        onChange={(date, dateString): void => {
          if (date == null) {
            hook.onInputChange(undefined);
          } else {
            hook.onInputChange(dateString);
          }
        }}
        format="YYYY-MM-DD"
        value={hook.momentInput}
      />
    </ErrorWrapper>
  );
};

export default DateInput;