import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<Quantity>()
export class Quantity extends NumberUnit {
  static serialize(unit: Quantity): Result<Serialized, SerializationError> {
    return super._serialize(unit, Quantity);
  }

  static deserialize(serialized: Serialized): Result<Quantity, SerializationError> {
    return super._deserialize(serialized, Quantity);
  }
}