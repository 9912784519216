import { DateTime } from "luxon";
import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import GenericDateTimeFormItemProvider from "../../_generic/date/GenericDateTimeFormItemProvider";
import {
  DATE_TIME_OFFSET_FORMAT,
} from "../../../../../../shared/utils/date";

const fieldName = "CP Date";

const outputToSuggestionText = (output: DateTime): string => output.toFormat(DATE_TIME_OFFSET_FORMAT);

const CPDateFormItemProvider: FormItemProvider<DateTime> = (props: FormItemProviderProps<DateTime>) => (
  <GenericDateTimeFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="CPDate"
    suggestPrevious={true}
    outputToSuggestionText={outputToSuggestionText}    
  />
);

export default React.memo(CPDateFormItemProvider);