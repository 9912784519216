import React from "react";
import {
  Select,
} from "antd";

import { FixedOffsetZone } from "luxon";
import {
  err,
  ok,
  Result,
} from "neverthrow";
import ErrorWrapper from "../../../ErrorWrapper";

import { FormItemProps } from "../../../FormItem";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import {
  useSingleFormItem,
  UseSingleFormItemHook,
} from "../../../useSingleFormItem";
import ValidationError from "../../../../../../domain/errors/ValidationError";
import { UTC_OFFSETS } from "./utcOffsets";

type Props = FormItemProps<FixedOffsetZone> & {};

type Hook = UseSingleFormItemHook<string, FixedOffsetZone> & {};

const outputToInput = (zone: FixedOffsetZone): string => zone.name;

const stringToFixedOffsetZone = (input: string): Result<FixedOffsetZone, ValidationError[]> => {
  const zone = FixedOffsetZone.parseSpecifier(input);
  if (zone === null || !zone.isValid) {
    return err([new ValidationError("UTC Offset is invalid. Should be in a format like \"UTC+6\" or \"UTC-9:30\".", { userFacing: true })]);
  }
  return ok(zone);
};

export const useUtcOffsetInput = (props: Props): Hook => {
  const inputToResult = notEmptyInputToResultWrapper("UTC Offset", stringToFixedOffsetZone);

  const hook = useSingleFormItem<string, FixedOffsetZone>({
    ...props,
    inputsEqual: eqeqeq,
    outputToInput,
    inputToResult,
  });

  return {
    ...hook,
  };
};

/* ------------------------------------------------------------ */

const UtcOffsetInput: React.FC<Props> = (props) => {
  const hook = useUtcOffsetInput(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <Select
        value={hook.input}
        onChange={hook.onInputChange}
        className={hook.isError ? "error" : undefined}
        placeholder="UTC Offset"
        options={UTC_OFFSETS.map((offset) => ({ value: offset.name, label: offset.name }))}
        showSearch={true}
        allowClear={true}
        filterOption={(input, option): boolean => option!.value.toLowerCase().includes(input.toLowerCase())}
      />
    </ErrorWrapper>
  );
};

export default UtcOffsetInput;