import React from "react";
import { DateTime } from "luxon";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import GenericDateTimeFormItemProvider from "../../_generic/date/GenericDateTimeFormItemProvider";
import { DATE_TIME_OFFSET_FORMAT } from "../../../../../../shared/utils/date";

const fieldName = "End of bunkering";

const EndOfBunkeringTimeFormItemProvider: FormItemProvider<DateTime> = (props: FormItemProviderProps<DateTime>) => (
  <GenericDateTimeFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="EndOfBunkeringTime"
    suggestPrevious={false}
    outputToSuggestionText={(o): string => o.toFormat(DATE_TIME_OFFSET_FORMAT)}
  />
);

export default React.memo(EndOfBunkeringTimeFormItemProvider);