import {
  err,
  ok,
  Result,
} from "neverthrow";
import { DisplayedField } from "../../../../domain/reports/DisplayedField";
import ReportData from "../../../../domain/reports/ReportData";
import FieldTypes, { FieldType } from "../../../../domain/reports/FieldTypes";
import ValidationError from "../../../../domain/errors/ValidationError";
import logger from "../../../../shared/utils/logger";
import { DirtyInfo } from "../FormItem";
import RequiredError from "../../../../domain/errors/RequiredError";

export type FieldState<T> = {
  result: Result<T, ValidationError[]> | null | undefined,
  dirty: DirtyInfo,
  required: boolean,
};

export type FieldStates = {
  [T in keyof FieldTypes]: FieldState<FieldTypes[T]>
};

export const displayedFieldsToFieldStates = (
  displayedFields: DisplayedField[],
  reportData?: ReportData,
): FieldStates => {
  return displayedFields.reduce((acc, field) => {
    const existingData = reportData?.[field.fieldType];
    const fieldState: FieldState<any> = {
      result: existingData !== undefined ? ok(existingData) : undefined,
      required: field.required,
      dirty: { value: existingData !== undefined },
    };
    return ({
      ...acc,
      [field.fieldType]: fieldState,
    });
  }, {} as FieldStates);
};

type FieldErrorMap = { [T in keyof FieldType]?: Error };

export const fieldStatesToReportData = (inputs: FieldStates, isDraft: boolean): Result<ReportData, FieldErrorMap> => {
  let reportData: ReportData = {};
  let fieldErrorMap: FieldErrorMap = {};

  Object.entries(inputs)
    .forEach(([fieldTypeStr, fieldInputsItem]) => {
      const fieldType = fieldTypeStr as FieldType;
      // Error if field is empty and required
      if (
        fieldInputsItem.result === null ||
        fieldInputsItem.result === undefined
      ) {
        if (fieldInputsItem.required) {
          fieldErrorMap[fieldType] = new RequiredError("Field is required.", { userFacing: true });
        }
        return;
      }

      // Error if field is errored
      if (fieldInputsItem.result.isErr()) {
        fieldErrorMap[fieldType] = fieldInputsItem.result.error;
        return;
      }

      // Add non-errored field to report data
      reportData = {
        ...reportData,
        [fieldType]: fieldInputsItem.result.value,
      };
    });
  
  if (isDraft) {
    fieldErrorMap = Object.entries(fieldErrorMap).reduce((acc, [fieldType, error]) => {
      if (error instanceof RequiredError) {
        return acc;
      }
      return {
        ...acc,
        [fieldType]: error,
      };
    }, {});
  }

  if (Object.keys(fieldErrorMap).length > 0) {
    return err(fieldErrorMap);
  }

  return ok(reportData);
};

export const logFieldStates = (fieldStates: FieldStates): void => {
  const devFriendlyFieldStates = Object.entries(fieldStates)
    .reduce((acc, [ft, item]) => ({
      ...acc,
      [ft]: item,
    }), {} as FieldStates);
  logger.debug("Field States:", devFriendlyFieldStates);
};