import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<NauticalMiles>()
export class NauticalMiles extends NumberUnit {
  static serialize(unit: NauticalMiles): Result<Serialized, SerializationError> {
    return super._serialize(unit, NauticalMiles);
  }

  static deserialize(serialized: Serialized): Result<NauticalMiles, SerializationError> {
    return super._deserialize(serialized, NauticalMiles);
  }
}