import {
  err,
  ok,
  Result,
} from "neverthrow";
import { DateTime } from "luxon";
import ValidationError from "../../../../../../domain/errors/ValidationError";
import { TIME_FORMAT } from "../../../../../../shared/utils/date";

const validPattern = /^[0-9][0-9]:[0-9][0-9]$/;

class TimeString {
  private constructor(public value: string) {}

  public static fromDateTime(input: DateTime): TimeString {
    return TimeString.fromString(input.toFormat(TIME_FORMAT))._unsafeUnwrap();
  }

  public static fromString(string: string): Result<TimeString, ValidationError[]> {
    if (!validPattern.test(string)) {
      return err([new ValidationError("Time must have format HH:MM.\nExample: 14:07", { userFacing: true })]);
    }

    const [hours, minutes] = string.split(":").map(Number);

    const nanErrors: ValidationError[] = [];
    if (Number.isNaN(hours)) {
      nanErrors.push(new ValidationError("Invalid hours, not a number.", { userFacing: true }));
    }
    if (Number.isNaN(minutes)) {
      nanErrors.push(new ValidationError("Invalid minutes, not a number.", { userFacing: true }));
    }
    if (nanErrors.length > 0) {
      return err(nanErrors);
    }

    const rangeErrors: ValidationError[] = [];
    if (hours < 0 || hours > 23) {
      rangeErrors.push(new ValidationError("Hours must be between 00 and 23.", { userFacing: true }));
    }
    if (minutes < 0 || minutes > 59) {
      rangeErrors.push(new ValidationError(`Minutes must be between 00 and 59.`, { userFacing: true }));
    }
    if (rangeErrors.length > 0) {
      return err(rangeErrors);
    }

    return ok(new TimeString(string));
  }

  public equals(that: TimeString): boolean {
    return this.value === that.value;
  }
}

export default TimeString;