import {
  useState,
} from "react";
import {
  ok,
  Result,
} from "neverthrow";
import {
  ChildInputTypes,
  Outputs,
  useCompositeFormItem,
  UseCompositeFormItemHook,
} from "../../../../useCompositeFormItem";
import { Coordinates } from "../../../../../../../domain/position/Coordinates";
import ValidationError from "../../../../../../../domain/errors/ValidationError";
import FetchErrors from "../../../../../../../shared/errors/FetchErrors";
import { FormItemProps } from "../../../../FormItem";
import Latitude from "../../../../../../../domain/position/Latitude";
import Longitude from "../../../../../../../domain/position/Longitude";

export type UsePositionFormItemProps =
  {
    getAisPosition: (() => Promise<Result<Coordinates, FetchErrors>>) | undefined,
  }
  & FormItemProps<Coordinates>;

export type UsePositionFormItemHook<CIT extends ChildInputTypes> =
  {
    fillWithAis: () => void,
    canFillWithAis: boolean,
    aisLoading: boolean,
  }
  & UseCompositeFormItemHook<CIT, Coordinates>;

export type CIT<LatIn, LongIn> = {
  lat: {
    input: LatIn,
    output: Latitude,
  },
  long: {
    input: LongIn,
    output: Longitude
  }
};

const childOutputsEqual = <LatIn, LongIn>(
  outputs1: Outputs<CIT<LatIn, LongIn>>,
  outputs2: Outputs<CIT<LatIn, LongIn>>,
): boolean => (
  outputs1.lat.equals(outputs2.lat) && outputs1.long.equals(outputs2.long)
);

const childOutputsToResult = <LatIn, LongIn>(
  outputs: Outputs<CIT<LatIn, LongIn>>,
): Result<Coordinates, ValidationError[]> => (
  ok(new Coordinates(outputs.lat, outputs.long))
);

const outputToChildOutputs = <LatIn, LongIn>(
  output: Coordinates,
): Outputs<CIT<LatIn, LongIn>> => ({
  lat: output.lat,
  long: output.long,
});

export const usePositionFormItem = <LatIn, LongIn>(
  props: UsePositionFormItemProps,
): UsePositionFormItemHook<CIT<LatIn, LongIn>> => {
  const hook = useCompositeFormItem<CIT<LatIn, LongIn>, Coordinates>({
    ...props,
    dataKeys: ["lat", "long"],
    childOutputsEqual,
    childOutputsToResult,
    outputToChildOutputs,
  });

  const [aisLoading, setAisLoading] = useState(false);

  const fillWithAis = async (): Promise<void> => {
    if (props.getAisPosition === undefined) {
      return;
    }
    setAisLoading(true);
    (await props.getAisPosition()).match(
      (coordinates: Coordinates) => {
        props.onResultChange(ok(coordinates));
      },
      (_error: Error) => {},
    );
    setAisLoading(false);
  };

  return {
    ...hook,
    fillWithAis,
    canFillWithAis: props.getAisPosition !== undefined,
    aisLoading,
  };
};