import { Id } from "../../domain/Id";
import ReportType from "../../domain/reports/ReportType";
import { reportTypeToUrl } from "./utils/reportTypeToUrl";

const Routes = {
  Login: {
    template: "/login",
    generate: (): string => "/login",
    exact: true,
  },
  Home: {
    template: "/",
    generate: (): string => "/",
    exact: true,
  },
  SelectReport: {
    template: "/new",
    generate: (): string => "/new",
    exact: true,
  },
  NewReport: {
    template: "/new/:reportType",
    generate: (reportType: ReportType): string => `/new/${reportTypeToUrl(reportType)}`,
    exact: true,
  },
  ReportsList: {
    template: "/reports",
    generate: (): string => "/reports",
    exact: true,
  },
  Report: {
    template: "/reports/:id",
    generate: (id: Id): string => `/reports/${id}`,
    exact: true,
  },
  EditReport: {
    template: "/reports/:id/edit",
    generate: (id: Id): string => `/reports/${id}/edit`,
    exact: true,
  },
  Support: {
    template: "/support",
    generate: (): string => `/support`,
    exact: true,
  },
};

export default Routes;
