import React, {
  ReactNode,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import {
  notification,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { faAt } from "@fortawesome/pro-regular-svg-icons/faAt";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faUndo } from "@fortawesome/pro-regular-svg-icons/faUndo";
import { Result } from "neverthrow";
import { red } from "@ant-design/colors";
import copy from "copy-to-clipboard";
import _ from "lodash";
import Report from "../../../../domain/reports/Report";
import IconButton from "../../misc-components/IconButton";
import ValidationError from "../../../../domain/errors/ValidationError";
import DataStatus from "../../misc-components/DataStatus";
import TextReportOutput from "../../../../domain/reports/generators/TextReportOutput";
import ReportFormatToSend from "../../../../domain/reports/ReportFormatToSend";
import ReportStatus from "../../../../domain/reports/ReportStatus";
import TooltipIcon from "../../misc-components/TooltipIcon";
import {
  DATE_FORMAT,
  getCurrentTimestamp,
} from "../../../../shared/utils/date";
import { saveReport } from "../../localReportStorage";
import { showErrorNotification } from "../../utils/notifications";
import CloudSyncStatus from "../../../../domain/CloudSyncStatus";

type Row = {
  generateResult: Result<TextReportOutput, ValidationError>,
  mailtoHref: string | undefined,
  fileHref: string | undefined,
  fileName: string | undefined,
  name: string,
  recipients: string[],
  sent: boolean,
  format: ReportFormatToSend,
};

type MarkSentLoadingMap = {
  [key: string]: {
    loading: boolean,
    sent: boolean,
  }
};

type Props = {
  report: Report,
  setReport: (report: Report) => void,
};

type Hook = {
  copyReport: (row: Row) => void,
  changeSent: (sent: boolean, row: Row) => void
  rows: Row[],
  loading: boolean,
  markSentLoadingMap: MarkSentLoadingMap,
};

const reportToRows = (report: Report): Row[] => {
  return report.reportFormatsToSend
    .filter((format) => format.reportFormatConfig.sendable)
    .map((format) => {
      const { name } = format.reportFormatConfig.generator;
      const { recipients } = format.reportFormatConfig;

      const result = format.reportFormatConfig.generator.generate(
        report.data,
        format.reportFormatConfig.version,
      );

      let fileHref: string | undefined;
      let fileName: string | undefined;
      let mailtoHref: string | undefined;

      if (result.isOk()) {
        fileHref = URL.createObjectURL(new Blob(
          [result.value.data],
          { type: "text/plain;charset=utf-8" },
        ));

        // TODO: Proper file name generation
        fileName =
        `${report.data.ReportDate?.toFormat(DATE_FORMAT) ?? "no-date"}` +
        `_${report.type.replace(/ /g, "-").toLowerCase()}` +
        `_${name.replace(/ /g, "-")}` +
        `.txt`;

        // TODO: Proper subject generation
        mailtoHref =
        `mailto:${recipients.join(",")}` +
        `?subject=${encodeURIComponent(`${report.type} - ${name}`)}` +
        `&body=${encodeURIComponent(result.value.data)}`;
      }

      return {
        generateResult: result,
        fileHref,
        fileName,
        mailtoHref,
        name,
        recipients,
        sent: format.sent,
        format,
      };
    });
};

export const useSendReport = (props: Props): Hook => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<Row[]>([]);
  const [markSentLoadingMap, setMarkSentLoadingMap] = useState<MarkSentLoadingMap>({});

  useEffect(() => {
    const newRows = reportToRows(props.report);
    setRows(newRows);
    setMarkSentLoadingMap((prevMarkSentLoadingMap) => {
      return newRows.reduce((acc, row) => {
        return {
          ...acc,
          [row.format.id]: prevMarkSentLoadingMap[row.format.id] ?? false,
        };
      }, {} as MarkSentLoadingMap);
    });
    setLoading(false);
  }, [props.report]);

  const copyReport = (row: Row): void => {
    if (row.generateResult.isOk()) {
      const success = copy(row.generateResult.value.data);
      if (success) {
        notification.success({
          message: "Data copied",
          description: "The report data was copied to your clipboard.",
        });
        return;
      }
    }
    showErrorNotification(
      "Copy failed",
      "Could not copy report data to clipboard.",
    );
  };

  const setSentLoadingByRow = (newLoading: boolean | undefined, newSent: boolean | undefined, row: Row): void => {
    const key = row.format.id;
    setMarkSentLoadingMap((prevMarkSentLoadingMap) => ({
      ...prevMarkSentLoadingMap,
      [key]: {
        ...prevMarkSentLoadingMap[key],
        loading: newLoading ?? prevMarkSentLoadingMap[key].loading,
        sent: newSent ?? prevMarkSentLoadingMap[key].sent,
      },
    }));
  };

  const changeSent = async (sent: boolean, row: Row): Promise<void> => {
    setSentLoadingByRow(true, sent, row);
    const newReport: Report = _.cloneDeep(props.report);
    const newFormat = _.cloneDeep(row.format);
    newFormat.sent = sent;
    newReport.cloudStatus = CloudSyncStatus.AwaitingConnection;
    newReport.reportFormatsToSend = newReport.reportFormatsToSend.map((format) => {
      if (format.id === newFormat.id) {
        return newFormat;
      }

      const loadingSent: boolean | undefined = markSentLoadingMap[format.id]?.sent;
      if (loadingSent !== undefined && format.sent !== loadingSent) {
        const newM = _.cloneDeep(format);
        newM.sent = loadingSent;
        return newM;
      }

      return format;
    });
    const allSent = newReport.reportFormatsToSend.every((m) => m.sent || !m.reportFormatConfig.sendable);
    newReport.status = allSent ? ReportStatus.Sent : ReportStatus.Unsent;
    newReport.updatedAtTimestamp = getCurrentTimestamp();
    (await saveReport(newReport)).match(
      () => {
        props.setReport(newReport);
        setSentLoadingByRow(false, undefined, row);
      },
      (error: Error) => {
        setSentLoadingByRow(false, undefined, row);
        showErrorNotification("Failed to mark report as sent.", error);
      },
    );
  };

  return {
    copyReport,
    changeSent,
    rows,
    loading,
    markSentLoadingMap,
  };
};

/* ------------------------------------------------------------ */

const StyledReportOutputData = styled.pre`
  max-height: 300px;
  overflow: auto;
  margin-bottom: 0;
  white-space: pre-wrap;
  font-family: monospace;
`;

const ReportOutputData: React.FC<{
  loading?: boolean,
  data: string | undefined,
}> = (props) => {
  if (props.loading) {
    return (
      <Spin
        tip="Generating report preview..."
      />
    );
  }

  return (
    <StyledReportOutputData>
      {props.data}
    </StyledReportOutputData>
  );
};

const StyledSendReport = styled.div`
  overflow: auto;
  
  & > * {
    margin-bottom: 20px;
  }
  
  & tr.sent {
    //opacity: 0.5;
  }
`;

const ButtonsWrapper = styled.div`
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
const SendReport: React.FC<Props> = (props) => {
  const hook = useSendReport(props);

  const columns: ColumnsType<Row> = [
    {
      title: "Format",
      dataIndex: "name",
    },
    {
      title: "Recipients",
      dataIndex: "recipients",
      render: (recipients: string[]): ReactNode => (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {recipients.join(",\n")}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (__, row): ReactNode => {
        if (row.generateResult.isErr()) {
          const { error } = row.generateResult;
          const gotErrorMessage = error.message !== undefined && error.userFacing;
          const errorMessage = gotErrorMessage ? error.message : "Could not generate report.";

          return (
            <span style={{
              color: red.primary,
            }}>
              {errorMessage}
            </span>
          );
        }

        return (
          <ButtonsWrapper>
            <Tooltip title="Open in e-mail client">
              <IconButton
                href={row.mailtoHref}
                icon={faAt}
                type="link"
                target="_blank"
              />
            </Tooltip>
            <Tooltip title="Copy to clipboard">
              <IconButton
                icon={faCopy}
                onClick={(): void => hook.copyReport(row)}
                type="link"
              />
            </Tooltip>
            <Tooltip title="Download as file">
              <IconButton
                icon={faDownload}
                href={row.fileHref}
                download={row.fileName}
                type="link"
              />
            </Tooltip>
          </ButtonsWrapper>
        );
      },
    },
    {
      title: (
        <>
          <span style={{ marginRight: "5px" }}>Sent</span>
          <TooltipIcon tooltip="Mark a report format as sent when you have sent it as an e-mail to the recipients." />
        </>
      ),
      dataIndex: "sent",
      width: "200px",
      render: (sent, row): ReactNode => {
        const { loading } = hook.markSentLoadingMap[row.format.id];

        return (
          <IconButton
            icon={sent ? faUndo : faCheck}
            danger={sent}
            type={sent ? undefined : "primary"}
            style={{
              width: "100%",
              transition: "none",
            }}
            onClick={(): void => {
              if (loading) {
                return;
              }
              hook.changeSent(!sent, row);
            }}
          >
            {sent ? "Mark as not sent" : "Mark as sent"}
          </IconButton>
        );
      },
    },
  ];

  return (
    <StyledSendReport>
      <h2>Report formats to send</h2>
      <Table
        columns={columns}
        dataSource={hook.rows}
        rowKey={(row): string => row.format.id}
        rowClassName={(row: Row): string => (row.sent ? "sent" : "")}
        pagination={false}
        expandable={{
          expandedRowRender: (row: Row): ReactNode => {
            if (row.generateResult.isErr()) {
              const { error } = row.generateResult;
              const gotErrorMessage = error.message !== undefined && error.userFacing;
              const errorMessage = gotErrorMessage ? error.message : "Could not generate report.";

              return (
                <DataStatus
                  isError={true}
                  errorText={errorMessage}
                />
              );
            }

            return (
              <ReportOutputData
                data={row.generateResult.value.data}
              />
            );
          },
        }}
        locale={{
          emptyText: (
            <DataStatus
              emptyText="No reports to send."
              loadingText="Generating reports..."
              isLoading={hook.loading}
              isEmpty={hook.rows === undefined || hook.rows.length === 0}
            />
          ),
        }}
      />
    </StyledSendReport>
  );
};

export default SendReport;
