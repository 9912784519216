import BaseError, { BaseErrorProps } from "../../shared/errors/BaseError";

type Details = {
  key?: string,
  value?: any,
};

class ValidationError extends BaseError {
  public readonly details?: Details;
  public readonly children?: Error[];
  private __nominal!: void;

  constructor(
    message: string,
    props: BaseErrorProps & {
      details?: Details,
      children?: Error[],
    },
  ) {
    super(message, props);
    this.details = props?.details;
    this.children = props?.children;
  }
}

export default ValidationError;