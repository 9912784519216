import { ok } from "neverthrow";
import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { Tons } from "../../../../../../domain/units/Tons";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Cargo Weight";

const CargoWeightFormItemProvider: FormItemProvider<Tons> = (props: FormItemProviderProps<Tons>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="FreshWater"
    inputToResult={notEmptyInputToResultWrapper(fieldName, (input: number) => ok(new Tons(input)))}
    outputToInput={unwrapValue}
    inputsEqual={eqeqeq}
    addonAfter="tons"
    allowNegative={false}
    separateThousands={true}
    integersOnly={false}
  />
);

export default React.memo(CargoWeightFormItemProvider);