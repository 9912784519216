import { faSatelliteDish } from "@fortawesome/pro-regular-svg-icons/faSatelliteDish";
import React from "react";
import { grey } from "@ant-design/colors";
import IconButton from "../../../../../misc-components/IconButton";

type FillWithAisButtonProps = {
  onClick: () => void,
  enabled: boolean,
  loading: boolean,
};

const FillWithAisButton: React.FC<FillWithAisButtonProps> = (props) => {
  return (
    <div
      className="aisButton"
    >
      <IconButton
        onClick={props.onClick}
        loading={props.loading}
        icon={faSatelliteDish}
        disabled={!props.enabled}
      >
        Fill with AIS
      </IconButton>
      {!props.enabled && (
        <span style={{
          color: grey[0],
          marginLeft: "10px",
        }}>
          (IMO Number must be filled to get AIS position)
        </span>
      )}
    </div>
  );
};

export default FillWithAisButton;