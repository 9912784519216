import * as joi from "joiful";
import {
  combine,
  Result,
} from "neverthrow";
import { PrimaryColumn } from "typeorm";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import ReportFormatConfig from "./ReportFormatConfig";
import { Id } from "../Id";

@serializableDecorator<ReportFormatToSend>()
class ReportFormatToSend {
  @joi.string().required()
  @PrimaryColumn("text")
  id: Id;

  @joi.boolean().required()
  sent: boolean;

  @joi.object().required()
  reportFormatConfig: ReportFormatConfig;

  constructor(
    id: Id,
    sent: boolean,
    reportFormatConfig: ReportFormatConfig,
  ) {
    this.id = id;
    this.sent = sent;
    this.reportFormatConfig = reportFormatConfig;
  }

  static serialize(details: ReportFormatToSend): Result<Serialized, SerializationError> {
    const results: Result<Serialized, SerializationError>[] = [
      ReportFormatConfig.serialize(details.reportFormatConfig),
    ];
    return combine(results)
      .map(([reportFormatConfig]) => ({
        id: details.id,
        sent: details.sent,
        reportFormatConfig,
        __className: ReportFormatToSend.name,
      }));
  }

  static deserialize(serialized: Serialized): Result<ReportFormatToSend, SerializationError> {
    const results: [
      Result<ReportFormatConfig, SerializationError>,
    ] = [
      ReportFormatConfig.deserialize(serialized.reportFormatConfig),
    ];
    return combine(results)
      .map(([reportFormatConfig]) => new ReportFormatToSend(
        serialized.id,
        serialized.sent,
        reportFormatConfig,
      ));
  }
}

export default ReportFormatToSend;