import React, {
  useCallback,
  useState,
} from "react";
import styled from "styled-components";
import { Button } from "antd";
import {
  Redirect,
  useParams,
} from "react-router-dom";
import ReportForm from "../../report-form/report-form/ReportForm";
import { FORM_ITEM_PROVIDERS } from "../../report-form/formItemProviders";
import {
  DEV_DATA,
} from "../../../../shared/mock/reportData";
import ReportData from "../../../../domain/reports/ReportData";
import ImoNumber from "../../../../domain/ImoNumber";
import { getLocalAuthPayload } from "../../auth";
import { USER_SETTINGS } from "../../../../shared/mock/userSettings";
import Report from "../../../../domain/reports/Report";
import useFetch from "../../hooks/useFetch";
import { getPreviousReport } from "../../localReportStorage";
import { urlToReportType } from "../../utils/reportTypeToUrl";
import Routes from "../../routes";
import ReportType from "../../../../domain/reports/ReportType";
import { FORM_STRUCTURE } from "../../../../domain/reports/FieldGroup";
import ReportFormatConfig from "../../../../domain/reports/ReportFormatConfig";
import reportTypeToReportFormatConfigs from "../../../../domain/reports/reportTypeToReportFormatConfigs";

type Props = {
};

type Hook = {
  data: ReportData | undefined,
  fillFormDevData: (() => void) | undefined,
  previousReport: Report | undefined,
  reportType: ReportType | undefined,
  reportFormatConfigs: ReportFormatConfig[] | undefined,
};

export const useReportFormPage = (_props: Props): Hook => {
  const { reportType: reportTypeUrl } = useParams<{ reportType: string }>();
  const reportType = urlToReportType(reportTypeUrl);

  const imoResult = ImoNumber.fromString(getLocalAuthPayload()!.imoNumber);
  const [data, setData] = useState<ReportData>({
    IMONumber: imoResult.isOk() ? imoResult.value : undefined,
  });

  const fillFormDevData = (process.env.NODE_ENV === "development")
    ? (): void => setData((prevData) => ({ ...prevData, ...DEV_DATA }))
    : undefined;

  const {
    data: previousReport,
    loading: _previousReportLoading,
    error: _previousReportError,
  } = useFetch(useCallback(() => getPreviousReport(), []));

  const reportFormatConfigs = reportType === undefined
    ? undefined
    : reportTypeToReportFormatConfigs(reportType);

  return {
    fillFormDevData,
    data,
    previousReport,
    reportType,
    reportFormatConfigs,
  };
};

/* ------------------------------------------------------------ */

const StyledReportFormPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
  }
`;

const NewReportPage: React.FC<Props> = (props) => {
  const hook = useReportFormPage(props);

  if (hook.reportType === undefined || hook.reportFormatConfigs === undefined) {
    return (
      <Redirect to={Routes.SelectReport.generate()} />
    );
  }

  return (
    <StyledReportFormPage>
      <div>
        <h1>New Report ({hook.reportType})</h1>
        {
          hook.fillFormDevData !== undefined && (
            <div style={{
              justifyContent: "flex-end",
              marginBottom: "10px",
              display: "flex",
            }}>
              <Button onClick={hook.fillFormDevData}>
                Fill test data
              </Button>
            </div>
          )
        }
        <ReportForm
          formItemProviders={FORM_ITEM_PROVIDERS}
          formStructure={FORM_STRUCTURE}
          reportFormatConfigs={hook.reportFormatConfigs}
          userSettings={USER_SETTINGS}
          previousReport={hook.previousReport}
          data={hook.data}
          blockNavigation={true}
          reportType={hook.reportType}
        />
      </div>
    </StyledReportFormPage>
  );
};

export default NewReportPage;
