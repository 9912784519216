import BaseError, { BaseErrorProps } from "./BaseError";

class NetworkError extends BaseError {
  constructor(props?: BaseErrorProps) {
    super("Couldn't connect. Please try again once the connection has been restored.", {
      userFacing: true,
      ...props,
    });
  }
}

export default NetworkError;