export type BaseErrorProps = {
  innerError?: Error,
  userFacing?: boolean,
};

abstract class BaseError extends Error {
  public readonly innerError?: Error;
  public readonly userFacing?: boolean;

  public constructor(
    public readonly message: string,
    props?: BaseErrorProps,
  ) {
    super();
    this.message = message;
    this.innerError = props?.innerError;
    this.userFacing = props?.userFacing;
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
    if (this.innerError != null && this.innerError.stack != null) {
      // this.stack += `\nCaused by: ${this.innerError.stack}`;
    }
  }
}

export default BaseError;
