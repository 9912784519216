import React from "react";
import { TimePicker } from "antd";
import moment, { Moment } from "moment";
import ErrorWrapper from "../../../ErrorWrapper";
import { FormItemProps } from "../../../FormItem";
import TimeString from "./TimeString";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import {
  useSingleFormItem,
  UseSingleFormItemHook,
} from "../../../useSingleFormItem";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { toMomentInput } from "../../../../utils/toMomentInput";

type Props = FormItemProps<TimeString>;

type Hook = UseSingleFormItemHook<string, TimeString> & {
  momentInput: Moment | undefined,
};

const outputToInput = (ts: TimeString): string => ts.value;

export const useTimeInput = (props: Props): Hook => {
  const inputToResult = notEmptyInputToResultWrapper("Time", TimeString.fromString);

  const hook = useSingleFormItem<string, TimeString>({
    ...props,
    inputsEqual: eqeqeq,
    outputToInput,
    inputToResult,
  });
  
  const momentInput = toMomentInput(hook.input, "HH:mm");

  return {
    ...hook,
    momentInput,
  };
};

/* ------------------------------------------------------------ */

const TimeInput: React.FC<Props> = (props) => {
  const hook = useTimeInput(props);
  
  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <TimePicker
        placeholder="HH:MM"
        className={hook.isError ? "error" : undefined}
        onChange={(time, timeString): void => {
          if (time == null) {
            hook.onInputChange(undefined);
          } else {
            hook.onInputChange(timeString);
          }
        }}
        format="HH:mm"
        value={hook.momentInput}
        defaultOpenValue={moment("12:00", "HH:mm")}
      />
    </ErrorWrapper>
  );
};

export default TimeInput;