import {
  DateTime,
  FixedOffsetZone,
} from "luxon";
import {
  err,
  ok,
  Result,
} from "neverthrow";
import React, {
  useCallback,
  useEffect,
  useMemo,
} from "react";
import styled from "styled-components";
import { Col, Grid, Row } from "antd";
import {
  FormItemProviderProps,
} from "../../../FormItemProvider";
import {
  Outputs,
  useCompositeFormItem,
  UseCompositeFormItemProps,
} from "../../../useCompositeFormItem";
import ValidationError from "../../../../../../domain/errors/ValidationError";
import DateString from "./DateString";
import TimeString from "./TimeString";
import Suggestion from "../../../Suggestion";
import {
  FormItemComponent,
  FormItemProps,
} from "../../../FormItem";
import SuggestionsWrapper from "../../../SuggestionsWrapper";
import StandardInputContainer from "../../../StandardInputContainer";
import { FieldType } from "../../../../../../domain/reports/FieldTypes";
import logger from "../../../../../../shared/utils/logger";
import TimeInput from "./TimeInput";
import DateInput from "./DateInput";
import UtcOffsetInput from "./UtcOffsetInput";
import { DATE_TIME_FORMAT } from "../../../../../../shared/utils/date";

type DateTimeChildInputTypes = {
  date: {
    input: string,
    output: DateString,
  },
  time: {
    input: string,
    output: TimeString,
  }
  offset: {
    input: string,
    output: FixedOffsetZone,
  }
};

const getChildOutputsToResult = (fieldName: string): (outputs: Outputs<DateTimeChildInputTypes>) => Result<DateTime, ValidationError[]> => {
  return useCallback((outputs: Outputs<DateTimeChildInputTypes>): Result<DateTime, ValidationError[]> => {
    const isoStr = `${outputs.date.value}T${outputs.time.value}`;
    const dateTime = DateTime.fromISO(isoStr, { zone: outputs.offset });

    if (!dateTime.isValid) {
      logger.warn(`Failed to convert ISO datetime string "${isoStr}" to DateTime.`);
      return err([new ValidationError(`${fieldName} is invalid.`, { userFacing: true })]);
    }

    return ok(dateTime);
  }, [fieldName]);
};

const outputToChildOutputs = (output: DateTime): Outputs<DateTimeChildInputTypes> => {
  return {
    date: DateString.fromDateTime(output),
    time: TimeString.fromDateTime(output),
    offset: FixedOffsetZone.instance(output.offset),
  };
};

const childOutputsEqual = (o1: Outputs<DateTimeChildInputTypes>, o2: Outputs<DateTimeChildInputTypes>): boolean => {
  return (
    o1.date.equals(o2.date) &&
    o1.time.equals(o2.time) &&
    o1.offset.equals(o2.offset)
  );
};

type GenericDateTimeOptions = {
};

type ignoredCompositeKeys =
  | "childOutputsToResult"
  | "outputToChildOutputs"
  | "childOutputsEqual"
  | "dataKeys";

type GenericDateTimeFormItemProps =
  & FormItemProps<DateTime>
  & Omit<UseCompositeFormItemProps<DateTimeChildInputTypes, DateTime>, ignoredCompositeKeys>
  & {
    fieldName: string,
  }
  & GenericDateTimeOptions;

const StyledReportDateFormItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div {
    flex: 1 1 0px;
    display: flex;
    flex-direction: row;
    min-height: 32px;
  }
  
  & > div > * {
    flex: 1 1 0px;
  }
  
  & > div > button {
    max-width: 125px;
  }
  
  & > div > :not(:first-child) {
    margin-left: 10px;
  }
`;

const UtcTime = styled.span`
  color: hsl(0, 0%, 60%);
`;

function GenericDateTimeFormItem(props: GenericDateTimeFormItemProps): FormItemComponent<DateTime> {
  const hook = useCompositeFormItem<DateTimeChildInputTypes, DateTime>({
    ...props,
    dataKeys: ["date", "time", "offset"],
    childOutputsToResult: getChildOutputsToResult(props.fieldName),
    childOutputsEqual,
    outputToChildOutputs,
  });
  const screens = Grid.useBreakpoint();

  return (
    <StandardInputContainer
      required={props.required}
      isOk={hook.isOk}
      isError={hook.isError}
      errors={hook.error}
      fieldName={props.fieldName}
    >
      <SuggestionsWrapper<DateTime>
        pickSuggestion={hook.pickSuggestion}
        showSuggestions={hook.showSuggestions}
        suggestions={props.suggestions}
      >

        {screens.xs ? (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <DateInput
                result={hook.date.result}
                onResultChange={hook.date.onResultChange}
                dirty={hook.date.dirty}
                onDirtyChange={hook.date.onDirtyChange}
                required={props.required}
              />
            </Col>
            <Col span={24}>
              <TimeInput
                result={hook.time.result}
                onResultChange={hook.time.onResultChange}
                dirty={hook.time.dirty}
                onDirtyChange={hook.time.onDirtyChange}
                required={props.required}
              />
            </Col>
            <Col span={24}>
              <UtcOffsetInput
                result={hook.offset.result}
                onResultChange={hook.offset.onResultChange}
                dirty={hook.offset.dirty}
                onDirtyChange={hook.offset.onDirtyChange}
                required={props.required}
              />
            </Col>
            <Col span={24}>
              { props.result?.isOk() && (
                <UtcTime>{`(UTC: ${props.result.value.toUTC().toFormat(DATE_TIME_FORMAT)})`}</UtcTime>
              )}
            </Col>
          </Row>
        )
          : (
            <StyledReportDateFormItem>
              <div>
                <DateInput
                  result={hook.date.result}
                  onResultChange={hook.date.onResultChange}
                  dirty={hook.date.dirty}
                  onDirtyChange={hook.date.onDirtyChange}
                  required={props.required}
                />
                <TimeInput
                  result={hook.time.result}
                  onResultChange={hook.time.onResultChange}
                  dirty={hook.time.dirty}
                  onDirtyChange={hook.time.onDirtyChange}
                  required={props.required}
                />
                <UtcOffsetInput
                  result={hook.offset.result}
                  onResultChange={hook.offset.onResultChange}
                  dirty={hook.offset.dirty}
                  onDirtyChange={hook.offset.onDirtyChange}
                  required={props.required}
                />
              </div>
              { props.result?.isOk() && (
                <UtcTime>{`(UTC: ${props.result.value.toUTC().toFormat(DATE_TIME_FORMAT)})`}</UtcTime>
              )}
            </StyledReportDateFormItem>
          )}
      </SuggestionsWrapper>
    </StandardInputContainer>
  );
}

type GenericDateTimeFormItemProviderProps =
  & FormItemProviderProps<DateTime>
  & Omit<UseCompositeFormItemProps<DateTimeChildInputTypes, DateTime>, ignoredCompositeKeys>
  & GenericDateTimeOptions
  & {
    suggestPrevious?: boolean,
    autoFillPrevious?: boolean,
    fieldName: string,
    fieldType: FieldType,
  }
  & (
    {
      suggestPrevious: true,
      outputToSuggestionText: (output: DateTime) => string
    } |
    {
      suggestPrevious?: false,
      outputToSuggestionText?: (output: DateTime) => string
    }
  );

function GenericDateTimeFormItemProvider(props: GenericDateTimeFormItemProviderProps): React.ReactElement {
  const suggestions: Suggestion<DateTime>[] = useMemo(() => {
    if (!props.suggestPrevious) {
      return [];
    }
    const potentialPrevValue = props.previousReport?.data?.[props.fieldType];
    if (potentialPrevValue === undefined || potentialPrevValue === null) {
      return [];
    }
    const prevValue: DateTime = potentialPrevValue as unknown as DateTime;
    return [{
      text: props.outputToSuggestionText(prevValue),
      value: prevValue,
    }];
  }, []);

  useEffect(() => {
    if (!props.autoFillPrevious) {
      return;
    }
    const potentialPrevValue = props.previousReport?.data?.[props.fieldType];
    if (
      potentialPrevValue === undefined ||
      potentialPrevValue === null ||
      (props.result?.isOk() && props.result.value !== undefined)
    ) {
      return;
    }
    const prevValue: DateTime = potentialPrevValue as unknown as DateTime;
    props.onResultChange(ok(prevValue));
  }, []);

  return (
    <GenericDateTimeFormItem
      suggestions={suggestions}
      {...props}
    />
  );
}

export default GenericDateTimeFormItemProvider;