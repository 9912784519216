import React from "react";
import {
  FormItemProviderProps,
} from "../../../FormItemProvider";
import Port from "../../../../../../domain/Port";
import PortFormItemProvider from "../../ports/PortFormItemProvider";

const fieldName = "1st Port";
const fieldType = "ETAPort1";

const ETAPort1FormItemProvider = (props: FormItemProviderProps<Port>): React.ReactElement => (
  <PortFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType={fieldType}
  />
);

export default ETAPort1FormItemProvider;