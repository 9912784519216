import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { CargoQuantity } from "../../../../../../domain/units/CargoQuantity";
import GenericQuantityFormItemProvider
  from "../../_generic/quantity/GenericQuantityFormItemProvider";

const fieldName = "Quantity by shore scale";

const ShoreQuantityFormItemProvider: FormItemProvider<CargoQuantity> = (props: FormItemProviderProps<CargoQuantity>) => (
  <GenericQuantityFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="CargoShoresQuantity"
  />
);

export default React.memo(ShoreQuantityFormItemProvider);
