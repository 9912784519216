import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Result } from "neverthrow";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import {
  getDisplayedFields,
  getFieldGroupsToDisplay,
} from "../displayedFields";
import { newId } from "../../../../domain/Id";
import { FieldType } from "../../../../domain/reports/FieldTypes";
import Something from "../../../../shared/types/Something";
import ValidationError from "../../../../domain/errors/ValidationError";
import ReportData from "../../../../domain/reports/ReportData";
import Report from "../../../../domain/reports/Report";
import ReportType from "../../../../domain/reports/ReportType";
import ReportStatus from "../../../../domain/reports/ReportStatus";
import CloudSyncStatus from "../../../../domain/CloudSyncStatus";
import ReportFormatToSend from "../../../../domain/reports/ReportFormatToSend";
import logger from "../../../../shared/utils/logger";
import {
  WrappedFormItemProvider,
  WrappedFormItemProviders,
} from "../FormItemProvider";
import {
  displayedFieldsToFieldStates,
  FieldStates,
  fieldStatesToReportData,
  logFieldStates,
} from "./fieldStates";
import { FieldTypeGroup } from "../../../../domain/reports/FieldGroup";
import UserSettings from "../../../../domain/reports/UserSettings";
import ReportFormatConfig from "../../../../domain/reports/ReportFormatConfig";
import {
  FieldGroupWithStatus,
  useProgressSteps,
} from "./useProgressSteps";
import {
  getAllReports,
  saveReport,
} from "../../localReportStorage";
import { showErrorNotification } from "../../utils/notifications";
import Routes from "../../routes";
import { SIMPLE_MODE } from "../../constants";
import { DirtyInfo } from "../FormItem";
import { getLocalAuthPayload } from "../../auth";
import RequiredError from "../../../../domain/errors/RequiredError";
import { getCurrentTimestamp } from "../../../../shared/utils/date";
import validateReportWithRespectToOtherReports from "./validateReportWithRespectToOtherReports";

export type UseReportFormProps = {
  formStructure: FieldTypeGroup[],
  userSettings: UserSettings,
  formItemProviders: WrappedFormItemProviders,
  reportFormatConfigs: ReportFormatConfig[],
  disabled?: boolean,
  data?: ReportData,
  previousReport?: Report,
  existingReport?: Report,
  blockNavigation?: boolean,
  reportType: ReportType,
};

type UseReportFormHook = {
  fieldGroups: FieldGroupWithStatus[],
  finalizeReport: () => void,
  submitReportLoading: boolean,
  saveDraft: () => void,
  saveDraftLoading: boolean,
  clearForm: () => void,
  onProgressStepClick: (newCurrentStep: number) => void,
  currentProgressStep: number,
  headersListRef: React.MutableRefObject<Element[]>,
  fieldStates: FieldStates,
  getWrappedProvider: (fieldType: FieldType) => WrappedFormItemProvider<any>,
  getOnResultChange: (fieldType: FieldType) => (newResult: Result<Something, ValidationError[]> | undefined | null) => void,
  getOnDirtyChange: (fieldType: FieldType) => (newDirty: DirtyInfo) => void,
  shouldBlockNavigation: boolean,
  buttonsFloating: boolean,
  setButtonsFloating: (newValue: boolean) => void,
};

export const useReportForm = (props: UseReportFormProps): UseReportFormHook => {
  const externalData = props.data ?? props.existingReport?.data;

  const displayedFields = useMemo(
    () => getDisplayedFields(props.reportFormatConfigs),
    [props.reportFormatConfigs],
  );
  const displayedFieldGroups = useMemo(
    () => getFieldGroupsToDisplay(props.formStructure, displayedFields),
    [props.formStructure, displayedFields],
  );
  const initialFieldStates = displayedFieldsToFieldStates(displayedFields, externalData);

  const [fieldStates, setFieldStates] = useState<FieldStates>(initialFieldStates);
  const [submitReportLoading, setSubmitReportLoading] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [buttonsFloating, setButtonsFloating] = useState(true);
  const [newResultShouldBlockNavigation, setNewResultShouldBlockNavigation] = useState(false);

  const history = useHistory();

  const progressStepsHook = useProgressSteps({
    displayedFieldGroups,
    fieldStates,
  });

  useEffect(() => {
    setTimeout(() => setNewResultShouldBlockNavigation(true), 1000);
  }, []);

  useEffect(() => {
    window.onbeforeunload = shouldBlockNavigation ? ((): boolean => true) : null;
  }, [shouldBlockNavigation]);

  // Update field states based on the fields to display
  useEffect(() => {
    setFieldStates(displayedFieldsToFieldStates(displayedFields, externalData));
  }, [externalData]);

  const getOnResultChange = useCallback(
    (fieldType: FieldType) => {
      return (
        (newResult: Result<Something, ValidationError[]> | undefined | null): void => {
          setFieldStates((oldFieldStates: FieldStates) => {
            if (newResult === oldFieldStates[fieldType].result) {
              return oldFieldStates;
            }

            if (newResult === null) {
              return {
                ...oldFieldStates,
                [fieldType]: {
                  ...oldFieldStates[fieldType],
                  result: null,
                  dirty: false,
                },
              };
            }
          
            if (
              props.blockNavigation &&
              newResultShouldBlockNavigation &&
              !(
                newResult !== undefined &&
                newResult.isErr() &&
                newResult.error.every((e) => e instanceof RequiredError)
              )
            ) {
              setShouldBlockNavigation(true);
            }

            return {
              ...oldFieldStates,
              [fieldType]: {
                ...oldFieldStates[fieldType],
                result: newResult,
                dirty: oldFieldStates[fieldType].dirty || { value: newResult?.isOk() },
              },
            };
          });
        }
      );
    },
    [props.blockNavigation, newResultShouldBlockNavigation],
  );

  const getOnDirtyChange = useCallback(
    (fieldType: FieldType) => (
      (newDirty: DirtyInfo): void => {
        setFieldStates((prevFieldsInput: FieldStates) => {
          if (prevFieldsInput[fieldType].dirty.value === newDirty.value) {
            return prevFieldsInput;
          }

          return ({
            ...prevFieldsInput,
            [fieldType]: {
              ...prevFieldsInput[fieldType],
              dirty: newDirty,
            },
          });
        });
      }
    ),
    [],
  );

  const dirtyInputs = (): void => {
    setFieldStates((prevFieldInputs: FieldStates) => (
      Object.entries(prevFieldInputs)
        .reduce((acc: FieldStates, [fieldType, item]): FieldStates => {
          return {
            ...acc,
            [fieldType]: {
              ...item,
              dirty: { value: true },
            },
          };
        }, {} as FieldStates)));
  };

  const clearForm = (): void => {
    // @ts-ignore
    setFieldStates((prevFieldInputs: FieldStates) => (
      Object.entries(prevFieldInputs)
        // @ts-ignore
        .reduce((acc: FieldStates, [fieldType, item]) => {
          if (fieldType === "IMONumber") {
            return {
              ...acc,
              [fieldType]: item,
            };
          }
          return {
            ...acc,
            [fieldType]: {
              ...item,
              dirty: { value: false },
              result: null,
            },
          };
        }, {} as FieldStates)));
  };

  const trySaveReport = (reportType: ReportType, isDraft: boolean) => async (): Promise<void> => {
    const setLoading = isDraft ? setSaveDraftLoading : setSubmitReportLoading;
    setLoading(true);

    try {
      logger.info(`Saving report as ${isDraft ? "draft" : "final"} version.`);
      dirtyInputs();
      logFieldStates(fieldStates);

      const reportDataResult = fieldStatesToReportData(fieldStates, isDraft);
      if (reportDataResult.isErr()) {
        logger.debug("Errored fields:", reportDataResult.error);
        showErrorNotification(
          "Report could not be saved",
          "The report contains invalid or missing data, please check for errors.",
        );
        return;
      }

      const allReportsResult = await getAllReports();

      if (allReportsResult.isErr()) {
        logger.error("Failed to get all reports", allReportsResult.error);
        showErrorNotification("Report could not be saved", allReportsResult.error);
        return;
      }

      let report: Report;

      const allConfigsNonSendable = props.reportFormatConfigs.every((m) => !m.sendable);

      let status: ReportStatus;
      if (isDraft) {
        status = ReportStatus.Draft;
      } else if (SIMPLE_MODE || allConfigsNonSendable) {
        status = ReportStatus.Sent;
      } else {
        status = ReportStatus.Unsent;
      }

      if (props.existingReport !== undefined) {
        report = props.existingReport;
        report.data = reportDataResult.value;
        report.cloudStatus = CloudSyncStatus.AwaitingConnection;
        report.status = status;
        report.updatedAtTimestamp = getCurrentTimestamp();
        report.reportFormatsToSend = report.reportFormatsToSend.map((f) => {
          return new ReportFormatToSend(f.id, false, f.reportFormatConfig);
        });
      } else {
        report = Report.create({
          id: newId(),
          imoNumber: getLocalAuthPayload()!.imoNumber,
          type: reportType,
          data: reportDataResult.value,
          createdAt: DateTime.now(),
          updatedAtTimestamp: getCurrentTimestamp(),
          status,
          cloudStatus: CloudSyncStatus.AwaitingConnection,
          reportFormatsToSend: props.reportFormatConfigs.map((roc) => (
            new ReportFormatToSend(newId(), false, roc)
          )),
          deleted: false,
        });
      }

      const errorsInRespectToOtherReports = validateReportWithRespectToOtherReports(
        report,
        allReportsResult.value,
      );

      if (errorsInRespectToOtherReports != null) {
        logger.debug("Invalid report based on other reports:", errorsInRespectToOtherReports);
        showErrorNotification(
          "Report could not be saved",
          errorsInRespectToOtherReports,
        );
        return;
      }

      (await saveReport(report)).match(
        () => {
          setShouldBlockNavigation(false);
          history.push(Routes.Report.generate(report.id));
        },
        (error) => {
          logger.error("Failed to save report.", error);
          showErrorNotification("Report could not be saved", error);
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const getWrappedProvider = useCallback(
    (fieldType: FieldType): WrappedFormItemProvider<any> => {
      const provider = props.formItemProviders[fieldType];
      if (provider === undefined) {
        throw new Error(`No error provider found for field type "${fieldType}".`);
      }
      return provider;
    },
    [props.formItemProviders],
  );

  return {
    finalizeReport: trySaveReport(props.reportType, false),
    submitReportLoading,
    saveDraft: trySaveReport(props.reportType, true),
    saveDraftLoading,
    clearForm,
    currentProgressStep: progressStepsHook.currentProgressStep,
    onProgressStepClick: progressStepsHook.onProgressStepClick,
    headersListRef: progressStepsHook.headersListRef,
    fieldGroups: progressStepsHook.displayedFieldGroupsWithStatus,
    fieldStates,
    getWrappedProvider,
    getOnDirtyChange,
    getOnResultChange,
    shouldBlockNavigation,
    buttonsFloating,
    setButtonsFloating,
  };
};
