import React, { useEffect } from "react";
import styled from "styled-components";
import {
  DatePicker,
  Popconfirm,
  Select,
  Table,
  TimePicker,
} from "antd";
import {
  err,
  ok,
  Result,
} from "neverthrow";
import { ColumnsType } from "antd/lib/table/interface";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import {
  DateTime,
  FixedOffsetZone,
} from "luxon";
import moment from "moment";
import {
  DirtyInfo,
  FormItemProps,
} from "../../FormItem";

import {
  removeAtIndex,
  replaceAtIndex,
} from "../../../../../shared/utils/array";
import IconButton from "../../../misc-components/IconButton";
import ValidationError from "../../../../../domain/errors/ValidationError";
import RequiredError from "../../../../../domain/errors/RequiredError";
import ErrorWrapper from "../../ErrorWrapper";
import {
  Id,
  newId,
} from "../../../../../domain/Id";
import { deepEqual } from "../../../../../shared/utils/equality";
import {
  useSingleFormItem,
  UseSingleFormItemHook,
} from "../../useSingleFormItem";
import { notEmptyInputToResultWrapper } from "../../InputToResult";
import RequiredStar from "../../../misc-components/RequiredStar";
import AnchorOrBerthActionItem from "../../../../../domain/BerthingActionItem";
import AnchorOrBerthAction, { anchorOrBerthActionOptions } from "../../../../../domain/BerthingAction";
import { UTC_OFFSETS } from "../_generic/date/utcOffsets";
import logger from "../../../../../shared/utils/logger";
import DateString from "../_generic/date/DateString";
import TimeString from "../_generic/date/TimeString";
import { toMomentInput } from "../../../utils/toMomentInput";

type ActionInput = {
  id: Id;
  dirty: DirtyInfo;
  action: AnchorOrBerthAction | undefined;
  actionDate: string | undefined;
  actionTime: string | undefined;
  actionOffset: string | undefined;
};

type Props = FormItemProps<AnchorOrBerthActionItem[]>;

type Hook = UseSingleFormItemHook<ActionInput[], AnchorOrBerthActionItem[]> & {
  addActionItem: () => void,
  tableColumns: ColumnsType<ActionInput>,
};

/* ------------------------------------------------------------ */

const outputToInput = (value: AnchorOrBerthActionItem[]): ActionInput[] => value.map(
  (actionItem) => ({
    id: actionItem.id,
    action: actionItem.action,
    actionDate: DateString.fromDateTime(actionItem.actionDate).value,
    actionTime: TimeString.fromDateTime(actionItem.actionDate).value,
    actionOffset: FixedOffsetZone.instance(actionItem.actionDate.offset).name,
    dirty: { value: true },
  }),
);

/* ------------------------------------------------------------ */

const getInputToResult = (input: ActionInput[]): Result<AnchorOrBerthActionItem[], ValidationError[]> => {
  let anchorItems: AnchorOrBerthActionItem[] = [];
  for (const actionInput of (input ?? [])) {
    if (
      actionInput.action === undefined ||
      actionInput.actionDate === undefined ||
      actionInput.actionTime === undefined ||
      actionInput.actionOffset === undefined
    ) {
      return err([new RequiredError(
        "An action entry is missing one or more fields, please check for errors.",
        { userFacing: true },
      )]);
    }
    const isoStr = `${actionInput.actionDate}T${actionInput.actionTime}`;
    const dateTime = DateTime.fromISO(isoStr, { zone: actionInput.actionOffset });
    if (!dateTime.isValid) {
      logger.warn(`Failed to convert ISO datetime string "${isoStr}" to DateTime.`);
      return err([new ValidationError(`Action date is invalid.`, { userFacing: true })]);
    }

    anchorItems = [
      ...anchorItems,
      {
        id: actionInput.id,
        action: actionInput.action,
        actionDate: dateTime,
      },
    ];
  }
  return ok(anchorItems);
};

const useActionsItemsFormItem = (props: Props): Hook => {
  const hook = useSingleFormItem<ActionInput[], AnchorOrBerthActionItem[]>({
    ...props,
    inputToResult: notEmptyInputToResultWrapper("Action", getInputToResult),
    outputToInput,
    inputsEqual: deepEqual,
    dontAutoUpdateDirty: true,
  });

  useEffect(() => {
    hook._setInput((oldInput) => {
      if (oldInput === undefined) {
        return oldInput;
      }
      return oldInput.map((item) => ({ ...item, dirty: props.dirty }));
    });
  }, [props.dirty]);

  useEffect(() => {
    if (hook.input === undefined) {
      return;
    }
    const newInput = hook.input.map((item) => {
      const allFilled = (
        item.action !== undefined &&
        item.actionDate !== undefined
      );
      return ({ ...item, dirty: item.dirty || { value: allFilled } });
    });
    if (!deepEqual(newInput, hook.input)) {
      hook.onInputChange(newInput);
    }
  }, [hook.input]);

  const addActionItem = (): void => {
    props.onDirtyChange({ value: false });
    hook.onInputChange([
      ...(hook.input ?? []),
      {
        id: newId(),
        dirty: props.dirty ?? { value: false },
        action: undefined,
        actionDate: undefined,
        actionTime: undefined,
        actionOffset: undefined,
      },
    ]);
  };

  const renderAction = (
    action: AnchorOrBerthAction | undefined,
    item: ActionInput,
    index: number,
  ): React.ReactNode => (
    <Select
      className={item.dirty.value && action === undefined ? "error" : undefined}
      value={action}
      placeholder="Action"
      onChange={(newAction: AnchorOrBerthAction): void => {
        const newItem = {
          ...item,
          action: newAction,
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}>
      {
        anchorOrBerthActionOptions.map((option) => (
          <Select.Option
            value={option.value}
            key={option.label}
          >
            {option.label}
          </Select.Option>
        ))
      }
    </Select>
  );

  const renderActionDate = (dateString: string, item: ActionInput, index: number): React.ReactNode => {
    return (
      <DatePicker
        placeholder="YYYY-MM-DD"
        className={item.dirty.value && dateString === undefined ? "error" : undefined}
        onChange={(date, newDateString): void => {
          if (date == null) {
            hook.onInputChange(undefined);
          } else {
            if (hook.input == null) {
              return;
            }
            const newItem = {
              ...item,
              actionDate: newDateString,
            };

            hook.onInputChange(replaceAtIndex(hook.input, index, newItem));
          }
        }}
        format="YYYY-MM-DD"
        value={toMomentInput(item.actionDate, "YYYY-MM-DD")}
      />
    );
  };

  const renderActionTime = (timeString: string, item: ActionInput, index: number): React.ReactNode => {
    return (
      <TimePicker
        placeholder="HH:MM"
        className={item.dirty.value && timeString === undefined ? "error" : undefined}
        onChange={(time, newTimeString): void => {
          if (hook.input == null) {
            return;
          }
          if (time == null) {
            hook.onInputChange(undefined);
          } else {
            const newItem = {
              ...item,
              actionTime: newTimeString,
            };

            hook.onInputChange(replaceAtIndex(hook.input, index, newItem));
          }
        }}
        format="HH:mm"
        value={toMomentInput(item.actionTime, "HH:mm")}
        defaultOpenValue={moment("12:00", "HH:mm")}
      />
    );
  };

  const renderActionOffset = (utcString: string, item: ActionInput, index: number): React.ReactNode => {
    return (
      <Select
        value={utcString}
        onChange={(offset) => {
          if (offset == null) {
            hook.onInputChange(undefined);
          } else {
            if (hook.input == null) {
              return;
            }
            const newItem = {
              ...item,
              actionOffset: offset,
            };
            hook.onInputChange(replaceAtIndex(hook.input, index, newItem));
          }
        }}
        className={item.dirty.value && utcString === undefined ? "error" : undefined}
        placeholder="UTC Offset"
        options={UTC_OFFSETS.map((offset) => ({ value: offset.name, label: offset.name }))}
        filterOption={(input, option): boolean => option!.value.toLowerCase().includes(input.toLowerCase())}
      />
    );
  };

  const renderDelete = (_: unknown, item: ActionInput, index: number): React.ReactNode => (
    <Popconfirm
      title="Really delete this entry?"
      onConfirm={(): void => {
        const newInput = hook.input !== undefined
          ? removeAtIndex(hook.input, index)
          : undefined;
        hook.onInputChange(newInput);
      }}
      okText="Yes"
      cancelText="No"
    >
      <IconButton
        danger={true}
        icon={faTrash}
        type="link"
      />
    </Popconfirm>
  );

  const tableColumns: ColumnsType<ActionInput> = [
    {
      title: "Action",
      dataIndex: "action",
      render: renderAction,
      width: "30%",
    },
    {
      title: "Date",
      dataIndex: "actionDate",
      render: renderActionDate,
      width: "20%",
    },
    {
      title: "Time",
      dataIndex: "actionTime",
      render: renderActionTime,
      width: "20%",
    },
    {
      title: "UTC Offset",
      dataIndex: "actionOffset",
      render: renderActionOffset,
      width: "20%",
    },
    {
      title: "",
      render: renderDelete,
      width: "10%",
    },
  ];
  return {
    ...hook,
    addActionItem,
    tableColumns,
  };
};

/* ------------------------------------------------------------ */

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  & td > * {
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: flex-end;
  
  & > h3 {
    flex: 1 1 0px;
    margin: 0;
  }
  
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const AnchorOrBerthActionsItemsFormItem = (props: Props): React.ReactElement => {
  const hook = useActionsItemsFormItem(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      displayRequiredErrors={false}
      errors={hook.error}
    >
      <TableWrapper>
        <ButtonsWrapper>
          <h3>Actions{props.required && <RequiredStar />}</h3>
          <IconButton
            icon={faPlus}
            type="primary"
            onClick={hook.addActionItem}
          >
            Add entry
          </IconButton>
        </ButtonsWrapper>
        <Table
          columns={hook.tableColumns}
          dataSource={hook.input}
          pagination={false}
          rowKey="id"
          className={(hook.isError && hook.error === undefined) ? "error" : undefined}
          locale={{
            emptyText: "No action entries added",
          }}
          tableLayout="fixed"
        />
      </TableWrapper>
    </ErrorWrapper>
  );
};

export default AnchorOrBerthActionsItemsFormItem;