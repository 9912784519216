import React from "react";
import { Route, Redirect } from "react-router-dom";
import Routes from "./routes";
import { getLocalAuthToken } from "./auth";

const AuthenticatedRoute: React.FC = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={(): React.ReactNode => {
      if (getLocalAuthToken() === null) {
        return <Redirect to={Routes.Login.generate()} />;
      }
      return children;
    }} />
);

export default AuthenticatedRoute;