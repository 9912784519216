import React, {
  useEffect,
  useState,
} from "react";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  Input,
  Spin,
} from "antd";
import { Result } from "neverthrow";
import AuthCode from "react-auth-code-input/dist";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import ImoNumber from "../../domain/ImoNumber";
import ValidationError from "../../domain/errors/ValidationError";
import ErrorWrapper from "./report-form/ErrorWrapper";
import FetchErrors from "../../shared/errors/FetchErrors";
import * as api from "./api";
import { SendActivationCodeResponse } from "../../server/crew/auth/SendActivationCode";
import NetworkError from "../../shared/errors/NetworkError";
import IconButton from "./misc-components/IconButton";
import { APP_NAME } from "../../shared/utils/constants";
import { setLocalAuthToken, getLocalAuthToken } from "./auth";

const ENABLE_DIAGNOSTICS = false;

const StyledLoginPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  text-align: center;

  & > div {
    max-width: 600px;
    height: 400px;
    background: white;
    padding: 40px 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); 
  }
  
  & h1 {
    margin-bottom: 30px;
  }

  & .authCodeContainer {
    padding: 16px;
  }
  
  & .authCodeInput {
    width: 2ch;
    padding: 8px;
    border-radius: 8px;
    font-size: 40px;
    text-align: center;
    margin-right: 12px;
    border: 1px solid whitesmoke;
    text-transform: uppercase;
  }
  
  & .authCodeInput:focus {
    border: 1px solid whitesmoke;
  }
`;

const ImoInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  & input {
    width: 300px
  }
  
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const getSendActivationCodeErrorText = (error: FetchErrors): string => {
  if (error.userFacing) {
    return error.message;
  }
  if (error instanceof NetworkError) {
    return "Could not connect to the server.";
  }
  return "An error occurred, please contact support.";
};

const getGetAuthTokenErrorText = (error: FetchErrors): string => {
  if (error.userFacing) {
    return error.message;
  }
  if (error instanceof NetworkError) {
    return "Could not connect to the server.";
  }
  return "An error occurred, please contact support.";
};

const LoginPage: React.FC = () => {
  const [imoInput, setImoInput] = useState<string>();
  const [imoNumber, setImoNumber] = useState<ImoNumber>();
  const [imoError, setImoError] = useState<ValidationError[]>();
  const [sendActivationCodeResult, setSendActivationCodeResult] = useState<Result<SendActivationCodeResponse, FetchErrors>>();
  const [getAuthTokenLoading, setGetAuthTokenLoading] = useState<boolean>(false);
  const [getAuthTokenError, setGetAuthTokenError] = useState<FetchErrors>();

  const [diagnosticInfo, setDiagnosticInfo] = useState<{
    browser: string,
    client: string,
    server: string,
    thirdParty: string,
  }>();
  useEffect(() => {
    (async (): Promise<void> => {
      let browser;
      try {
        browser = window.navigator.onLine ? "Online" : "Offline";
      } catch (error) {
        browser = `Error (${error.message})`;
      }

      let client;
      try {
        const response = await fetch(process.env.PUBLIC_URL as string);
        client = `${response.ok ? "Online" : "Offline"} (${response.status})`;
      } catch (error) {
        client = `Error (${error.message})`;
      }

      let server;
      try {
        const response = await fetch(process.env.REACT_APP_API_URL as string);
        server = `${response.ok ? "Online" : "Offline"} (${response.status})`;
      } catch (error) {
        server = `Error (${error.message})`;
      }

      let thirdParty;
      try {
        const response = await fetch("https://httpstat.us/200");
        thirdParty = `${response.ok ? "Online" : "Offline"} (${response.status})`;
      } catch (error) {
        thirdParty = `Error (${error.message})`;
      }

      setDiagnosticInfo({
        browser,
        client,
        server,
        thirdParty,
      });
    })();
  }, []);

  const history = useHistory();

  const goBack = (): void => {
    setSendActivationCodeResult(undefined);
    setGetAuthTokenError(undefined);
  };

  const sendCode = async (): Promise<void> => {
    const imoResult = ImoNumber.fromString(imoInput ?? "");
    if (imoResult.isErr()) {
      setImoError(imoResult.error);
      return;
    }
    setImoNumber(imoResult.value);
    const newSendActivationCodeResult = await api.sendActivationCode({
      imoNumber: imoResult.value,
    });
    setSendActivationCodeResult(newSendActivationCodeResult);
  };

  const getToken = async (activationCode: string): Promise<void> => {
    try {
      setGetAuthTokenLoading(true);
      if (imoNumber === undefined) {
        goBack();
        return;
      }
      const imoResult = ImoNumber.fromString(imoInput ?? "");
      if (imoResult.isErr()) {
        setImoError(imoResult.error);
        return;
      }
      const getAuthTokenResult = await api.getAuthToken({
        imoNumber,
        activationCode: activationCode.toUpperCase(),
      });
      if (getAuthTokenResult.isErr()) {
        setGetAuthTokenError(getAuthTokenResult.error);
        return;
      }
      await api.sendActivationNotification({
        imoNumber: imoResult.value,
      });
      const { authToken } = getAuthTokenResult.value;
      setLocalAuthToken(authToken);
      history.push("/");
    } finally {
      setGetAuthTokenLoading(false);
    }
  };

  useEffect(() => {
    setImoError(undefined);
  }, [imoInput]);

  if (getLocalAuthToken() !== null) {
    return <Redirect to="/" />;
  }

  return (
    <StyledLoginPage>
      {ENABLE_DIAGNOSTICS && (
        <div style={{
          position: "absolute",
          left: 10,
          bottom: 10,
          border: "2px solid grey",
          background: "white",
          height: "fit-content",
          padding: 15,
        }}>
          <h3>Network diagnostics</h3>
          {diagnosticInfo == null && (
            <span>Gathering diagnostics info...</span>
          )}
          {diagnosticInfo != null && (
            <span>
              <b>Browser connection status:</b> {diagnosticInfo?.browser}
              <br />
              <b>Connection to {process.env.PUBLIC_URL}:</b> {diagnosticInfo?.client}
              <br />
              <b>Connection to {process.env.REACT_APP_API_URL}:</b> {diagnosticInfo?.server}
              <br />
              <b>Connection to third party:</b> {diagnosticInfo?.thirdParty}
            </span>
          )}
        </div>
      )}
      <div>
        <h1>Welcome to {APP_NAME}</h1>
        {!sendActivationCodeResult?.isOk() && (
          <>
            <p>To get started, please enter the vessel&apos;s IMO number.</p>
            <ImoInputWrapper>
              <ErrorWrapper
                isError={imoError !== undefined}
                errors={imoError}
                defaultErrorText="Invalid IMO number."
              >
                <Input
                  autoFocus={true}
                  onChange={(e): void => setImoInput(e.target.value)}
                  value={imoInput}
                  placeholder="IMO number"
                  onKeyPress={(e): void => {
                    if (e.key === "Enter") {
                      // noinspection JSIgnoredPromiseFromCall
                      sendCode();
                    }
                  }}
                />
              </ErrorWrapper>
              <Button
                type="primary"
                onClick={sendCode}
              >
                Submit
              </Button>
            </ImoInputWrapper>

            {sendActivationCodeResult?.isErr() && (
              <p style={{
                color: "red",
                maxWidth: "400px",
                marginTop: "20px",
              }}>
                Failed to send activation code. {getSendActivationCodeErrorText(sendActivationCodeResult.error)}
              </p>
            )}
          </>
        )}

        {sendActivationCodeResult?.isOk() && (
          <>
            <p>We have sent a 6-digit activation code to <b>{sendActivationCodeResult.value.email}</b>,<br />which you must enter below to get started.</p>
            {!getAuthTokenLoading && (
              <AuthCode
                characters={6}
                onChange={(newValue: string): void => {
                  if (newValue === undefined || newValue.length !== 6) {
                    return;
                  }
                  getToken(newValue.toUpperCase());
                }}
                containerClassName="authCodeContainer"
                inputClassName="authCodeInput"
              />
            )}
            {getAuthTokenLoading && (
              <Spin
                style={{
                  margin: "20px 0px 20px 0px",
                }}
                size="large"
              />
            )}
            {!getAuthTokenLoading && getAuthTokenError && (
              <p style={{
                color: "red",
                maxWidth: "400px",
              }}>
                Failed to activate account. {getGetAuthTokenErrorText(getAuthTokenError)}
              </p>
            )}
            <IconButton
              onClick={goBack}
              icon={faArrowLeft}
              style={{
                marginBottom: "20px",
              }}
            >
              Back
            </IconButton>
          </>
        )}
      </div>
    </StyledLoginPage>
  );
};

export default LoginPage;
