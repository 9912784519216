import {
  useEffect,
  useMemo,
  useState,
} from "react";

export const useIsVisible = (element: Element): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
    );
  }, []);

  useEffect(() => {
    if (element == null) {
      return () => {};
    }

    observer.observe(element);
    return (): void => observer.disconnect();
  }, [element == null]);

  return isVisible;
};