import {
  ok,
  Result,
} from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";

export enum SpeedInstructionOptions {
  Full = "Full",
  Eco = "Economical",
  Other = "Other",
}

@serializableDecorator<SpeedInstructions>()
class SpeedInstructions {
  public constructor(
    public readonly option: SpeedInstructionOptions,
    public readonly otherValue?: string,
  ) {}
  
  static serialize(obj: SpeedInstructions): Result<Serialized, SerializationError> {
    return ok({
      option: obj.option,
      otherValue: obj.otherValue,
      __className: SpeedInstructions.name,
    });
  }

  static deserialize(serialized: Serialized): Result<SpeedInstructions, SerializationError> {
    return ok(new SpeedInstructions(serialized.option, serialized.otherValue));
  }
}

export default SpeedInstructions;