import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import Beaufort from "../../../../../../domain/units/Beaufort";

const fieldName = "Wind Force";

const WindForceFormItemProvider: FormItemProvider<Beaufort> = (props: FormItemProviderProps<Beaufort>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="WindForce"
    inputToResult={notEmptyInputToResultWrapper(fieldName, Beaufort.fromNumber)}
    outputToInput={unwrapValue}
    allowNegative={false}
    integersOnly={true}
    inputsEqual={eqeqeq}
    addonAfter="beaufort"
  />
);

export default React.memo(WindForceFormItemProvider);