import {
  err,
  Result,
} from "neverthrow";
import { useCallback } from "react";
import ValidationError from "../../../domain/errors/ValidationError";
import RequiredError from "../../../domain/errors/RequiredError";

export function notEmptyInputToResultWrapper<I, O>(
  fieldName: string,
  inputToResult: (input: I) => Result<O, ValidationError[]>,
): (input: I | undefined) => Result<O, ValidationError[]> {
  return useCallback((input: I | undefined): Result<O, ValidationError[]> => {
    const isEmptyString = typeof input === "string" && input === "";
    if (input === undefined || isEmptyString) {
      return err([new RequiredError(`${fieldName} is required.`, { userFacing: true })]);
    }
    return inputToResult(input);
  }, [fieldName, inputToResult]);
}