import React from "react";
import { Result } from "neverthrow";
import styled from "styled-components";
import {
  FormItem,
  FormItemProps,
} from "../../../../FormItem";
import StandardInputContainer from "../../../../StandardInputContainer";
import Latitude, { LatHemisphere } from "../../../../../../../domain/position/Latitude";
import { Degrees } from "../../../../../../../domain/units/Degrees";
import { Minutes } from "../../../../../../../domain/units/Minutes";
import {
  Outputs,
  useCompositeFormItem,
  UseCompositeFormItemHook,
} from "../../../../useCompositeFormItem";
import ValidationError from "../../../../../../../domain/errors/ValidationError";
import DegreesInput from "../shared/DegreesInput";
import { eqeqeq } from "../../../../../../../shared/utils/equality";
import MinutesInput from "../shared/MinutesInput";
import HemisphereInput from "../shared/HemisphereInput";
import identity from "../../../../../../../shared/utils/identity";
import Hemisphere from "../../../../../../../domain/position/Hemisphere";
import { unwrapValue } from "../../../../../../../shared/utils/unwrapValue";
import { notEmptyInputToResultWrapper } from "../../../../InputToResult";

type ChildInputTypes = {
  degrees: {
    input: number,
    output: Degrees,
  },
  minutes: {
    input: number,
    output: Minutes,
  },
  hemisphere: {
    input: Hemisphere,
    output: LatHemisphere,
  }
};

const childOutputsEqual = (outputs1: Outputs<ChildInputTypes>, outputs2: Outputs<ChildInputTypes>): boolean => (
  outputs1.degrees.equals(outputs2.degrees) &&
  outputs1.minutes.equals(outputs2.minutes) &&
  outputs1.hemisphere === outputs2.hemisphere
);

const childOutputsToResult = (outputs: Outputs<ChildInputTypes>): Result<Latitude, ValidationError[]> => (
  Latitude.fromDegreesDecimalMinutes(outputs)
);

const outputToChildOutputs = (output: Latitude): Outputs<ChildInputTypes> => (
  output.toDegreesDecimalMinutes()
);

export type DDMFormItemProps =
  & FormItemProps<Latitude>;

export type DDMFormItemHook =
  {
    degreesInputToResult: (input: number | undefined) => Result<Degrees, ValidationError[]>,
    minutesInputToResult: (input: number | undefined) => Result<Minutes, ValidationError[]>,
    hemisphereInputToResult: (input: Hemisphere | undefined) => Result<LatHemisphere, ValidationError[]>,
  }
  & UseCompositeFormItemHook<ChildInputTypes, Latitude>;

/* ------------------------------------------------------------ */

export const useDDMLatitudeFormItem = (props: DDMFormItemProps): DDMFormItemHook => {
  const hook = useCompositeFormItem<ChildInputTypes, Latitude>({
    ...props,
    dataKeys: ["degrees", "minutes", "hemisphere"],
    outputToChildOutputs,
    childOutputsToResult,
    childOutputsEqual,
  });

  const degreesInputToResult = notEmptyInputToResultWrapper("Latitude", (input: number) => (
    Latitude.validateDDMDegrees(new Degrees(input))
  ));

  const minutesInputToResult = notEmptyInputToResultWrapper("Latitude", (input: number) => (
    Latitude.validateDDMMinutes(new Degrees(input))
  ));

  const hemisphereInputToResult = notEmptyInputToResultWrapper("Latitude", Latitude.validateHemisphere);

  return {
    ...hook,
    degreesInputToResult,
    minutesInputToResult,
    hemisphereInputToResult,
  };
};

/* ------------------------------------------------------------ */

const InputsWrapper = styled.div`
  & {
    display: flex;
    flex-direction: row;
  }
  
  & > *:not(:last-child) {
    flex: 2 1 0px;
    margin-right: 10px;
  }
  
  & > *:last-child {
    flex: 1 1 0px;
  }
`;

const DDMLatitudeFormItem: FormItem<Latitude> = (props) => {
  const hook = useDDMLatitudeFormItem({
    ...props,
  });

  return (
    <StandardInputContainer
      fieldName="Latitude"
      required={props.required}
      isError={hook.isError}
      isOk={hook.isOk}
    >
      <InputsWrapper>
        <DegreesInput
          result={hook.degrees.result}
          onResultChange={hook.degrees.onResultChange}
          dirty={hook.degrees.dirty}
          onDirtyChange={hook.degrees.onDirtyChange}
          outputToInput={unwrapValue}
          inputToResult={hook.degreesInputToResult}
          inputsEqual={eqeqeq}
          required={props.required}
          decimal={false}
        />
        <MinutesInput
          result={hook.minutes.result}
          onResultChange={hook.minutes.onResultChange}
          dirty={hook.minutes.dirty}
          onDirtyChange={hook.minutes.onDirtyChange}
          outputToInput={unwrapValue}
          inputToResult={hook.minutesInputToResult}
          inputsEqual={eqeqeq}
          required={props.required}
          decimal={true}
        />
        <HemisphereInput<LatHemisphere>
          result={hook.hemisphere.result}
          onResultChange={hook.hemisphere.onResultChange}
          dirty={hook.hemisphere.dirty}
          onDirtyChange={hook.hemisphere.onDirtyChange}
          outputToInput={identity}
          inputToResult={hook.hemisphereInputToResult}
          hemisphereChoices={[Hemisphere.N, Hemisphere.S]}
          inputsEqual={eqeqeq}
          required={props.required}
        />
      </InputsWrapper>
    </StandardInputContainer>
  );
};

export default DDMLatitudeFormItem;