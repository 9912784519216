import React from "react";
import {
  green,
  red,
  yellow,
} from "@ant-design/colors";
import assertUnreachable from "../../../shared/utils/assertUnreachable";
import CloudSyncStatus from "../../../domain/CloudSyncStatus";
import StatusSpan from "./StatusSpan";

const CloudStatusText: React.FC<{
  cloudStatus: CloudSyncStatus,
  tooltipMode: "underline" | "icon",
}> = ({
  cloudStatus,
  tooltipMode,
}) => {
  switch (cloudStatus) {
    case CloudSyncStatus.AwaitingConnection:
      return (
        <StatusSpan
          text={CloudSyncStatus.AwaitingConnection}
          color={yellow.primary}
          tooltip="The report will automatically be synced once the connection is restored."
          tooltipMode={tooltipMode}
        />
      );
    case CloudSyncStatus.Failed:
      return (
        <StatusSpan
          text={CloudSyncStatus.Failed}
          color={red.primary}
          tooltip="The report could not be synced to the cloud."
          tooltipMode={tooltipMode}
        />
      );
    case CloudSyncStatus.Synced:
      return (
        <StatusSpan
          text={CloudSyncStatus.Synced}
          color={green.primary}
          tooltip="The report has been synced with the cloud."
          tooltipMode={tooltipMode}
        />
      );
    default:
      return assertUnreachable(cloudStatus);
  }
};

export default CloudStatusText;