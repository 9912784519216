import React from "react";
import NumericInput from "../../../../../misc-components/NumericInput";

import ErrorWrapper from "../../../../ErrorWrapper";
import { Seconds } from "../../../../../../../domain/units/Seconds";
import {
  useSingleFormItem,
  UseSingleFormItemProps,
} from "../../../../useSingleFormItem";
import { Degrees } from "../../../../../../../domain/units/Degrees";
import { FormItemProps } from "../../../../FormItem";

type Props =
  {
    decimal: boolean,
  }
  & UseSingleFormItemProps<number, Seconds>
  & FormItemProps<Degrees>;

const SecondsInput: React.FC<Props> = (props) => {
  const hook = useSingleFormItem(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <NumericInput
        addonAfter="'"
        placeholder="Seconds"
        className={hook.isError ? "error" : undefined}
        value={hook.input}
        onChange={hook.onInputChange}
        integersOnly={!props.decimal}
        separateThousands={false}
        allowNegative={false}
        returnUndefinedOnEmpty={true}
      />
    </ErrorWrapper>
  );
};

export default SecondsInput;