import UserSettings from "../../domain/reports/UserSettings";

// TODO: Save this per vessel
export const USER_SETTINGS: UserSettings = {
  coordinateType: "DMS",
  requiredConsumptionEngineTypes: [
  ],
  requiredOrderedConsumptionFuelTypes: [
  ],
  showConsumptionEngine: true,
  showConsumptionReason: true,
  showConsumptionTime: true,
};