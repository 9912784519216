import React, {
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  err,
  ok,
  Result,
} from "neverthrow";
import { Switch } from "antd";
import styled from "styled-components";
import {
  FormItemProviderPropsWithFieldStates,
} from "../../../FormItemProvider";
import { NamedLocation } from "../../../../../../domain/NamedLocation";
import {
  FormItemComponent,
  FormItemProps,
} from "../../../FormItem";
import {
  Outputs,
  useCompositeFormItem,
} from "../../../useCompositeFormItem";
import ValidationError from "../../../../../../domain/errors/ValidationError";
import { Coordinates } from "../../../../../../domain/position/Coordinates";
import Port from "../../../../../../domain/Port";
import PositionFormItemProvider from "../position/PositionFormItemProvider";
import PortFormItemProvider from "../../ports/PortFormItemProvider";
import LocationNameInput from "./LocationNameInput";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import StandardInputContainer from "../../../StandardInputContainer";
import identity from "../../../../../../shared/utils/identity";
import ports from "../../../../../../domain/ports.json";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";

function NamedLocationFormItemProvider(
  props: FormItemProviderPropsWithFieldStates<NamedLocation>,
) {
  const getPositionFormItem = useCallback(
    (formItemProps: FormItemProps<Coordinates>) => (
      <PositionFormItemProvider
        {...formItemProps}
        reportType={props.reportType}
        fieldStates={props.fieldStates}
        previousReport={props.previousReport}
        settings={props.settings}
      />
    ),
    [
      props.reportType,
      props.fieldStates,
      props.previousReport,
      props.settings,
      props.suggestions,
    ],
  );

  const getPortFormItem = useCallback(
    (formItemProps: FormItemProps<Port>) => {
      return (
        <PortFormItemProvider
          {...formItemProps}
          reportType={props.reportType}
          previousReport={props.previousReport}
          settings={props.settings}
          fieldName="Port"
          fieldType="CurrentPort"
        />
      );
    },
    [
      props.reportType,
      props.fieldStates,
      props.previousReport,
      props.settings,
      props.suggestions,
    ],
  );

  return (
    <NamedLocationFormItem
      {...props}
      getPositionFormItem={getPositionFormItem}
      getPortFormItem={getPortFormItem}
    />
  );
}

const StyledSwitchWrapper = styled.div`
  & {
    display: flex;
    font-size: 1.25em;
    align-items: center;
    margin-left: 135px;
  }
  
  & > button {
    font-size: 1.25em;
    margin: 0 10px;
  }
`;

const StyledWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 35px;
  }
`;

type NamedLocationFormItemProps = (
  FormItemProps<NamedLocation> &
  {
    getPositionFormItem: (props: FormItemProps<Coordinates>) => React.ReactElement,
    getPortFormItem: (props: FormItemProps<Port>) => React.ReactElement,
  }
);

function NamedLocationFormItem(
  props: NamedLocationFormItemProps,
): FormItemComponent<NamedLocation> {
  const [isCustomLocation, setIsCustomLocation] = useState(
    props.result?.isOk() && !props.result.value.fromPort,
  );

  useEffect(() => {
    if (props.result?.isOk()) {
      const newIsCustomLocation = !props.result.value.fromPort;

      if (newIsCustomLocation !== isCustomLocation) {
        setIsCustomLocation(newIsCustomLocation);
      }
    }
  }, [props.result]);

  const customLocationHook = useCompositeFormItem<CustomLocationChildInputTypes, NamedLocation>({
    ...props,
    dataKeys: ["location", "name"],
    childOutputsToResult: customChildOutputsToResult,
    childOutputsEqual: customChildOutputsEqual,
    outputToChildOutputs: customOutputsToChildOutputs,
    onResultChange: useCallback((result) => {
      if (result?.isOk() && result.value.fromPort) {
        return;
      }

      props.onResultChange(result);
    }, []),
    result: (props.result?.isOk() && props.result.value.fromPort) ? undefined : props.result,
  });

  const portLocationHook = useCompositeFormItem<PortLocationChildInputTypes, NamedLocation>({
    ...props,
    dataKeys: ["port"],
    childOutputsToResult: portChildOutputsToResult,
    childOutputsEqual: portChildOutputsEqual,
    outputToChildOutputs: portOutputToChildOutputs,
    onResultChange: useCallback((result) => {
      if (result?.isOk() && !result.value.fromPort) {
        return;
      }

      props.onResultChange(result);
    }, []),
    result: (props.result?.isOk() && !props.result.value.fromPort) ? undefined : props.result,
  });

  const PositionFormItem = props.getPositionFormItem({
    dirty: customLocationHook.location.dirty,
    result: (
      (customLocationHook.location.result?.isOk() && customLocationHook.location.result.value === null) ?
        null :
        customLocationHook.location.result?.map((l) => l!) ?? null
    ),
    required: true,
    onDirtyChange: customLocationHook.location.onDirtyChange,
    onResultChange: customLocationHook.location.onResultChange,
  });

  const PortFormItem = props.getPortFormItem({
    dirty: portLocationHook.port.dirty,
    result: (
      (portLocationHook.port.result?.isOk() && portLocationHook.port.result.value === null) ?
        null :
        portLocationHook.port.result?.map((l) => l!) ?? null
    ),
    required: true,
    onDirtyChange: portLocationHook.port.onDirtyChange,
    onResultChange: portLocationHook.port.onResultChange,
  });

  return (
    <StyledWrapper>
      <StyledSwitchWrapper className="hideWhenDisabled">
        <span>Port</span>
        <Switch
          onChange={(newIsCustomLocation) => {
            props.onResultChange(null);

            setIsCustomLocation(newIsCustomLocation);
          }}
          checked={isCustomLocation}
        />
        <span>Coordinates</span>
      </StyledSwitchWrapper>
      <div
        style={{
          display: isCustomLocation ? "none" : undefined,
        }}
      >
        {PortFormItem}
      </div>
      <StandardInputContainer
        fieldName="Location Name"
        required={true}
        isOk={customLocationHook.name.result?.isOk()}
        isError={(
          customLocationHook.name.dirty.value &&
          (customLocationHook.name.result?.isErr() ?? true)
        )}
        style={{
          display: isCustomLocation ? undefined : "none",
        }}
      >
        <LocationNameInput
          {...customLocationHook.name}
          inputsEqual={eqeqeq}
          inputToResult={notEmptyInputToResultWrapper("Location Name", ok)}
          outputToInput={identity}
          required={true}
        />
      </StandardInputContainer>
      <div
        style={{
          display: isCustomLocation ? undefined : "none",
        }}
      >
        {PositionFormItem}
      </div>
    </StyledWrapper>
  );
}

type CustomLocationChildInputTypes = {
  location: {
    input: Coordinates | undefined,
    output: Coordinates | undefined,
  },
  name: {
    input: string | undefined,
    output: string | undefined,
  }
};

type PortLocationChildInputTypes = {
  port: {
    input: Port | undefined,
    output: Port | undefined,
  }
};

function customChildOutputsToResult(
  outputs: Outputs<CustomLocationChildInputTypes>,
): Result<NamedLocation, ValidationError[]> {
  if (outputs.name == null || outputs.location == null) {
    return err([
      new ValidationError(
        `You must enter either a location or a port.`,
        { userFacing: true },
      ),
    ]);
  }

  return NamedLocation.fromPosition(outputs.location, outputs.name)
    .mapErr((e) => [e]);
}

function customChildOutputsEqual(
  o1: Outputs<CustomLocationChildInputTypes>,
  o2: Outputs<CustomLocationChildInputTypes>,
): boolean {
  const nameEquals = o1.name === o2.name;

  const locationEquals = (
    (o1.location == null && o2.location == null) ||
    (
      (o1.location != null && o2.location != null) &&
      (o1.location.equals(o2.location))
    )
  );

  return (
    nameEquals &&
    locationEquals
  );
}

function customOutputsToChildOutputs(
  output: NamedLocation,
): Outputs<CustomLocationChildInputTypes> {
  if (output.fromPort) {
    return {
      name: undefined,
      location: undefined,
    };
  }

  return {
    name: output.name,
    location: output.location,
  };
}

function portChildOutputsToResult(
  outputs: Outputs<PortLocationChildInputTypes>,
): Result<NamedLocation, ValidationError[]> {
  if (outputs.port == null) {
    return err([
      new ValidationError(
        `You must enter either a location or a port.`,
        { userFacing: true },
      ),
    ]);
  }

  return NamedLocation.fromPort(outputs.port)
    .mapErr((e) => [e]);
}

function portChildOutputsEqual(
  o1: Outputs<PortLocationChildInputTypes>,
  o2: Outputs<PortLocationChildInputTypes>,
): boolean {
  return o1.port?.id === o2.port?.id;
}

function portOutputToChildOutputs(
  output: NamedLocation,
): Outputs<PortLocationChildInputTypes> {
  if (!output.fromPort) {
    return {
      port: undefined,
    };
  }

  const portData = ports.find((p) => p.name === output.name);

  if (portData == null) {
    throw new Error(`Failed to find port based on name "${output.name}".`);
  }

  return {
    port: Port.fromPortData(portData),
  };
}

export default React.memo(NamedLocationFormItemProvider);
