import { ok } from "neverthrow";
import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { KiloWattHours } from "../../../../../../domain/units/KiloWattHours";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Avg. Main Engine Power";

const MainEnginePowerFormItemProvider: FormItemProvider<KiloWattHours> = (props: FormItemProviderProps<KiloWattHours>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="MainEnginePower"
    separateThousands={true}
    allowNegative={false}
    integersOnly={false}
    addonAfter="kWh"
    inputToResult={notEmptyInputToResultWrapper(fieldName, (input: number) => ok(new KiloWattHours(input)))}
    outputToInput={unwrapValue}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(MainEnginePowerFormItemProvider);