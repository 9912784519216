import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  green,
  red,
  yellow,
} from "@ant-design/colors";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import React, { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ValidationError from "../../../domain/errors/ValidationError";
import ErrorWrapper from "./ErrorWrapper";
import RequiredStar from "../misc-components/RequiredStar";

const getIconProps = (isError: boolean, isWarning: boolean, isFilled: boolean): {
  color: string,
  icon: IconDefinition,
} => {
  if (isError) {
    return {
      color: red.primary as string,
      icon: faTimesCircle,
    };
  }
  if (isWarning) {
    return {
      color: yellow.primary as string,
      icon: faExclamationTriangle,
    };
  }
  if (isFilled) {
    return {
      color: green.primary as string,
      icon: faCheckCircle,
    };
  }
  return {
    color: "#e0e0e0",
    icon: faCheckCircle,
  };
};

type Props = {
  required: boolean,
  fieldName: string,
  isOk?: boolean,
  isWarning?: boolean,
  isError?: boolean,
  errors?: ValidationError[],
  style?: CSSProperties,
};

const StyledStandardInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 50px;
  
  & > :not(:last-child) {
    flex: 1 1 0px;
  }
  
  & > label {
    color: gray;
    margin-right: 10px;
    max-width: 170px;
    display: flex;
    justify-content: flex-end;
    padding-top: 4px;
    text-align: right;
  }
`;

const InputBody = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  
  & > * {
    flex: 1 1 0px;
  }
`;

const StatusIcon = styled.div`
  margin-left: 10px;
  font-size: 1.5em;
  line-height: 1em;
  display: flex;
  height: 32px;
  align-items: center;
`;

const StandardInputContainer: React.FC<Props> = ({
  children,
  fieldName,
  required,
  errors,
  isOk = false,
  isWarning = false,
  isError = false,
  style,
}) => {
  const iconProps = getIconProps(isError, isWarning, isOk);

  return (
    <StyledStandardInputContainer style={style}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        <span>{fieldName}</span>
        {required && <RequiredStar />}
      </label>
      <ErrorWrapper isError={isError} errors={errors}>
        <InputBody>
          {children}
        </InputBody>
      </ErrorWrapper>
      <StatusIcon className="fa-fw fa-lg status-icon">
        <FontAwesomeIcon
          color={iconProps.color}
          icon={iconProps.icon}
        />
      </StatusIcon>
    </StyledStandardInputContainer>
  );
};

export default StandardInputContainer;