import React from "react";
import NumericInput from "../../../../misc-components/NumericInput";
import ErrorWrapper from "../../../ErrorWrapper";
import {
  useSingleFormItem,
  UseSingleFormItemProps,
} from "../../../useSingleFormItem";
import { FormItemProps } from "../../../FormItem";
import { Quantity } from "../../../../../../domain/units/Quantity";

type Props =
  & UseSingleFormItemProps<number, Quantity>
  & FormItemProps<Quantity>;

const QuantityInput: React.FC<Props> = (props) => {
  const hook = useSingleFormItem(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <NumericInput
        placeholder="Quantity"
        className={hook.isError ? "error" : undefined}
        value={hook.input}
        onChange={(newInput: number | undefined): void => {
          hook.onInputChange(newInput);
        }}
        integersOnly={false}
        separateThousands={false}
        allowNegative={true}
        returnUndefinedOnEmpty={true}
      />
    </ErrorWrapper>
  );
};

export default QuantityInput;