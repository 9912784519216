import {
  err,
  ok,
  Result,
} from "neverthrow";
import ValidationError from "./errors/ValidationError";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";

const validPattern = /^[a-z]{2} ?[a-z2-9]{3}$/i;

@serializableDecorator<PortCode>()
class PortCode {
  private constructor(
    public readonly value: string,
  ) {}

  public static fromString(input: string): Result<PortCode, ValidationError[]> {
    if (!validPattern.test(input)) {
      return err([new ValidationError(`Port code is invalid. Must have format XX XXX.`, { userFacing: true })]);
    }
    const formattedCode = (`${input.slice(0, 2)} ${input.slice(-3)}`).toUpperCase();
    return ok(new PortCode(formattedCode));
  }

  static serialize(obj: PortCode): Result<Serialized, SerializationError> {
    return ok({ value: obj.value, __className: PortCode.name });
  }

  static deserialize(serialized: Serialized): Result<PortCode, SerializationError> {
    return ok(new PortCode(serialized.value));
  }
}

export default PortCode;