import Hemisphere from "./Hemisphere";
import { round } from "../../shared/utils/math";
import { Degrees } from "../units/Degrees";
import { Seconds } from "../units/Seconds";
import { Minutes } from "../units/Minutes";

export type DMS<H extends Hemisphere> = {
  degrees: Degrees,
  minutes: Minutes,
  seconds: Seconds,
  hemisphere: H,
};
export type DDM<H extends Hemisphere> = {
  degrees: Degrees,
  minutes: Minutes,
  hemisphere: H,
};

const DEGREES_PRECISION = 3;
const MINUTES_PRECISION = 2;
const SECONDS_PRECISION = 1;

abstract class SingleCoordinate {
  protected readonly decimalDegrees: Degrees;

  protected constructor(decimalDegrees: Degrees) {
    const roundedDecimalDegrees: Degrees = new Degrees(decimalDegrees.value);
    this.decimalDegrees = roundedDecimalDegrees;
  }

  public toDecimalDegrees(options = { round: true }): Degrees {
    if (options.round) {
      const roundedDegrees = round(this.decimalDegrees.value, DEGREES_PRECISION);
      return new Degrees(roundedDegrees);
    } 
    return this.decimalDegrees;
  }

  public toDegreesMinuteSeconds(): DMS<Hemisphere> {
    const absDegrees = Math.abs(this.decimalDegrees.value);
    const degrees = Math.floor(absDegrees);
    const minutes = Math.floor(round((absDegrees - degrees) * 60, 10));
    const seconds = Math.abs((absDegrees - degrees) * (60 * 60) - minutes * 60);
    const roundedSeconds = round(seconds, SECONDS_PRECISION);

    const hemisphere = this.getHemisphere();

    return {
      degrees: new Degrees(degrees),
      minutes: new Minutes(minutes),
      seconds: new Seconds(roundedSeconds),
      hemisphere,
    };
  }

  public toDegreesDecimalMinutes(): DDM<Hemisphere> {
    const absDegrees = Math.abs(this.decimalDegrees.value);
    const degrees = Math.floor(absDegrees);
    const minutes = round((absDegrees - degrees) * 60, MINUTES_PRECISION);
    const hemisphere = this.getHemisphere();

    return {
      degrees: new Degrees(degrees),
      minutes: new Minutes(minutes),
      hemisphere,
    };
  }

  public equals(that: SingleCoordinate): boolean {
    return this.toDecimalDegrees().value === that.toDecimalDegrees().value;
  }

  public toDecimalString(): string {
    return `${this.toDecimalDegrees().value}°`;
  }

  public toDDMString(): string {
    const ddm = this.toDegreesDecimalMinutes();
    return `${ddm.degrees.value}°${ddm.minutes.value}'${ddm.hemisphere}`;
  }

  public toDMSString(): string {
    const latDms = this.toDegreesMinuteSeconds();
    return `${latDms.degrees.value}°${latDms.minutes.value}'${latDms.seconds.value}"${latDms.hemisphere}`;
  }

  protected abstract getHemisphere(): Hemisphere;
}

export default SingleCoordinate;