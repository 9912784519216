import React from "react";

type CircleProps = {
  size: string,
  color?: string
  style?: React.CSSProperties,
};

const Circle: React.FC<CircleProps> = (props) => {
  return (
    <span style={{
      height: props.size,
      width: props.size,
      backgroundColor: props.color,
      borderRadius: "50%",
      display: "inline-block",
      ...props.style,
    }} />
  );
};

export default Circle;