import {
  ChangeEvent,
  KeyboardEvent,
  useState,
} from "react";
import {
  addChange,
  redo,
  undo,
} from "../../../shared/utils/undo";

type InputElements = HTMLInputElement | HTMLTextAreaElement;

type UseUndoReturnType = [
  handleChange: (e: ChangeEvent<InputElements>) => void,
  handleKeyDown: (e: KeyboardEvent) => void,
  addNewValue: (newValue?: string) => void,
];

const useUndo = (
  onChange: (value: string) => void,
  value?: string,
  transformValue?: (value: string) => string,
): UseUndoReturnType => {
  const [valueHistory, setValueHistory] = useState<string[]>(value !== undefined ? [value] : [""]);
  const [valueHistoryPos, setValueHistoryPos] = useState(0);

  const handleChange = (e: ChangeEvent<InputElements>): void => {
    const rawValue = e.target.value;

    const element = e.target;
    const caretPos = element.selectionStart || 0;
    const caretFromEndOffset = rawValue.length - caretPos;

    const valueToUse = transformValue !== undefined ? transformValue(rawValue) : rawValue;
    onChange(valueToUse);

    addChange(
      valueHistory,
      setValueHistory,
      valueHistoryPos,
      setValueHistoryPos,
      valueToUse,
    );

    window.requestAnimationFrame(() => {
      const newCaretPos = valueToUse.length - caretFromEndOffset;

      element.selectionStart = newCaretPos;
      element.selectionEnd = newCaretPos;
    });
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() === "z" && e.ctrlKey && !e.shiftKey) {
      e.preventDefault();

      undo(
        valueHistory,
        valueHistoryPos,
        setValueHistoryPos,
        onChange,
      );
    }
    if (
      (e.key.toLowerCase() === "z" && e.ctrlKey && e.shiftKey) ||
      (e.key.toLowerCase() === "y" && e.ctrlKey)
    ) {
      e.preventDefault();

      redo(
        valueHistory,
        valueHistoryPos,
        setValueHistoryPos,
        onChange,
      );
    }
  };

  const addNewValue = (newValue?: string): void => {
    if (newValue === valueHistory[valueHistoryPos]) {
      return;
    }
    addChange(
      valueHistory,
      setValueHistory,
      valueHistoryPos,
      setValueHistoryPos,
      newValue ?? "",
    );
  };

  return [handleChange, handleKeyDown, addNewValue];
};

export default useUndo;