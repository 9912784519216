import * as joi from "joiful";
import {
  combine,
  ok,
  Result,
} from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { getClassByClassName } from "../../shared/utils/classNameToConstructor";
import TextReportGenerator from "./generators/TextReportGenerator";

@serializableDecorator<ReportFormatConfig>()
class ReportFormatConfig {
  @joi.object().required()
  generator!: TextReportGenerator;

  @joi.array().items((j) => j.string())
  recipients!: string[];

  @joi.string().required()
  version?: string;

  @joi.boolean().required()
  sendable!: boolean;

  constructor(generator: TextReportGenerator, version: string | undefined, recipients: string[], sendable: boolean = true) {
    this.generator = generator;
    this.recipients = recipients;
    this.version = version;
    this.sendable = sendable;
  }

  static serialize(config: ReportFormatConfig): Result<Serialized, SerializationError> {
    return ok({
      recipients: config.recipients,
      version: config.version,
      sendable: config.sendable,
      generatorClassName: config.generator.constructor.name,
      __className: ReportFormatConfig.name,
    });
  }

  static deserialize(serialized: Serialized): Result<ReportFormatConfig, SerializationError> {
    const results = [
      getClassByClassName<TextReportGenerator>(serialized.generatorClassName, TextReportGenerator),
    ];
    return combine(results)
      .map(([generator]) => new ReportFormatConfig(
        generator,
        serialized.version,
        serialized.recipients,
        serialized.sendable,
      ));
  }
}

export default ReportFormatConfig;