import React from "react";
import { FormItemProvider } from "../../FormItemProvider";
import StatementOfFactActivity from "../../../../../domain/StatementOfFactActivity";
import StatementOfFactsActivitiesFormItem from "./StatementOfFactsActivitiesFormItem";

const StatementOfFactsActivitiesFormItemProvider: FormItemProvider<StatementOfFactActivity[]> = (props) => {
  const { settings: _settings } = props;

  return (
    <StatementOfFactsActivitiesFormItem
      {...props}
    />
  );
};

export default React.memo(StatementOfFactsActivitiesFormItemProvider);