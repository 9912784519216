import {
  err,
  ok,
  Result,
} from "neverthrow";
import React from "react";
import {
  FormItemProviderProps,
} from "../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../InputToResult";
import { eqeqeq } from "../../../../../shared/utils/equality";
import Port, {
  PortData,
  PortId,
} from "../../../../../domain/Port";
import GenericSelectFormItemProvider from "../_generic/select/GenericSelectFormItemProvider";
import ValidationError from "../../../../../domain/errors/ValidationError";
import ports from "../../../../../domain/ports.json";
import { Options } from "../../../Options";
import { FieldType } from "../../../../../domain/reports/FieldTypes";

const options: Options<Port> = [...ports]
  .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  .map((p) => {
    return ({
      value: Port.fromPortData(p),
      searchText: `${p.name} ${p.unlocode ?? ""}`,
      label: (
        <>
          <span>{p.name}</span>
          {p.unlocode !== undefined && p.unlocode !== "" && (
            <span
              style={{
                fontSize: "0.8em",
                color: "hsl(0deg 0% 60%)",
                marginLeft: "5px",
                fontStyle: "italic",
              }}
            >
              (UN/LOCODE: {p.unlocode})
            </span>
          )}
        </>
      ),
    });
  });

const inputToResult = (input: PortId): Result<Port, ValidationError[]> => {
  const portData: PortData | undefined = ports.find((p) => p.id === input);
  if (portData === undefined) {
    return err([new ValidationError(`No port found with id ${input}.`, { userFacing: false })]);
  }

  return ok(Port.fromPortData(portData));
};

const outputToInput = (port: Port): PortId => {
  return port.id;
};

type PortFormItemProviderProps = {
  fieldName: string,
  fieldType: FieldType,
};

type Props = FormItemProviderProps<Port> & PortFormItemProviderProps;

const PortFormItemProvider: React.FC<Props> = (props: Props) => (
  <GenericSelectFormItemProvider<number, Port>
    {...props}
    inputToResult={notEmptyInputToResultWrapper(props.fieldName, inputToResult)}
    outputToInput={outputToInput}
    inputsEqual={eqeqeq}
    options={options}
    fieldName={props.fieldName}
    fieldType={props.fieldType}
    searchable
    allowClear
  />
);

export default React.memo(PortFormItemProvider);
