import React, {
  useCallback,
  useEffect,
} from "react";
import {
  Button,
} from "antd";
import {
  err,
  ok,
  Result,
} from "neverthrow";
import {
  DateTime,
  FixedOffsetZone,
} from "luxon";
import type {
  FormItemProps,
} from "../../FormItem";

import {
  replaceAtIndex,
} from "../../../../../shared/utils/array";
import ValidationError from "../../../../../domain/errors/ValidationError";
import RequiredError from "../../../../../domain/errors/RequiredError";
import ErrorWrapper from "../../ErrorWrapper";

import { deepEqual } from "../../../../../shared/utils/equality";
import {
  useSingleFormItem,
  UseSingleFormItemHook,
} from "../../useSingleFormItem";
import { notEmptyInputToResultWrapper } from "../../InputToResult";
import logger from "../../../../../shared/utils/logger";
import DateString from "../_generic/date/DateString";
import TimeString from "../_generic/date/TimeString";
import StatementOfFactActivity from "../../../../../domain/StatementOfFactActivity";
import { newId } from "../../../../../domain/Id";
import ActivityInput from "./ActivityInput";
import StatementOfFactsActivityCard from "./StatementOfFactsActivityCard";

type Props = FormItemProps<StatementOfFactActivity[]>;

type Hook = UseSingleFormItemHook<ActivityInput[], StatementOfFactActivity[]> & {
  addItem: () => void,
  updateItemAtIndex: (item: Partial<ActivityInput>, index: number) => void,
};

/* ------------------------------------------------------------ */

const outputToInput = (value: StatementOfFactActivity[]): ActivityInput[] => value.map(
  (activityItem) => ({
    id: activityItem.id,
    type: activityItem.type,
    date: DateString.fromDateTime(activityItem.date).value,
    time: TimeString.fromDateTime(activityItem.date).value,
    utcOffset: FixedOffsetZone.instance(activityItem.date.offset).name,
    note: activityItem.note,
    dirty: { value: true },
  }),
);

/* ------------------------------------------------------------ */

const getInputToResult = (input: ActivityInput[]): Result<StatementOfFactActivity[], ValidationError[]> => {
  let activities: StatementOfFactActivity[] = [];

  for (const activityInput of (input ?? [])) {
    if (
      activityInput.type === undefined ||
      activityInput.date === undefined ||
      activityInput.time === undefined ||
      activityInput.utcOffset === undefined
    ) {
      return err([new RequiredError(
        "An activity entry is missing one or more fields, please check for errors.",
        { userFacing: true },
      )]);
    }
    const isoStr = `${activityInput.date}T${activityInput.time}`;
    const dateTime = DateTime.fromISO(isoStr, { zone: activityInput.utcOffset });
    if (!dateTime.isValid) {
      logger.warn(`Failed to convert ISO datetime string "${isoStr}" to DateTime.`);
      return err([new ValidationError(`Activity date is invalid.`, { userFacing: true })]);
    }

    activities = [
      ...activities,
      new StatementOfFactActivity(
        activityInput.id,
        activityInput.type,
        dateTime,
        activityInput.note,
      ),
    ];
  }

  const commencedLoadingCount = activities.filter((a) => a.type === "Commenced loading").length;
  const completedLoadingCount = activities.filter((a) => a.type === "Completed loading").length;
  const commencedDischargingCount = activities.filter((a) => a.type === "Commenced discharging").length;
  const completedDischargingCount = activities.filter((a) => a.type === "Completed discharging").length;

  if (commencedLoadingCount !== completedLoadingCount) {
    return err([new ValidationError(
      `You have entered ${commencedLoadingCount} "Commenced loading" ${commencedLoadingCount === 1 ? "activity" : "activities"}, ` +
      `but ${completedLoadingCount} "Completed loading" ${completedLoadingCount === 1 ? "activity" : "activities"}.`,
      { userFacing: true },
    )]);
  }

  if (commencedDischargingCount !== completedDischargingCount) {
    return err([new ValidationError(
      `You have entered ${commencedDischargingCount} "Commenced discharging" ${commencedDischargingCount === 1 ? "activity" : "activities"}, ` +
      `but ${completedDischargingCount} "Completed discharging" ${completedDischargingCount === 1 ? "activity" : "activities"}.`,
      { userFacing: true },
    )]);
  }

  return ok(activities);
};

const useActivityItemsFormItem = (props: Props): Hook => {
  const hook = useSingleFormItem<ActivityInput[], StatementOfFactActivity[]>({
    ...props,
    inputToResult: notEmptyInputToResultWrapper("Activity", getInputToResult),
    outputToInput,
    inputsEqual: deepEqual,
    dontAutoUpdateDirty: true,
  });

  useEffect(() => {
    hook._setInput((oldInput) => {
      if (oldInput === undefined) {
        return oldInput;
      }
      return oldInput.map((item) => ({ ...item, dirty: props.dirty }));
    });
  }, [props.dirty]);

  useEffect(() => {
    if (hook.input === undefined) {
      return;
    }

    const newInput = hook.input.map((item) => {
      const allFilled = (
        item.type !== undefined &&
        item.date !== undefined &&
        item.time !== undefined &&
        item.utcOffset !== undefined
      );
      return ({ ...item, dirty: item.dirty || { value: allFilled } });
    });

    if (!deepEqual(newInput, hook.input)) {
      hook.onInputChange(newInput);
    }
  }, [hook.input]);

  const updateItemAtIndex = useCallback(
    (updates: Partial<ActivityInput>, index: number) => {
      hook.onInputChange((prevInput) => {
        if (prevInput == null) {
          return prevInput;
        }

        const newItem = { ...prevInput[index], ...updates };

        return replaceAtIndex(prevInput, index, newItem as ActivityInput);
      });
    },
    [],
  );

  const addActivity = useCallback(
    (): void => {
      props.onDirtyChange({ value: false });
      hook.onInputChange((prevInput) => [
        ...(prevInput ?? []),
        {
          id: newId(),
          dirty: props.dirty ?? { value: false },
          type: undefined,
          date: undefined,
          time: undefined,
          note: undefined,
          utcOffset: undefined,
        },
      ]);
    },
    [
      props.dirty,
      props.onDirtyChange,
      hook.onInputChange,
    ],
  );

  return {
    ...hook,
    addItem: addActivity,
    updateItemAtIndex,
  };
};

/* ------------------------------------------------------------ */

const StatementOfFactsActivitiesFormItem = (props: Props): React.ReactElement => {
  const hook = useActivityItemsFormItem(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      displayRequiredErrors={false}
      errors={hook.error}
    >
      {hook.input?.map((activity, index) => (
        <StatementOfFactsActivityCard
          key={activity.id}
          {...activity}
          dirty={activity.dirty.value}
          index={index}
          updateItemAtIndex={hook.updateItemAtIndex}
          onInputChange={hook.onInputChange}
        />
      ))}
      <Button
        type="primary"
        onClick={hook.addItem}
      >
        Add new activity
      </Button>
    </ErrorWrapper>
  );
};

export default React.memo(StatementOfFactsActivitiesFormItem);