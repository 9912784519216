import { notification } from "antd";
import BaseError from "../../../shared/errors/BaseError";

export const showErrorNotification = (title: string, error: BaseError | string): void => {
  const defaultDesc = "An error occurred. Please try again later or contact support.";

  let description;

  if (typeof error === "string") {
    description = error;
  } else {
    description = error.userFacing
      ? error.message
      : defaultDesc;
  }

  notification.error({
    message: title,
    description,
    duration: null,
  });
};
