import BaseError, { BaseErrorProps } from "./BaseError";

class HttpStatusError extends BaseError {
  public readonly body?: any;

  constructor(
    public readonly statusCode: number,
    message: string,
    props?: BaseErrorProps & {
      body?: any,
    },
  ) {
    super(message, props);
    this.body = props?.body;
  }
}

export default HttpStatusError;