enum ReportType {
  NoonAtSea = "Noon at Sea",
  NoonInPort = "Noon in Port",
  Arrival = "Arrival",
  Departure = "Departure",
  Bunkering = "Bunkering",
  Delivery = "Delivery",
  Redelivery = "Redelivery",
  StatementOfFacts = "Statement of Facts",
  Berthing = "Berthing",
}

export default ReportType;