import {
  combine,
  err,
  ok,
  Result,
} from "neverthrow";
import localforage from "localforage";
import { nanoid } from "nanoid";
import { notification } from "antd";
import Report from "../../domain/reports/Report";
import { Id } from "../../domain/Id";
import BaseError from "../../shared/errors/BaseError";
import {
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import FetchErrors from "../../shared/errors/FetchErrors";
import { syncReports } from "./api";
import CloudSyncStatus from "../../domain/CloudSyncStatus";
import NetworkError from "../../shared/errors/NetworkError";
import { getLocalAuthPayload } from "./auth";
import { getCurrentTimestamp } from "../../shared/utils/date";
import { SIMPLE_MODE } from "./constants";
import ReportStatus from "../../domain/reports/ReportStatus";
import logger from "../../shared/utils/logger";

const SYNCED_AT_TIMESTAMP = "syncedAtTimestamp";
const REPORT_KEY_PREFIX = "report_";
const REPORT_ID_LIST = "reportIdList";

export class StorageError extends BaseError {}

const idToKey = (id: Id): string => {
  return `${REPORT_KEY_PREFIX}${id}`;
};

const _keyToId = (key: string): Id => {
  return key.replace(new RegExp(`^${REPORT_KEY_PREFIX}`), "");
};

/* ------------------------------------------------------------ */

type EventTypes =
  | "save"
  | "sync";

const eventSubscribers: {
  [key: string]: {
    [key: string]: () => void,
  },
} = {};

export const subscribeToStorageEvent = (event: EventTypes, callback: () => void): (() => void) => {
  const key = nanoid();
  if (eventSubscribers[event] === undefined) {
    eventSubscribers[event] = {};
  }
  eventSubscribers[event][key] = callback;
  const unsubscribe = (): void => {
    delete eventSubscribers[event][key];
  };
  return unsubscribe;
};

const notifySubscribers = (event: EventTypes): void => {
  const subscribers = eventSubscribers[event] ?? {};
  Object.values(subscribers)
    .forEach((callback) => callback());
};

/* ------------------------------------------------------------ */

export const getReportIds = async (): Promise<string[]> => await localforage.getItem(REPORT_ID_LIST) as string[] ?? [];

export const getSerializedReport = async (reportId: string): Promise<Serialized | null> => {
  return localforage.getItem(idToKey(reportId));
};

export const setSerializedReport = async (reportId: string, serializedReport: Serialized): Promise<void> => {
  await localforage.setItem(idToKey(reportId), serializedReport);
};

export const clearReports = async (): Promise<Result<null, StorageError>> => {
  try {
    await localforage.clear();
  } catch (error) {
    return err(new StorageError("Failed to clear reports.", { innerError: error }));
  }
  return ok(null);
};

export const getReport = async (id: Id): Promise<Result<Report, StorageError | SerializationError>> => {
  const serializedReport: Serialized | null = await getSerializedReport(id);
  if (serializedReport === null) {
    return err(new StorageError(`No report saved with id ${id}.`));
  }
  return Report.deserialize(serializedReport);
};

const _saveReport = async (report: Report): Promise<Result<null, StorageError | SerializationError>> => {
  const serializedResult = Report.serialize(report);
  if (serializedResult.isErr()) {
    return err(serializedResult.error);
  }
  const existingReportResult = await getReport(report.id);
  if (
    existingReportResult.isErr() ||
    existingReportResult.value.updatedAtTimestamp <= report.updatedAtTimestamp
  ) {
    await setSerializedReport(report.id, serializedResult.value);
    const existingIds = await getReportIds();
    if (!existingIds.some((id) => id === report.id)) {
      await localforage.setItem(REPORT_ID_LIST, [...existingIds, report.id]);
    }
  }
  return ok(null);
};

export const getAllReports = async (options: {
  newerThanTimestamp?: number,
  includeDeleted?: boolean,
} = {}): Promise<Result<Report[], StorageError>> => {
  const ids = await getReportIds();
  const isReportNewer = (r: Report): boolean => (
    options.newerThanTimestamp === undefined ||
    r.updatedAtTimestamp > options.newerThanTimestamp
  );

  const isReportNotDeleted = (r: Report): boolean => (
    options.includeDeleted || !r.deleted
  );

  const getReportPromises = ids.map(getReport);
  const getReportResults = await Promise.all(getReportPromises);

  return combine(getReportResults)
    .map((rs) => rs.filter(isReportNewer))
    .map((rs) => rs.filter(isReportNotDeleted));
};

export const getPreviousReport = async (thisReport?: Report): Promise<Result<Report | undefined, StorageError>> => {
  const allReportsResult = await getAllReports();
  if (allReportsResult.isErr()) {
    return err(allReportsResult.error);
  }
  const reports = allReportsResult.value;

  const previousReport = reports.reduce((acc: undefined | Report, r: Report) => {
    if (
      r.data.ReportDate !== undefined &&
      (
        acc?.data?.ReportDate === undefined ||
        acc.data.ReportDate < r.data.ReportDate
      ) &&
      (
        thisReport === undefined ||
        (
          thisReport.data.ReportDate !== undefined &&
          thisReport.data.ReportDate > r.data.ReportDate
        )
      )
    ) {
      return r;
    }
    return acc;
  }, undefined);

  return ok(previousReport);
};

type SyncError = StorageError | SerializationError | FetchErrors;

export const getSyncedAtTimestamp = async (): Promise<number | null> => {
  return localforage.getItem<number>(SYNCED_AT_TIMESTAMP);
};

export const setSyncedAtTimestamp = async (newTimestamp: number | null): Promise<void> => {
  await localforage.setItem(SYNCED_AT_TIMESTAMP, newTimestamp);
};

let syncInProgress = false;

export const syncWithServer = async (): Promise<Result<null, SyncError>> => {
  if (syncInProgress) {
    logger.warn("Aborting sync, already syncing.");
    return ok(null);
  }

  syncInProgress = true;

  try {
    const newSyncedAtTimestamp = getCurrentTimestamp();
    const oldSyncedAtTimestamp = await getSyncedAtTimestamp() ?? 0;

    const allReportsResult = await getAllReports({
      includeDeleted: true,
    });
    if (allReportsResult.isErr()) {
      return err(allReportsResult.error);
    }
    const allReports = allReportsResult.value;

    const newReportsFromClient = allReports.filter((r) => r.updatedAtTimestamp > oldSyncedAtTimestamp);

    const reportLastUpdatedAts = allReports.reduce((acc, r) => ({
      ...acc,
      [r.id]: r.updatedAtTimestamp,
    }), {});

    const syncReportsResult = await syncReports(
      {
        newReports: newReportsFromClient,
        reportLastUpdatedAts,
        imoNumber: getLocalAuthPayload()!.imoNumber,
      },
    );
    if (syncReportsResult.isErr()) {
      if (!(syncReportsResult.error instanceof NetworkError)) {
        const saveResults = [];
        for (const newReport of newReportsFromClient) {
          newReport.cloudStatus = CloudSyncStatus.Failed;
          const saveReportResult = await _saveReport(newReport);
          saveResults.push(saveReportResult);
        }
        const combinedSaveResults = combine(saveResults);
        if (combinedSaveResults.isErr()) {
          return err(combinedSaveResults.error);
        }
      }

      return err(syncReportsResult.error);
    }
    const newReportsFromServer = syncReportsResult.value;

    const newReportsOverwrittenByServer = newReportsFromClient
      .filter((cr) => newReportsFromServer.some((sr) => sr.id === cr.id));
    const newReportsNotOverwrittenByServer = newReportsFromClient
      .filter((cr) => !newReportsFromServer.some((sr) => sr.id === cr.id));

    const reportsToSave = [
      ...newReportsFromServer,
      ...newReportsNotOverwrittenByServer,
    ];
    const saveResults = [];
    for (const newReport of reportsToSave) {
      newReport.cloudStatus = CloudSyncStatus.Synced;
      const saveReportResult = await _saveReport(newReport);
      if (saveReportResult.isErr()) {
        return err(saveReportResult.error);
      }
      saveResults.push(saveReportResult);
    }
    const combinedSaveResults = combine(saveResults);
    if (combinedSaveResults.isErr()) {
      return err(combinedSaveResults.error);
    }

    await setSyncedAtTimestamp(newSyncedAtTimestamp);

    logger.debug(`Finished syncing.`, {
      "New synced timestamp": newSyncedAtTimestamp,
      "Old synced timestamp": oldSyncedAtTimestamp,
      "Local reports": allReports.length,
      "New local reports": newReportsFromClient.length,
      "New reports from server": newReportsFromServer.length,
      "New local reports overwritten by new server reports": newReportsOverwrittenByServer.length,
      "Saved reports": reportsToSave.length,
    });

    if (reportsToSave.length > 0) {
      notifySubscribers("sync");
    }

    const nonDraftReportsFromClient = newReportsFromClient.filter((r) => r.status !== ReportStatus.Draft);

    if (nonDraftReportsFromClient.length > 0 && SIMPLE_MODE) {
      const plural = nonDraftReportsFromClient.length > 1 ? "s" : "";
      let action;
      if (nonDraftReportsFromClient.every((r) => r.deleted)) {
        action = "deleted";
      } else {
        action = "saved";
      }
      notification.success({
        message: `Report${plural} synced to the cloud`,
        description: `Successfully ${action} ${nonDraftReportsFromClient.length} report${plural}.`,
      });
    }
  } finally {
    syncInProgress = false;
  }

  return ok(null);
};

export const saveReport = async (report: Report): Promise<Result<null, StorageError | SerializationError>> => {
  const result = await _saveReport(report);
  if (result.isOk()) {
    notifySubscribers("save");
  }
  return result;
};
