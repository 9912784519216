import {
  ok,
  Result,
} from "neverthrow";
import React, {
  useCallback,
} from "react";
import styled from "styled-components";
import { Col, Grid, Row } from "antd";
import {
  FormItemProviderProps,
} from "../../../FormItemProvider";
import {
  Outputs,
  useCompositeFormItem,
  UseCompositeFormItemProps,
} from "../../../useCompositeFormItem";
import ValidationError from "../../../../../../domain/errors/ValidationError";
import {
  FormItemComponent,
  FormItemProps,
} from "../../../FormItem";
import StandardInputContainer from "../../../StandardInputContainer";
import { FieldType } from "../../../../../../domain/reports/FieldTypes";
import {
  CargoQuantity,
} from "../../../../../../domain/units/CargoQuantity";
import { Quantity } from "../../../../../../domain/units/Quantity";
import QuantityInput from "./QuantityInput";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import UnitInput from "./UnitSelect";
import { Options } from "../../../../Options";
import CargoUnit from "../../../../../../domain/CargoUnit";
import identity from "../../../../../../shared/utils/identity";

type QuantityChildInputTypes = {
  quantity: {
    input: number,
    output: Quantity,
  },
  unit: {
    input: string,
    output: CargoUnit,
  }
};

type OutputQuantityTypes = Result<Outputs<QuantityChildInputTypes>, ValidationError[]>;

const getChildOutputsToResult = (
  fieldName: string,
): (outputs: Outputs<QuantityChildInputTypes>
  ) => OutputQuantityTypes => {
  return useCallback((outputs: Outputs<QuantityChildInputTypes>): OutputQuantityTypes => {
    return ok(outputs);
  }, [fieldName]);
};

const childOutputsEqual = (
  o1: Outputs<QuantityChildInputTypes>,
  o2: Outputs<QuantityChildInputTypes>,
): boolean => {
  return (
    o1.quantity === o2.quantity &&
    o1.unit === o2.unit
  );
};

type ignoredCompositeKeys =
  | "childOutputsToResult"
  | "outputToChildOutputs"
  | "childOutputsEqual"
  | "dataKeys";

type GenericQuantityFormItemProps =
  & Omit<FormItemProps<CargoQuantity>, "suggestions">
  & Omit<UseCompositeFormItemProps<
  QuantityChildInputTypes,
  QuantityChildInputTypes>,
  ignoredCompositeKeys
  >
  & {
    fieldName: string,
  };

const StyledQuantityFormItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div {
    flex: 1 1 0px;
    display: flex;
    flex-direction: row;
    min-height: 32px;
  }
  
  & > div > * {
    flex: 1 1 0px;
  }
  
  & > div > button {
    max-width: 125px;
  }

  & > div > :last-child {
    max-width: 100px;
    margin-left: 10px;
  }
`;

const options: Options<CargoUnit> = [
  {
    label: "Tons",
    value: CargoUnit.Tons,
  },
  {
    label: "Units",
    value: CargoUnit.Unit,
  },
  {
    label: "m³",
    value: CargoUnit.m3,
  },
  {
    label: "Barrels",
    value: CargoUnit.Barrels,
  },
];

function GenericQuantityFormItem(
  props: GenericQuantityFormItemProps,
): FormItemComponent<CargoQuantity> {
  const hook = useCompositeFormItem<QuantityChildInputTypes, CargoQuantity>({
    ...props,
    dataKeys: ["quantity", "unit"],
    childOutputsToResult: getChildOutputsToResult(props.fieldName),
    childOutputsEqual,
    outputToChildOutputs: identity,
  });

  const screens = Grid.useBreakpoint();

  const inputToResultQuantity = notEmptyInputToResultWrapper(
    "Quantity",
    (i: number) => ok(new Quantity(i)),
  );

  const inputToResultUnit = notEmptyInputToResultWrapper(
    "Unit",
    (i: CargoUnit) => ok(i),
  );

  return (
    <StandardInputContainer
      required={props.required}
      isOk={hook.isOk}
      isError={hook.isError}
      errors={hook.error}
      fieldName={props.fieldName}
    >
      {screens.xs ? (
        <Row>
          <Col span={24}>
            <QuantityInput
              {...hook.quantity}
              inputToResult={inputToResultQuantity}
              inputsEqual={eqeqeq}
              outputToInput={unwrapValue}
              required
            />
          </Col>
          <Col span={24}>
            <UnitInput
              {...hook.unit}
              inputToResult={inputToResultUnit}
              outputToInput={identity}
              inputsEqual={eqeqeq}
              required
              options={options}
            />
          </Col>
        </Row>
      ) : (
        <StyledQuantityFormItem>
          <div>
            <QuantityInput
              {...hook.quantity}
              inputToResult={inputToResultQuantity}
              inputsEqual={eqeqeq}
              outputToInput={unwrapValue}
              required
            />
            <UnitInput
              {...hook.unit}
              inputToResult={inputToResultUnit}
              outputToInput={identity}
              inputsEqual={eqeqeq}
              required
              options={options}
            />
          </div>
        </StyledQuantityFormItem>
      )}
    </StandardInputContainer>
  );
}

type GenericCargoQuantityFormItemProviderProps =
  & FormItemProviderProps<CargoQuantity>
  & Omit<UseCompositeFormItemProps<
  QuantityChildInputTypes,
  CargoQuantity>,
  ignoredCompositeKeys
  >
  & {
    suggestPrevious?: boolean,
    autoFillPrevious?: boolean,
    fieldName: string,
    fieldType: FieldType,
  };

function GenericQuantityFormItemProvider(
  props: GenericCargoQuantityFormItemProviderProps,
): React.ReactElement {
  return (
    <GenericQuantityFormItem
      {...props}
    />
  );
}

export default GenericQuantityFormItemProvider;