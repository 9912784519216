import {
  err,
  ok,
  Result,
} from "neverthrow";
import ReportData from "../ReportData";
import ReportOutput from "./ReportOutput";
import ValidationError from "../../errors/ValidationError";
import { DisplayedField } from "../DisplayedField";
import RequiredError from "../../errors/RequiredError";

abstract class ReportGenerator {
  protected constructor(
    public readonly name: string,
    public readonly fields: DisplayedField[],
    public readonly defaultVersion: string | undefined,
  ) {}

  abstract generate(data: ReportData, version: string | undefined): Result<ReportOutput, ValidationError>;

  protected checkRequiredFields(data: ReportData): Result<ReportData, ValidationError> {
    const missingFields = this.fields.filter((f) => (
      f.required &&
      data[f.fieldType] === undefined
    ));

    if (missingFields.length > 0) {
      return err(new ValidationError(
        "Report is missing one or more required fields. Please revise report.",
        {
          children: missingFields.map((field) => {
            return new RequiredError(`Field "${field.fieldType}" is missing.`, { userFacing: false });
          }),
        },
      ));
    }

    return ok(data);
  }
}

export default ReportGenerator;
