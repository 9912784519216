import React, {
  useEffect,
  useMemo,
} from "react";
import { ok } from "neverthrow";
import Suggestion from "../../../Suggestion";
import {
  useSingleFormItem,
  UseSingleFormItemProps,
} from "../../../useSingleFormItem";
import {
  FormItemComponent,
  FormItemProps,
} from "../../../FormItem";
import SuggestionsWrapper from "../../../SuggestionsWrapper";
import StandardInputContainer from "../../../StandardInputContainer";
import { FormItemProviderProps } from "../../../FormItemProvider";
import { FieldType } from "../../../../../../domain/reports/FieldTypes";
import SelectInput from "../../../SelectInput";
import { Options } from "../../../../Options";

type GenericSelectOptions<O> = {
  options: Options<O>,
  searchable?: boolean,
  allowClear?: boolean,
};

type GenericSelectFormItemProps<I extends string | number, O> =
  & FormItemProps<O>
  & UseSingleFormItemProps<I, O>
  & {
    fieldName: string,
  }
  & GenericSelectOptions<O>;

function GenericSelectFormItem<I extends string | number, O>(props: GenericSelectFormItemProps<I, O>): FormItemComponent<O> {
  const hook = useSingleFormItem<I, O>(props);

  return (
    <StandardInputContainer
      required={props.required}
      isOk={hook.isOk}
      isError={hook.isError}
      errors={hook.error}
      fieldName={props.fieldName}
    >
      <SuggestionsWrapper<O>
        pickSuggestion={hook.pickSuggestion}
        showSuggestions={hook.showSuggestions}
        suggestions={props.suggestions}
      >
        <SelectInput<I>
          options={props.options.map((o) => ({
            ...o,
            value: props.outputToInput(o.value),
          }))}
          searchable={props.searchable}
          value={hook.input}
          onChange={hook.onInputChange}
          placeholder={props.fieldName}
          isError={hook.isError}
          allowClear={props.allowClear}
        />
      </SuggestionsWrapper>
    </StandardInputContainer>
  );
}

type GenericSelectFormItemProviderProps<I extends string | number, O> =
  & FormItemProviderProps<O>
  & UseSingleFormItemProps<I, O>
  & GenericSelectOptions<O>
  & {
    suggestPrevious?: boolean,
    autoFillPrevious?: boolean,
    fieldName: string,
    fieldType: FieldType,
  }
  & (
    {
      suggestPrevious: true,
      outputToSuggestionText: (output: O) => string
    } |
    {
      suggestPrevious?: false,
      outputToSuggestionText?: (output: O) => string
    }
  );

function GenericSelectFormItemProvider<I extends string | number, O>(props: GenericSelectFormItemProviderProps<I, O>): React.ReactElement {
  const suggestions: Suggestion<O>[] = useMemo(() => {
    if (!props.suggestPrevious) {
      return [];
    }
    const potentialPrevValue = props.previousReport?.data?.[props.fieldType];
    if (potentialPrevValue === undefined || potentialPrevValue === null) {
      return [];
    }
    const prevValue: O = potentialPrevValue as unknown as O;
    return [{
      text: props.outputToSuggestionText(prevValue),
      value: prevValue,
    }];
  }, []);

  useEffect(() => {
    if (!props.autoFillPrevious) {
      return;
    }
    const potentialPrevValue = props.previousReport?.data?.[props.fieldType];
    if (
      potentialPrevValue === undefined ||
      potentialPrevValue === null ||
      (props.result?.isOk() && props.result.value !== undefined)
    ) {
      return;
    }
    const prevValue: O = potentialPrevValue as unknown as O;
    props.onResultChange(ok(prevValue));
  }, []);

  return (
    <GenericSelectFormItem
      suggestions={suggestions}
      {...props}
    />
  );
}

export default GenericSelectFormItemProvider;