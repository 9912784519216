import { ok } from "neverthrow";
import React from "react";
import LoadCondition from "../../../../../../domain/LoadCondition";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import identity from "../../../../../../shared/utils/identity";
import GenericSelectFormItemProvider from "../../_generic/select/GenericSelectFormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Next Voyage Condition";

const options = [
  {
    label: "Ballast",
    value: LoadCondition.Ballast,
  },
  {
    label: "Laden",
    value: LoadCondition.Laden,
  },
];

// TODO: Convert to radio buttons?
const NextVoyageConditionFormItemProvider: FormItemProvider<LoadCondition> = (props: FormItemProviderProps<LoadCondition>) => (
  <GenericSelectFormItemProvider<LoadCondition, LoadCondition>
    {...props}
    fieldName={fieldName}
    fieldType="LoadCondition"
    options={options}
    inputToResult={notEmptyInputToResultWrapper(fieldName, ok)}
    outputToInput={identity}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(NextVoyageConditionFormItemProvider);
