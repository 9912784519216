import express from "express";
import {
  ok,
  Result,
} from "neverthrow";

import BaseController from "./BaseController";
import BaseUseCase from "./BaseUseCase";

export class PingRequest {
}

export type PingResponse = null;

export type PingError = never;

/* ------------------------------------------------------------ */

export class PingUseCase extends BaseUseCase<PingRequest, PingResponse, PingError> {
  public execute = async (_input: PingRequest): Promise<Result<PingResponse, PingError>> => {
    return ok(null);
  };
}

/* ------------------------------------------------------------ */

export class PingController extends BaseController<PingRequest, PingResponse, PingError> {
  public constructor() {
    super(
      PingRequest,
      new PingUseCase(),
      {
        doNotLog: true,
      },
    );
  }

  protected createRequest = (_req: express.Request): Result<PingRequest, PingError> => {
    return ok({});
  };
}
