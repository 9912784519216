import { Button, Grid } from "antd";
import React from "react";
import { showErrorNotification } from "../utils/notifications";
import { logOut } from "../../logout";

const LogoutButton: React.FC = () => {
  const screens = Grid.useBreakpoint();
  const onLogOut = async (): Promise<void> => {
    try {
      await logOut();
    } catch (error) {
      showErrorNotification("Failed to log out.", error as Error);
    }
  };
  
  return (
    <Button
      onClick={onLogOut}
      type="link"
      size={screens.xs ? "small" : "middle"}
      style={{
        color: screens.xs ? "hsl(209, 100%, 25%)" : "white",
      }}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;