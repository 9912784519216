import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { CargoQuantity } from "../../../../../../domain/units/CargoQuantity";
import GenericQuantityFormItemProvider
  from "../../_generic/quantity/GenericQuantityFormItemProvider";

const fieldName = "Total loaded/discharged in last 24 hours";

const ToBeLoadedDischargedFormItemProvider: FormItemProvider<CargoQuantity> = (props: FormItemProviderProps<CargoQuantity>) => (
  <GenericQuantityFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="TotalLoadedDischargedDay"
  />
);

export default React.memo(ToBeLoadedDischargedFormItemProvider);
