import React from "react";
import styled from "styled-components";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { Radio } from "antd";
import Hemisphere from "../../../../../../../domain/position/Hemisphere";
import ErrorWrapper from "../../../../ErrorWrapper";
import {
  useSingleFormItem,
  UseSingleFormItemProps,
} from "../../../../useSingleFormItem";
import { FormItemProps } from "../../../../FormItem";
import { LatHemisphere } from "../../../../../../../domain/position/Latitude";
import { LongHemisphere } from "../../../../../../../domain/position/Longitude";

type Props<H extends LatHemisphere | LongHemisphere> =
  {
    hemisphereChoices: H[],
  }
  & UseSingleFormItemProps<Hemisphere, H>
  & FormItemProps<H>;

/* ------------------------------------------------------------ */

const RadioWrapper = styled.div`
  & > div {
    width: 100px;
    height: 32px;
    align-items: center;
  }
`;

function HemisphereInput<H extends LatHemisphere | LongHemisphere>(props: Props<H>): React.ReactElement {
  const hook = useSingleFormItem(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <RadioWrapper>
        <Radio.Group
          value={hook.input}
          onChange={(e: RadioChangeEvent): void => hook.onInputChange(e.target.value)}
          className={hook.isError ? "error" : undefined}
        >
          {props.hemisphereChoices.map((h: Hemisphere) => (
            <Radio key={h} value={h}>{h}</Radio>
          ))}
        </Radio.Group>
      </RadioWrapper>
    </ErrorWrapper>
  );
}

export default HemisphereInput;