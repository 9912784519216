import {
  err,
  ok,
  Result,
} from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import ValidationError from "../errors/ValidationError";
import Knots from "./Knots";

@serializableDecorator<Beaufort>()
class Beaufort {
  private constructor(
    public readonly value: number,
  ) {}

  public static fromNumber(input: number): Result<Beaufort, ValidationError[]> {
    if (!Number.isInteger(input)) {
      return err([new ValidationError("Beaufort scale must be a whole number.", { userFacing: true })]);
    }
    if (input < 0 || input > 12) {
      return err([new ValidationError(`Beaufort scale must be between 0 and 12.`, { userFacing: true })]);
    }
    return ok(new Beaufort(input));
  }
  
  public static fromKnots(knots: Knots): Beaufort {
    if (knots.value < 1) {
      return new Beaufort(0);
    } if (knots.value < 4) {
      return new Beaufort(1);
    } if (knots.value < 7) {
      return new Beaufort(2);
    } if (knots.value < 11) {
      return new Beaufort(3);
    } if (knots.value < 17) {
      return new Beaufort(4);
    } if (knots.value < 22) {
      return new Beaufort(5);
    } if (knots.value < 28) {
      return new Beaufort(6);
    } if (knots.value < 34) {
      return new Beaufort(7);
    } if (knots.value < 41) {
      return new Beaufort(8);
    } if (knots.value < 48) {
      return new Beaufort(9);
    } if (knots.value < 56) {
      return new Beaufort(10);
    } if (knots.value < 64) {
      return new Beaufort(11);
    } 
    return new Beaufort(12);
  }

  static serialize(obj: Beaufort): Result<Serialized, SerializationError> {
    return ok({ value: obj.value, __className: Beaufort.name });
  }

  static deserialize(serialized: Serialized): Result<Beaufort, SerializationError> {
    return ok(new Beaufort(serialized.value));
  }
}

export default Beaufort;