import {
  combineWithAllErrors,
  err,
  ok,
  Result,
} from "neverthrow";
import ValidationError from "../errors/ValidationError";
import Hemisphere from "./Hemisphere";
import SingleCoordinate, {
  DDM,
  DMS,
} from "./SingleCoordinate";
import { Degrees } from "../units/Degrees";
import { Seconds } from "../units/Seconds";
import { Minutes } from "../units/Minutes";

export type LatHemisphere = Hemisphere.N | Hemisphere.S;
export type LatDMS = DMS<LatHemisphere>;
export type LatDDM = DDM<LatHemisphere>;

class Latitude extends SingleCoordinate {
  public static validateHemisphere = (hemisphere: Hemisphere): Result<LatHemisphere, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (hemisphere !== Hemisphere.N && hemisphere !== Hemisphere.S) {
      errors.push(new ValidationError("Latitude hemisphere must be either N or S.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(hemisphere as LatHemisphere);
  };

  public static validateDecimalDegrees(decimalDegrees: Degrees): Result<Degrees, ValidationError[]> {
    const errors: ValidationError[] = [];
    if (
      decimalDegrees.value < -90 ||
      decimalDegrees.value > +90
    ) {
      errors.push(new ValidationError("Latitude must be between -90° and +90°.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(decimalDegrees);
  }

  /* ------------------------------------------------------------ */

  public static fromDecimalDegrees(decimalDegrees: Degrees): Result<Latitude, ValidationError[]> {
    return Latitude.validateDecimalDegrees(decimalDegrees)
      .map((dd: Degrees) => new Latitude(dd));
  }

  public static validateDMSDegrees = (degrees: Degrees): Result<Degrees, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (degrees.value < 0) {
      errors.push(new ValidationError("Latitude degrees must be 0° or above.", { userFacing: true }));
    }
    if (degrees.value >= 90) {
      errors.push(new ValidationError("Latitude degrees must be below 90°.", { userFacing: true }));
    }
    if (!Number.isInteger(degrees.value)) {
      errors.push(new ValidationError("Latitude degrees must be a whole number.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(degrees);
  };

  /* ------------------------------------------------------------ */

  public static validateDMSMinutes = (minutes: Minutes): Result<Minutes, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (minutes.value < 0) {
      errors.push(new ValidationError("Latitude minutes must be 0 or above.", { userFacing: true }));
    }
    if (minutes.value >= 60) {
      errors.push(new ValidationError("Latitude minutes must be below 60.", { userFacing: true }));
    }
    if (!Number.isInteger(minutes.value)) {
      errors.push(new ValidationError("Latitude minutes must be a whole number.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(minutes);
  };

  public static validateDMSSeconds = (seconds: Seconds): Result<Seconds, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (seconds.value < 0) {
      errors.push(new ValidationError("Latitude seconds must be 0 or above.", { userFacing: true }));
    }
    if (seconds.value >= 60) {
      errors.push(new ValidationError("Latitude seconds must be below 60.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(seconds);
  };

  public static fromDegreesMinutesSeconds(
    dms: LatDMS,
  ): Result<Latitude, ValidationError[]> {
    const validationResults: [
      Result<Degrees, ValidationError[]>,
      Result<Minutes, ValidationError[]>,
      Result<Seconds, ValidationError[]>,
      Result<Hemisphere, ValidationError[]>,
    ] = [
      this.validateDMSDegrees(dms?.degrees),
      this.validateDMSMinutes(dms?.minutes),
      this.validateDMSSeconds(dms?.seconds),
      this.validateHemisphere(dms?.hemisphere),
    ];

    return combineWithAllErrors(validationResults)
      .map(([
        degrees,
        minutes,
        seconds,
        hemisphere,
      ]) => {
        const decimalDegrees = (
          degrees.value
          + (minutes.value / 60)
          + (seconds.value / (60 * 60))
        ) * (hemisphere === Hemisphere.N ? 1 : -1);

        return new Latitude(new Degrees(decimalDegrees));
      })
      .mapErr((errors) => errors.flat());
  }

  public static validateDDMDegrees = (degrees: Degrees): Result<Degrees, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (degrees.value < 0) {
      errors.push(new ValidationError("Latitude degrees must be 0° or above.", { userFacing: true }));
    }
    if (degrees.value >= 90) {
      errors.push(new ValidationError("Latitude degrees must be below 90°.", { userFacing: true }));
    }
    if (!Number.isInteger(degrees.value)) {
      errors.push(new ValidationError("Latitude degrees must be a whole number.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(degrees);
  };

  public static validateDDMMinutes = (minutes: Minutes): Result<Minutes, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (minutes.value < 0) {
      errors.push(new ValidationError("Latitude minutes must be 0 or above.", { userFacing: true }));
    }
    if (minutes.value >= 60) {
      errors.push(new ValidationError("Latitude minutes must be below 60.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(minutes);
  };

  /* ------------------------------------------------------------ */

  public static fromDegreesDecimalMinutes(
    dms: LatDDM,
  ): Result<Latitude, ValidationError[]> {
    const validationResults: [
      Result<Degrees, ValidationError[]>,
      Result<Minutes, ValidationError[]>,
      Result<Hemisphere, ValidationError[]>,
    ] = [
      this.validateDDMDegrees(dms?.degrees),
      this.validateDDMMinutes(dms?.minutes),
      this.validateHemisphere(dms?.hemisphere),
    ];

    return combineWithAllErrors(validationResults)
      .map(([
        degrees,
        minutes,
        hemisphere,
      ]) => {
        const decimalDegrees = (
          degrees.value
          + (minutes.value / 60)
        ) * (hemisphere === Hemisphere.N ? 1 : -1);

        return new Latitude(new Degrees(decimalDegrees));
      })
      .mapErr((errors) => errors.flat());
  }

  public toDegreesDecimalMinutes(): LatDDM {
    return super.toDegreesDecimalMinutes() as LatDDM;
  }

  public toDegreesMinuteSeconds(): LatDMS {
    return super.toDegreesMinuteSeconds() as LatDMS;
  }

  protected getHemisphere(): LatHemisphere {
    return this.decimalDegrees.value >= 0 ? Hemisphere.N : Hemisphere.S;
  }
}

export default Latitude;