import React from "react";
import { FormItemProvider } from "../../FormItemProvider";
import ConsumptionItem from "../../../../../domain/ConsumptionItem";
import ConsumptionItemsFormItem from "./ConsumptionItemsFormItem";
import FuelType from "../../../../../domain/FuelType";

import EngineType from "../../../../../domain/EngineType";
import ConsumptionReason from "../../../../../domain/ConsumptionReason";

const ConsumptionItemsSOSPFormItemProvider: FormItemProvider<ConsumptionItem[]> = (props) => {
  const { settings } = props;
  const {
    requiredConsumptionEngineTypes,
  } = settings;

  return (
    <ConsumptionItemsFormItem
      {...props}
      fuelTypes={Object.values(FuelType)}
      reasonTypes={Object.values(ConsumptionReason)}
      engineTypes={Object.values(EngineType)}
      requiredConsumptionEngineTypes={requiredConsumptionEngineTypes}
      showConsumptionEngine={settings.showConsumptionEngine ?? false}
      showConsumptionReason={settings.showConsumptionReason ?? false}
      showConsumptionTime={settings.showConsumptionTime ?? false}
      title="Consumption since SOSP"
    />
  );
};

export default React.memo(ConsumptionItemsSOSPFormItemProvider);
