import {
  err,
  ok,
  Result,
} from "neverthrow";
import EngineType from "./EngineType";
import FuelType from "./FuelType";
import ConsumptionReason from "./ConsumptionReason";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../shared/utils/serialization";
import { Hours } from "./units/Hours";
import { Tons } from "./units/Tons";
import { Id } from "./Id";

@serializableDecorator<ConsumptionItem>()
class ConsumptionItem {
  public constructor(
    public readonly id: Id,
    public readonly fuel: FuelType,
    public readonly consumption: Tons,
    public readonly engine?: EngineType,
    public readonly reason?: ConsumptionReason,
    public readonly time?: Hours,
  ) {}

  static serialize(obj: ConsumptionItem): Result<Serialized, SerializationError> {
    const timeResult = obj.time !== undefined ? Hours.serialize(obj.time) : undefined;
    const consResult = Tons.serialize(obj.consumption);

    if (timeResult?.isErr()) {
      return timeResult;
    }
    if (consResult === undefined) {
      return err(new SerializationError("Consumption is empty."));
    }
    if (!consResult.isOk()) {
      return consResult;
    }

    return ok({
      id: obj.id,
      fuel: obj.fuel,
      engine: obj.engine,
      reason: obj.reason,
      time: timeResult?.isOk() ? timeResult.value : undefined,
      consumption: consResult.value,
      __className: ConsumptionItem.name,
    });
  }

  static deserialize(serialized: Serialized): Result<ConsumptionItem, SerializationError> {
    const timeResult = serialized.time !== undefined ? Hours.deserialize(serialized.time) : undefined;
    const consResult = Tons.deserialize(serialized.consumption);

    if (timeResult?.isErr()) {
      return err(timeResult.error);
    }
    if (consResult === undefined) {
      return err(new SerializationError("Consumption is empty."));
    }
    if (!consResult.isOk()) {
      return err(consResult.error);
    }

    return ok(new ConsumptionItem(
      serialized.id,
      serialized.fuel,
      consResult.value,
      serialized.engine,
      serialized.reason,
      timeResult?.isOk() ? timeResult.value : undefined,
    ));
  }
}

export default ConsumptionItem;