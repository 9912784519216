import {
  Card,
  DatePicker,
  Grid,
  Input,
  Popconfirm,
  Select,
  Space,
  TimePicker,
} from "antd";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import moment from "moment/moment";
import React, { SetStateAction } from "react";
import styled from "styled-components";
import { removeAtIndex } from "../../../../../shared/utils/array";
import IconButton from "../../../misc-components/IconButton";
import {
  STATEMENT_OF_FACTS_ACTIVITY_TYPE_ARRAY,
  StatementOfFactActivityType,
} from "../../../../../domain/StatementOfFactActivity";
import { toMomentInput } from "../../../utils/toMomentInput";
import { UTC_OFFSETS } from "../_generic/date/utcOffsets";
import ActivityInput from "./ActivityInput";

type StatementOfFactsActivityCardProps = {
  index: number,
  updateItemAtIndex: (activity: Partial<ActivityInput>, index: number) => void,
  onInputChange: (newInput: (
    | ActivityInput[]
    | undefined
    | SetStateAction<ActivityInput[] | undefined>
  )) => void,
} & (Omit<ActivityInput, "dirty"> & { dirty: boolean });

const InputWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
  }

  & > label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  & > div {
    display: flex;
  }

  & > div > * {
    flex: 1;
  }

  & > div > :not(:last-child) {
    margin-right: 10px;
  }
`;

const InputRow = styled.div`
  & {
    display: flex;
  }
  
  & > :not(:last-child) {
    margin-right: 20px;
  }
  
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const StatementOfFactsActivityCard = (props: StatementOfFactsActivityCardProps) => {
  const {
    index,
    updateItemAtIndex,
    onInputChange,
    ...activity
  } = props;
  const screens = Grid.useBreakpoint();

  return (
    <Card
      title={activity.type == null ? "Activity" : `Activity - ${activity.type}`}
      style={{
        backgroundColor: "hsla(0, 0%, 0%, 0.01)",
        marginBottom: "20px",
      }}
      extra={(
        <Popconfirm
          title="Really delete this entry?"
          onConfirm={(): void => {
            onInputChange((prevInput) => {
              if (prevInput !== undefined) {
                return removeAtIndex(prevInput, index);
              }
              return undefined;
            });
          }}
          okText="Yes"
          cancelText="No"
        >
          <IconButton
            danger={true}
            icon={faTrash}
            type="link"
          />
        </Popconfirm>
      )}
    >
      <InputRow>
        <Space direction="vertical">
          <InputWrapper style={{ width: screens.xs ? "100%" : "250px" }}>
            <label>Activity Type</label>
            <div>
              <Select
                showSearch
                className={activity.dirty && activity.type === undefined ? "error" : undefined}
                defaultValue={activity.type}
                placeholder="Type"
                onChange={(newType: StatementOfFactActivityType): void => {
                  updateItemAtIndex({
                    type: newType,
                  }, index);
                }}
              >
                {
                  STATEMENT_OF_FACTS_ACTIVITY_TYPE_ARRAY.map((t) => (
                    <Select.Option
                      value={t}
                      key={t}
                    >
                      {t}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
          </InputWrapper>
          <InputWrapper style={{ flex: 1 }}>
            <label>Date / Time</label>
            <div>
              <DatePicker
                placeholder="YYYY-MM-DD"
                className={activity.dirty && activity.date === undefined ? "error" : undefined}
                onChange={(date, newDateString): void => {
                  if (date == null) {
                    onInputChange(undefined);
                  } else {
                    updateItemAtIndex({
                      date: newDateString,
                    }, index);
                  }
                }}
                format="YYYY-MM-DD"
                defaultValue={toMomentInput(activity.date, "YYYY-MM-DD")}
              />
              <TimePicker
                placeholder="HH:MM"
                className={activity.dirty && activity.time === undefined ? "error" : undefined}
                onChange={(time, newTimeString): void => {
                  updateItemAtIndex({
                    time: newTimeString,
                  }, index);
                }}
                format="HH:mm"
                defaultValue={toMomentInput(activity.time, "HH:mm")}
                defaultOpenValue={moment("12:00", "HH:mm")}
              />
              <Select
                defaultValue={activity.utcOffset}
                onChange={(offset) => {
                  updateItemAtIndex({
                    utcOffset: offset,
                  }, index);
                }}
                className={activity.dirty && activity.utcOffset === undefined ? "error" : undefined}
                placeholder="UTC Offset"
                options={UTC_OFFSETS.map((offset) => ({ value: offset.name, label: offset.name }))}
                filterOption={(input, option): boolean => option!.value.toLowerCase().includes(input.toLowerCase())}
              />
            </div>
          </InputWrapper>
        </Space>
      </InputRow>
      <InputRow>
        <InputWrapper style={{ flex: 1 }}>
          <label>Note</label>
          <div>
            <Input.TextArea
              value={activity.note}
              placeholder="Note"
              onChange={(e): void => {
                updateItemAtIndex({
                  note: e.target.value,
                }, index);
              }}
            />
          </div>
        </InputWrapper>
      </InputRow>
    </Card>
  );
};

export default React.memo(StatementOfFactsActivityCard);