import React from "react";
import { FormItemProvider } from "../../../FormItemProvider";
import BunkerItem from "../../../../../../domain/ROBItem";
import FuelType from "../../../../../../domain/FuelType";
import BunkersReceivedFormItem from "./BunkersReceivedFormItem";

const BunkersReceivedFormItemProvider: FormItemProvider<BunkerItem[]> = (props) => {
  const { settings } = props;
  const { requiredROBFuelTypes } = settings;

  return (
    <BunkersReceivedFormItem
      {...props}
      fuelTypes={Object.values(FuelType)}
      requiredBunkerFuelTypes={requiredROBFuelTypes}
    />
  );
};

export default React.memo(BunkersReceivedFormItemProvider);