import jwt_decode from "jwt-decode";
import { AuthPayload } from "../../shared/types/AuthPayload";

const AUTH_TOKEN_KEY = "AUTH_TOKEN";

export const getLocalAuthToken = (): string | null => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
};

export const setLocalAuthToken = (authToken: string): void => {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

export const clearLocalAuthToken = (): void => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

export const getLocalAuthPayload = (): AuthPayload | null => {
  const token = getLocalAuthToken();
  if (token === null) {
    return null;
  }
  return jwt_decode(token);
};