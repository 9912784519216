import {
  combine,
  ok,
  Result,
} from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { Quantity } from "./Quantity";
import CargoUnit from "../CargoUnit";

@serializableDecorator<CargoQuantity>()
export class CargoQuantity {
  public constructor(
    public readonly quantity: Quantity,
    public readonly unit: CargoUnit,
  ) {}

  static serialize(obj: CargoQuantity): Result<Serialized, SerializationError> {
    const results = [
      Quantity.serialize(obj.quantity),
    ];
    return combine(results)
      .andThen(([quantity]) => ok({
        unit: obj.unit,
        quantity,
        __className: CargoQuantity.name,
      }));
  }

  static deserialize(serialized: Serialized): Result<CargoQuantity, SerializationError> {
    const results = [
      Quantity.deserialize(serialized.quantity),
    ];
    return combine(results)
      .andThen(([quantity]) => ok(new CargoQuantity(
        quantity,
        serialized.unit,
      )));
  }
}