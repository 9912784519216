import React, {
  useCallback,
  useEffect,
} from "react";
import styled from "styled-components";
import {
  Grid,
  Popconfirm,
  Select,
} from "antd";
import {
  err,
  ok,
  Result,
} from "neverthrow";
import { ColumnsType } from "antd/lib/table/interface";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import {
  DirtyInfo,
  FormItemProps,
} from "../../FormItem";
import ROBItem from "../../../../../domain/ROBItem";

import {
  removeAtIndex,
  replaceAtIndex,
} from "../../../../../shared/utils/array";
import IconButton from "../../../misc-components/IconButton";
import NumericInput from "../../../misc-components/NumericInput";
import ValidationError from "../../../../../domain/errors/ValidationError";
import RequiredError from "../../../../../domain/errors/RequiredError";
import ErrorWrapper from "../../ErrorWrapper";
import FuelType, { fuelTypeOptions } from "../../../../../domain/FuelType";
import {
  Id,
  newId,
} from "../../../../../domain/Id";
import { deepEqual } from "../../../../../shared/utils/equality";
import { Tons } from "../../../../../domain/units/Tons";
import {
  useSingleFormItem,
  UseSingleFormItemHook,
} from "../../useSingleFormItem";
import { notEmptyInputToResultWrapper } from "../../InputToResult";
import RequiredStar from "../../../misc-components/RequiredStar";
import Table from "../../../misc-components/Table";

type ROBItemInput = {
  id: Id,
  dirty: DirtyInfo
  quantity?: Tons,
  fuel?: FuelType,
};

type Props = FormItemProps<ROBItem[]> & {
  fuelTypes: FuelType[],
  requiredROBFuelTypes?: FuelType[],
};

type Hook = UseSingleFormItemHook<ROBItemInput[], ROBItem[]> & {
  addROBItem: () => void,
  tableColumns: ColumnsType<ROBItemInput>,
};

/* ------------------------------------------------------------ */

const outputToInput = (value: ROBItem[]): ROBItemInput[] => {
  return value.map((consItem) => ({
    ...consItem,
    dirty: { value: true },
  }));
};

const getInputToResult = (
  anyRequired: boolean,
  requiredROBFuelTypes: FuelType[] | undefined,
): ((input: ROBItemInput[]) => Result<ROBItem[], ValidationError[]>
  ) => {
  return useCallback((input) => {
    if ((input === undefined || input.length === 0) && !anyRequired) {
      return ok([]);
    }
    const missingTypes = (requiredROBFuelTypes ?? []).filter((rft) => !(input ?? []).some((robii) => robii.fuel === rft));
    if (missingTypes.length > 0) {
      return err([new ValidationError(
        `Missing ROB entries for the following fuel types: ${missingTypes.join(", ")}`,
        { userFacing: true },
      )]);
    }
    let robItems: ROBItem[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const robInputItem of (input ?? [])) {
      // TODO: This error handling is kinda dumb, not very specific.
      if (
        robInputItem.fuel === undefined ||
        // robInputItem.sulphurContent === undefined ||
        robInputItem.quantity === undefined
      ) {
        return err([new RequiredError(
          "A ROB entry is missing one or more fields, please check for errors.",
          { userFacing: true },
        )]);
      }
      robItems = [
        ...robItems,
        {
          id: robInputItem.id,
          fuel: robInputItem.fuel,
          // sulphurContent: robInputItem.sulphurContent,
          quantity: robInputItem.quantity,
        },
      ];
    }
    return ok(robItems);
  }, [anyRequired, requiredROBFuelTypes]);
};

/* ------------------------------------------------------------ */

const useROBItemsFormItem = (props: Props): Hook => {
  const {
    requiredROBFuelTypes,
  } = props;

  const anyRequired = (
    props.required &&
    requiredROBFuelTypes !== undefined &&
    requiredROBFuelTypes.length > 0
  );

  const hook = useSingleFormItem<ROBItemInput[], ROBItem[]>({
    ...props,
    inputToResult: notEmptyInputToResultWrapper(
      "Consumption Items",
      getInputToResult(anyRequired, requiredROBFuelTypes),
    ),
    outputToInput,
    inputsEqual: deepEqual,
    dontAutoUpdateDirty: true,
  });

  useEffect(() => {
    const initialInput: ROBItemInput[] | undefined = anyRequired
      ? requiredROBFuelTypes!.map((fuel) => ({
        id: newId(),
        fuel,
        dirty: { value: false },
      }))
      : undefined;
    hook.onInputChange(initialInput);
  }, []);

  useEffect(() => {
    hook._setInput((oldInput) => {
      if (oldInput === undefined) {
        return oldInput;
      }
      return oldInput.map((item) => ({ ...item, dirty: props.dirty }));
    });
  }, [props.dirty]);

  useEffect(() => {
    if (hook.input === undefined) {
      return;
    }
    const newInput = hook.input.map((item) => {
      const allFilled = (
        item.quantity !== undefined &&
        item.fuel !== undefined
      );
      return ({ ...item, dirty: item.dirty || { value: allFilled } });
    });
    if (!deepEqual(newInput, hook.input)) {
      hook.onInputChange(newInput);
    }
  }, [hook.input]);

  const addROBItem = (): void => {
    props.onDirtyChange({ value: false });
    hook.onInputChange([
      ...(hook.input ?? []),
      {
        id: newId(),
        dirty: props.dirty ?? { value: false },
      },
    ]);
  };

  const renderFuel = (
    fuel: FuelType | undefined,
    item: ROBItemInput,
    index: number,
  ): React.ReactNode => (
    <Select
      className={item.dirty.value && fuel === undefined ? "error" : undefined}
      value={fuel}
      placeholder="Fuel Grade"
      onChange={(newFuel: FuelType): void => {
        const newItem = {
          ...item,
          fuel: newFuel,
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}>
      { Object.entries(fuelTypeOptions).map(([groupLabel, groupOptions]) => (
        <Select.OptGroup
          label={groupLabel}
          key={groupLabel}
        >
          {
            groupOptions.map((option) => (
              <Select.Option
                value={option.value}
                key={option.label}
              >
                {option.label}
              </Select.Option>
            ))
          }
        </Select.OptGroup>
      ))}
    </Select>
  );

  const renderQuantity = (quantity: Tons | undefined, item: ROBItemInput, index: number): React.ReactNode => (
    <NumericInput
      className={item.dirty.value && quantity === undefined ? "error" : undefined}
      value={quantity?.value}
      addonAfter="tons"
      allowNegative={false}
      separateThousands={true}
      placeholder="Quantity"
      onChange={(newQuantity: number | undefined): void => {
        const newItem = {
          ...item,
          quantity: newQuantity === undefined ? undefined : new Tons(newQuantity),
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}
    />
  );

  const renderDelete = (_: unknown, item: ROBItemInput, index: number): React.ReactNode => (
    <Popconfirm
      title="Really delete this entry?"
      onConfirm={(): void => {
        const newInput = hook.input !== undefined
          ? removeAtIndex(hook.input, index)
          : undefined;
        hook.onInputChange(newInput);
      }}
      okText="Yes"
      cancelText="No"

    >
      <IconButton
        danger={true}
        icon={faTrash}
        type="link"
      />
    </Popconfirm>
  );

  const tableColumns: ColumnsType<ROBItemInput> = [
    {
      title: "Fuel Grade",
      dataIndex: "fuel",
      render: renderFuel,
      width: "30%",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: renderQuantity,
      width: "30%",
    },
    {
      title: "",
      render: renderDelete,
      width: "10%",
    },
  ];

  return {
    ...hook,
    addROBItem,
    tableColumns,
  };
};

/* ------------------------------------------------------------ */

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  & td > * {
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: flex-end;
  
  & > h3 {
    flex: 1 1 0px;
    margin: 0;
  }
  
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const ROBItemsFormItem = (props: Props): React.ReactElement => {
  const hook = useROBItemsFormItem(props);
  const screens = Grid.useBreakpoint();
  return (
    <ErrorWrapper
      isError={hook.isError}
      displayRequiredErrors={false}
      errors={hook.error}
    >
      <TableWrapper>
        <ButtonsWrapper>
          <h3>ROB{props.required && <RequiredStar />}</h3>
          <IconButton
            icon={faPlus}
            type="primary"
            onClick={hook.addROBItem}
          >
            Add entry
          </IconButton>
        </ButtonsWrapper>
        <Table
          columns={hook.tableColumns}
          dataSource={hook.input}
          useCards={screens.xs}
          pagination={false}
          rowKey="id"
          className={(hook.isError && hook.error === undefined) ? "error" : undefined}
          locale={{
            emptyText: "No ROB entries added",
          }}
          tableLayout="fixed"
        />
      </TableWrapper>
    </ErrorWrapper>
  );
};

export default ROBItemsFormItem;