import {
  err,
  ok,
  Result,
} from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import ValidationError from "../errors/ValidationError";

@serializableDecorator<DouglasSeaScale>()
class DouglasSeaScale {
  private constructor(
    public readonly value: number,
  ) {}

  public static fromNumber(input: number): Result<DouglasSeaScale, ValidationError[]> {
    if (!Number.isInteger(input)) {
      return err([new ValidationError("Douglas sea scale must be a whole number.", { userFacing: true })]);
    }
    if (input < 0 || input > 9) {
      return err([new ValidationError(`Douglas sea scale must be between 0 and 9.`, { userFacing: true })]);
    }
    return ok(new DouglasSeaScale(input));
  }

  static serialize(obj: DouglasSeaScale): Result<Serialized, SerializationError> {
    return ok({ value: obj.value, __className: DouglasSeaScale.name });
  }

  static deserialize(serialized: Serialized): Result<DouglasSeaScale, SerializationError> {
    return ok(new DouglasSeaScale(serialized.value));
  }
}

export default DouglasSeaScale;
