import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as setupServiceWorker from "./service-worker/setupServiceWorker";
import "../../domain/reports/generators/__side-effects";

const element = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

const container = document.getElementById("root");

ReactDOM.render(element, container);

setupServiceWorker.register();
