import {
  combineWithAllErrors,
  err,
  ok,
  Result,
} from "neverthrow";
import ValidationError from "../errors/ValidationError";
import Hemisphere from "./Hemisphere";
import SingleCoordinate, {
  DDM,
  DMS,
} from "./SingleCoordinate";
import { Degrees } from "../units/Degrees";
import { Seconds } from "../units/Seconds";
import { Minutes } from "../units/Minutes";

export type LongHemisphere = Hemisphere.E | Hemisphere.W;
export type LongDMS = DMS<LongHemisphere>;
export type LongDDM = DDM<LongHemisphere>;

class Longitude extends SingleCoordinate {
  public static validateHemisphere = (hemisphere: Hemisphere): Result<LongHemisphere, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (hemisphere !== Hemisphere.E && hemisphere !== Hemisphere.W) {
      errors.push(new ValidationError("Longitude hemisphere must be either E or W.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(hemisphere as LongHemisphere);
  };

  public static validateDecimalDegrees(decimalDegrees: Degrees): Result<Degrees, ValidationError[]> {
    const errors: ValidationError[] = [];
    if (decimalDegrees.value < -180) {
      errors.push(new ValidationError("Longitude degrees must be above -180°.", { userFacing: true }));
    }
    if (decimalDegrees.value > +180) {
      errors.push(new ValidationError("Longitude degrees must be below 180°.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(decimalDegrees);
  }

  /* ------------------------------------------------------------ */

  public static fromDecimalDegrees(decimalDegrees: Degrees): Result<Longitude, ValidationError[]> {
    return Longitude.validateDecimalDegrees(decimalDegrees)
      .map((dd: Degrees) => new Longitude(dd));
  }

  public static validateDMSDegrees = (degrees: Degrees): Result<Degrees, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (degrees.value < 0) {
      errors.push(new ValidationError("Longitude degrees must be 0° or above.", { userFacing: true }));
    }
    if (degrees.value >= 180) {
      errors.push(new ValidationError("Longitude degrees must be below 180°.", { userFacing: true }));
    }
    if (!Number.isInteger(degrees.value)) {
      errors.push(new ValidationError("Longitude degrees must be a whole number.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(degrees);
  };

  /* ------------------------------------------------------------ */

  public static validateDMSMinutes = (minutes: Minutes): Result<Minutes, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (minutes.value < 0) {
      errors.push(new ValidationError("Longitude minutes must be 0 or above.", { userFacing: true }));
    }
    if (minutes.value >= 60) {
      errors.push(new ValidationError("Longitude minutes must be below 60.", { userFacing: true }));
    }
    if (!Number.isInteger(minutes.value)) {
      errors.push(new ValidationError("Longitude minutes must be a whole number.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(minutes);
  };

  public static validateDMSSeconds = (seconds: Seconds): Result<Seconds, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (seconds.value < 0) {
      errors.push(new ValidationError("Longitude seconds must be 0 or above.", { userFacing: true }));
    }
    if (seconds.value >= 60) {
      errors.push(new ValidationError("Longitude seconds must be below 60.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(seconds);
  };

  public static fromDegreesMinutesSeconds(
    dms: LongDMS,
  ): Result<Longitude, ValidationError[]> {
    const validationResults: [
      Result<Degrees, ValidationError[]>,
      Result<Minutes, ValidationError[]>,
      Result<Seconds, ValidationError[]>,
      Result<Hemisphere, ValidationError[]>,
    ] = [
      this.validateDMSDegrees(dms?.degrees),
      this.validateDMSMinutes(dms?.minutes),
      this.validateDMSSeconds(dms?.seconds),
      this.validateHemisphere(dms?.hemisphere),
    ];
    return combineWithAllErrors(validationResults)
      .map(([
        degrees,
        minutes,
        seconds,
        hemisphere,
      ]) => {
        const decimalDegrees = (
          degrees.value
          + (minutes.value / 60)
          + (seconds.value / (60 * 60))
        ) * (hemisphere === Hemisphere.E ? 1 : -1);

        return new Longitude(new Degrees(decimalDegrees));
      })
      .mapErr((errors) => errors.flat());
  }

  public static validateDDMDegrees = (degrees: Degrees): Result<Degrees, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (degrees.value < 0) {
      errors.push(new ValidationError("Longitude degrees must be 0° or above.", { userFacing: true }));
    }
    if (degrees.value >= 180) {
      errors.push(new ValidationError("Longitude degrees must be below 180°.", { userFacing: true }));
    }
    if (!Number.isInteger(degrees.value)) {
      errors.push(new ValidationError("Longitude degrees must be a whole number.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(degrees);
  };

  public static validateDDMMinutes = (minutes: Minutes): Result<Minutes, ValidationError[]> => {
    const errors: ValidationError[] = [];
    if (minutes.value < 0) {
      errors.push(new ValidationError("Longitude minutes must be 0 or above.", { userFacing: true }));
    }
    if (minutes.value >= 60) {
      errors.push(new ValidationError("Longitude minutes must be below 60.", { userFacing: true }));
    }
    if (errors.length > 0) {
      return err(errors);
    }
    return ok(minutes);
  };

  /* ------------------------------------------------------------ */

  public static fromDegreesDecimalMinutes(
    ddm: LongDDM,
  ): Result<Longitude, ValidationError[]> {
    const validationResults: [
      Result<Degrees, ValidationError[]>,
      Result<Minutes, ValidationError[]>,
      Result<Hemisphere, ValidationError[]>,
    ] = [
      this.validateDDMDegrees(ddm?.degrees),
      this.validateDDMMinutes(ddm?.minutes),
      this.validateHemisphere(ddm?.hemisphere),
    ];
    return combineWithAllErrors(validationResults)
      .map(([
        degrees,
        minutes,
        hemisphere,
      ]) => {
        const decimalDegrees = (
          degrees.value
          + (minutes.value / 60)
        ) * (hemisphere === Hemisphere.E ? 1 : -1);

        return new Longitude(new Degrees(decimalDegrees));
      })
      .mapErr((errors) => errors.flat());
  }

  public toDegreesMinuteSeconds(): LongDMS {
    return super.toDegreesMinuteSeconds() as LongDMS;
  }

  public toDegreesDecimalMinutes(): LongDDM {
    return super.toDegreesDecimalMinutes() as LongDDM;
  }

  protected getHemisphere(): LongHemisphere {
    return this.decimalDegrees.value >= 0 ? Hemisphere.E : Hemisphere.W;
  }
}

export default Longitude;