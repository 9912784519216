import React from "react";
import {
  Result,
} from "neverthrow";
import styled from "styled-components";
import {
  FormItemProps,
} from "../../../../FormItem";
import { Coordinates } from "../../../../../../../domain/position/Coordinates";
import ErrorWrapper from "../../../../ErrorWrapper";
import FetchErrors from "../../../../../../../shared/errors/FetchErrors";
import FillWithAisButton from "../shared/FillWithAisButton";
import DDMLatitudeFormItem from "./DDMLatitudeFormItem";
import DDMLongitudeFormItem from "./DDMLongitudeFormItem";
import { usePositionFormItem } from "../shared/usePositionFormItem";
import { LatDDM } from "../../../../../../../domain/position/Latitude";
import { LongDDM } from "../../../../../../../domain/position/Longitude";

const StyledDDMFormItem = styled.div`
  display: flex;
  flex-direction: column;
  
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
  
  & > div.aisButton {
    align-self: flex-start;
    margin: 0px 30px 20px 180px;
  }
`;

type Props =
  FormItemProps<Coordinates>
  & {
    getAisPosition: (() => Promise<Result<Coordinates, FetchErrors>>) | undefined
  };

const DDMFormItem = (props: Props): React.ReactElement => {
  const hook = usePositionFormItem<LatDDM, LongDDM>(props);

  return (
    <ErrorWrapper isError={hook.isError} errors={hook.error}>
      <StyledDDMFormItem>
        <FillWithAisButton
          onClick={hook.fillWithAis}
          enabled={hook.canFillWithAis}
          loading={hook.aisLoading}
        />
        <DDMLatitudeFormItem
          {...hook.lat}
          required={props.required}
        />
        <DDMLongitudeFormItem
          {...hook.long}
          required={props.required}
        />
      </StyledDDMFormItem>
    </ErrorWrapper>
  );
};

export default DDMFormItem;