import React, {
  useCallback,
  useEffect,
} from "react";
import styled from "styled-components";
import {
  Button,
  Popconfirm,
  Select,
  Table,
} from "antd";
import {
  err,
  ok,
  Result,
} from "neverthrow";
import { ColumnsType } from "antd/lib/table";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import {
  DirtyInfo,
  FormItemProps,
} from "../../../FormItem";

import {
  removeAtIndex,
  replaceAtIndex,
} from "../../../../../../shared/utils/array";
import IconButton from "../../../../misc-components/IconButton";
import NumericInput from "../../../../misc-components/NumericInput";
import RequiredError from "../../../../../../domain/errors/RequiredError";
import ErrorWrapper from "../../../ErrorWrapper";
import RequiredStar from "../../../../misc-components/RequiredStar";
import ValidationError from "../../../../../../domain/errors/ValidationError";
import OrderedConsumptionItem from "../../../../../../domain/OrderedConsumptionItem";
import FuelType, { fuelTypeOptions } from "../../../../../../domain/FuelType";
import {
  Id,
  newId,
} from "../../../../../../domain/Id";
import { deepEqual } from "../../../../../../shared/utils/equality";
import { Tons } from "../../../../../../domain/units/Tons";
import {
  useSingleFormItem,
  UseSingleFormItemHook,
} from "../../../useSingleFormItem";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";

type OrderedConsumptionItemInput = {
  id: Id,
  dirty: DirtyInfo
  fuel?: FuelType,
  consumptionPerDay?: Tons,
};

type Props = FormItemProps<OrderedConsumptionItem[]> & {
  fuelTypes: FuelType[],
  requiredOrderedConsumptionFuelTypes?: FuelType[],
};
type Hook = UseSingleFormItemHook<OrderedConsumptionItemInput[], OrderedConsumptionItem[]> & {
  addConsumptionItem: () => void,
  tableColumns: ColumnsType<OrderedConsumptionItemInput>,
};

/* ------------------------------------------------------------ */

const outputToInput = (value: OrderedConsumptionItem[]): OrderedConsumptionItemInput[] => {
  return value.map((consItem) => ({
    ...consItem,
    dirty: { value: true },
  }));
};

const getInputToResult = (
  anyRequired: boolean,
  requiredOrderedConsumptionFuelTypes: FuelType[] | undefined,
): ((input: OrderedConsumptionItemInput[]) => Result<OrderedConsumptionItem[], ValidationError[]>
  ) => {
  return useCallback((input) => {
    if ((input === undefined || input.length === 0) && !anyRequired) {
      return ok([]);
    }
    const missingTypes = (requiredOrderedConsumptionFuelTypes ?? []).filter((rft) => !(input ?? []).some((cii) => cii.fuel === rft));
    if (missingTypes.length > 0) {
      return err([new ValidationError(`Missing warranted consumption entries for the following fuel types: ${missingTypes.join(
        ", ",
      )}`, { userFacing: true })]);
    }
    let consumptionItems: OrderedConsumptionItem[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const consumptionInputItem of (input ?? [])) {
      // TODO: This error handling is kinda dumb, not very specific.
      if (
        consumptionInputItem.fuel === undefined ||
        consumptionInputItem.consumptionPerDay === undefined
      ) {
        return err([new RequiredError(
          "A warranted consumption entry is missing one or more fields, please check for errors.",
          { userFacing: true },
        )]);
      }
      const newConsumptionItem: OrderedConsumptionItem = {
        id: consumptionInputItem.id,
        fuel: consumptionInputItem.fuel,
        consumptionPerDay: consumptionInputItem.consumptionPerDay,
      };
      consumptionItems = [
        ...consumptionItems,
        newConsumptionItem,
      ];
    }
    return ok(consumptionItems);
  }, [anyRequired, requiredOrderedConsumptionFuelTypes]);
};

/* ------------------------------------------------------------ */

const useOrderedConsumptionItemsFormItem = (props: Props): Hook => {
  const {
    requiredOrderedConsumptionFuelTypes,
  } = props;

  const anyRequired = (
    props.required &&
    requiredOrderedConsumptionFuelTypes !== undefined &&
    requiredOrderedConsumptionFuelTypes.length > 0
  );

  const hook = useSingleFormItem<OrderedConsumptionItemInput[], OrderedConsumptionItem[]>({
    ...props,
    inputToResult: notEmptyInputToResultWrapper(
      "Consumption Items",
      getInputToResult(anyRequired, requiredOrderedConsumptionFuelTypes),
    ),
    outputToInput,
    inputsEqual: deepEqual,
    dontAutoUpdateDirty: true,
  });

  useEffect(() => {
    const initialInput: OrderedConsumptionItemInput[] | undefined = anyRequired
      ? requiredOrderedConsumptionFuelTypes!.map((fuel) => ({
        id: newId(),
        fuel,
        dirty: { value: false },
      }))
      : undefined;
    hook.onInputChange(initialInput);
  }, []);

  useEffect(() => {
    hook._setInput((oldInput) => {
      if (oldInput === undefined) {
        return oldInput;
      }
      return oldInput.map((item) => ({ ...item, dirty: props.dirty }));
    });
  }, [props.dirty]);

  useEffect(() => {
    if (hook.input === undefined) {
      return;
    }
    const newInput = hook.input.map((item) => {
      const allFilled = (
        item.fuel !== undefined &&
        item.consumptionPerDay !== undefined
      );
      return ({ ...item, dirty: item.dirty || { value: allFilled } });
    });
    if (!deepEqual(newInput, hook.input)) {
      hook.onInputChange(newInput);
    }
  }, [hook.input]);

  const addConsumptionItem = (): void => {
    props.onDirtyChange({ value: false });
    hook.onInputChange([
      ...(hook.input ?? []),
      {
        id: newId(),
        dirty: props.dirty ?? { value: false },
      },
    ]);
  };

  const renderFuel = (
    fuel: FuelType | undefined,
    item: OrderedConsumptionItemInput,
    index: number,
  ): React.ReactNode => (
    <Select
      className={item.dirty.value && fuel === undefined ? "error" : undefined}
      value={fuel}
      placeholder="Fuel Grade"
      onChange={(newFuel: FuelType): void => {
        const newItem = {
          ...item,
          fuel: newFuel,
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newItem)
          : undefined;
        hook.onInputChange(newInput);
      }}>
      { Object.entries(fuelTypeOptions).map(([groupLabel, groupOptions]) => (
        <Select.OptGroup
          label={groupLabel}
          key={groupLabel}
        >
          {
            groupOptions.map((option) => (
              <Select.Option
                key={option.label}
                value={option.value}
              >
                {option.label}
              </Select.Option>
            ))
          }
        </Select.OptGroup>
      ))}
    </Select>
  );

  const renderConsumption = (
    consumption: Tons | undefined,
    item: OrderedConsumptionItemInput,
    index: number,
  ): React.ReactNode => (
    <NumericInput
      className={item.dirty.value && consumption === undefined ? "error" : undefined}
      value={consumption?.value}
      addonAfter="tons/day"
      allowNegative={false}
      separateThousands={true}
      placeholder="Consumption"
      onChange={(newConsumption: number | undefined): void => {
        const newInputItem: OrderedConsumptionItemInput = {
          ...item,
          consumptionPerDay: newConsumption === undefined ? undefined : new Tons(newConsumption),
        };
        const newInput = hook.input !== undefined
          ? replaceAtIndex(hook.input, index, newInputItem)
          : undefined;
        hook.onInputChange(newInput);
      }}
    />
  );

  const renderDelete = (_: unknown, item: OrderedConsumptionItemInput, index: number): React.ReactNode => (
    <Popconfirm
      title="Really delete this entry?"
      onConfirm={(): void => {
        const newInput = hook.input !== undefined
          ? removeAtIndex(hook.input, index)
          : undefined;
        hook.onInputChange(newInput);
      }}
      okText="Yes"
      cancelText="No"

    >
      <IconButton
        danger={true}
        icon={faTrash}
        type="link"
      />
    </Popconfirm>
  );

  const tableColumns: ColumnsType<OrderedConsumptionItemInput> = [
    {
      title: "Fuel Grade",
      dataIndex: "fuel",
      render: renderFuel,
      width: "45%",
    },
    {
      title: "Consumption",
      dataIndex: "consumptionPerDay",
      render: renderConsumption,
      width: "45%",
    },
    {
      title: "",
      render: renderDelete,
      width: "10%",
    },
  ];

  return {
    ...hook,
    addConsumptionItem,
    tableColumns,
  };
};

/* ------------------------------------------------------------ */

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  & td > * {
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: flex-end;
  
  & > h3 {
    flex: 1 1 0px;
    margin: 0;
  }
  
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const OrderedConsumptionItemsFormItem = (props: Props): React.ReactElement => {
  const hook = useOrderedConsumptionItemsFormItem(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      displayRequiredErrors={false}
      errors={hook.error}
    >
      <TableWrapper>
        <ButtonsWrapper>
          <h3>Warranted Consumption{props.required && <RequiredStar />}</h3>
          <Button
            type="primary"
            onClick={hook.addConsumptionItem}
          >
            Add entry
          </Button>
        </ButtonsWrapper>
        <Table
          columns={hook.tableColumns}
          dataSource={hook.input}
          pagination={false}
          rowKey="id"
          locale={{
            emptyText: "No warranted consumption entries added",
          }}
          tableLayout="fixed"
        />
      </TableWrapper>
    </ErrorWrapper>
  );
};

export default OrderedConsumptionItemsFormItem;