import Report from "../../domain/reports/Report";

const byReportDate = (direction: "asc" | "desc") => {
  return (a: Report, b: Report): number => {
    const aMillis = a.data.ReportDate?.toMillis() ?? 0;
    const bMillis = b.data.ReportDate?.toMillis() ?? 0;

    if (direction === "asc") {
      return aMillis - bMillis;
    }
    return bMillis - aMillis;
  };
};

export default byReportDate;