import localforage from "localforage";

export const logOut = async (
  beforeReloadCallback?: () => Promise<void>,
): Promise<void> => {
  await localforage.clear();
  await localStorage.clear();
  if (beforeReloadCallback !== undefined) {
    await beforeReloadCallback();
  }
  window.location.reload();
};