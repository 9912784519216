import React from "react";
import {
  green,
  red,
} from "@ant-design/colors";
import {
  Grid,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/pro-solid-svg-icons/faSyncAlt";
import Circle from "./Circle";
import { useSync } from "../hooks/useSync";
import { useIsOnline } from "../hooks/useIsOnline";

const ConnectionIndicator: React.FC = () => {
  const isOnline = useIsOnline();
  const { isSyncing } = useSync();
  const screens = Grid.useBreakpoint();

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 8px",
      color: screens.xs ? "black" : "hsl(0, 0%, 95%)",
      fontSize: "1.1em",
    }}>
      {
        isSyncing && (
          <Tooltip title="Syncing...">
            <span style={{
              marginRight: "10px",
            }}>
              <FontAwesomeIcon
                icon={faSyncAlt}
                spin={true}
              />
            </span>
          </Tooltip>
        )
      }
      <span>{isOnline ? "Online" : "Offline"}</span>
      <Circle 
        size="0.75em"
        color={isOnline ? green.primary : red.primary}
        style={{
          marginTop: "2px",
          marginLeft: "7px",
        }}
      />
    </div>
  );
};

export default ConnectionIndicator;