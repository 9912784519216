import TextReportGenerator from "../TextReportGenerator";
import ReportData from "../../ReportData";
import { saveClassByClassNameDecorator } from "../../../../shared/utils/classNameToConstructor";
import { DisplayedField } from "../../DisplayedField";

const DEFAULT_VERSION = "1";

const TITLE: string = "Test Report";

const FIELDS: DisplayedField[] = [
  { fieldType: "Captain", required: true },
];

@saveClassByClassNameDecorator()
class TestFieldsReportGenerator extends TextReportGenerator {
  public constructor() {
    super(TITLE, FIELDS, DEFAULT_VERSION);
  }

  getReportText(_data: ReportData, _version: string | undefined): string {
    return "";
  }
}

export default TestFieldsReportGenerator;