import { ok } from "neverthrow";
import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { Meters } from "../../../../../../domain/units/Meters";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { eqeqeq } from "../../../../../../shared/utils/equality";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";

const fieldName = "Mid Draft";

const DraftMidFormItemProvider: FormItemProvider<Meters> = (props: FormItemProviderProps<Meters>) => (
  <GenericNumberFormItemProvider
    fieldName={fieldName}
    fieldType="DraftMid"
    addonAfter="m"
    allowNegative={false}
    integersOnly={false}
    inputToResult={notEmptyInputToResultWrapper(fieldName, (input: number) => ok(new Meters(input)))}
    outputToInput={unwrapValue}
    inputsEqual={eqeqeq}
    {...props}
  />
);

export default React.memo(DraftMidFormItemProvider);