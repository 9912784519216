import Report from "../../../../domain/reports/Report";
import ReportType from "../../../../domain/reports/ReportType";
import byReportDate from "../../../../shared/utils/byReportDate";
import { DATE_TIME_OFFSET_FORMAT } from "../../../../shared/utils/date";

const validateReportWithRespectToOtherReports = (
  report: Report,
  allReports: Report[],
): string | null => {
  if (!isArrivalOrDeparture(report.type)) {
    return null;
  }

  const latestArrivalOrDeparture = [...allReports]
    .sort(byReportDate("desc"))
    .find((r) => isArrivalOrDeparture(r.type));

  const latestOtherArrivalOrDeparture = [...allReports]
    .sort(byReportDate("desc"))
    .find((r) => isArrivalOrDeparture(r.type) && r.id !== report.id);

  if (latestArrivalOrDeparture == null) {
    return null;
  }

  const latestReportDateMillis = latestArrivalOrDeparture.data.ReportDate?.toMillis() ?? 0;
  const latestOtherReportDateMillis = latestOtherArrivalOrDeparture?.data.ReportDate?.toMillis() ?? 0;
  const thisReportDateMillis = report.data.ReportDate?.toMillis() ?? 0;

  if (
    (
      latestArrivalOrDeparture.id !== report.id &&
      thisReportDateMillis < latestReportDateMillis
    ) ||
    (
      latestArrivalOrDeparture.id === report.id &&
      latestOtherArrivalOrDeparture != null &&
      thisReportDateMillis < latestOtherReportDateMillis
    )
  ) {
    const latestReportDateString = latestArrivalOrDeparture.data.ReportDate?.toFormat(DATE_TIME_OFFSET_FORMAT) ?? "";

    const oppositeType = report.type === ReportType.Arrival ? ReportType.Departure : ReportType.Arrival;

    return `Cannot submit a ${report.type} report earlier than the latest ${oppositeType} report (${latestReportDateString}).`;
  }

  if (
    latestArrivalOrDeparture.id !== report.id &&
    latestArrivalOrDeparture.type === ReportType.Departure &&
    report.type === ReportType.Departure
  ) {
    return `Cannot have two consecutive Departure reports. An Arrival report must be submitted before another Departure report can be submitted.`;
  }

  if (
    latestArrivalOrDeparture.id !== report.id &&
    latestArrivalOrDeparture.type === ReportType.Arrival &&
    report.type === ReportType.Arrival
  ) {
    return `Cannot have two consecutive Arrival reports. A Departure report must be submitted before another Arrival report can be submitted.`;
  }

  if (
    latestArrivalOrDeparture.id !== report.id &&
    report.type === ReportType.Departure &&
    (
      latestArrivalOrDeparture.type !== ReportType.Arrival ||
      latestArrivalOrDeparture.data.ArrivalPort?.id !== report.data.DeparturePort?.id
    )
  ) {
    return `Cannot submit a Departure report from port ${report.data.DeparturePort?.name ?? ""} when the latest Arrival report from port ${latestArrivalOrDeparture.data.ArrivalPort?.name ?? ""}.`;
  }
  
  return null;
};

function isArrivalOrDeparture(reportType: ReportType): boolean {
  return reportType === ReportType.Arrival || reportType === ReportType.Departure;
}

export default validateReportWithRespectToOtherReports;