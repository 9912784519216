import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import {
  useHistory,
  useParams,
} from "react-router-dom";
import {
  Affix,
  Card,
  Divider,
  Popconfirm,
  Tabs,
} from "antd";
import { faEdit } from "@fortawesome/pro-solid-svg-icons/faEdit";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons/faRefresh";
import ReportForm from "../../report-form/report-form/ReportForm";
import { FORM_ITEM_PROVIDERS } from "../../report-form/formItemProviders";
import Report from "../../../../domain/reports/Report";
import FetchErrors from "../../../../shared/errors/FetchErrors";
import useFetch from "../../hooks/useFetch";
import CloudStatusText from "../../misc-components/CloudStatusText";
import DataStatus from "../../misc-components/DataStatus";
import ReportStatus from "../../../../domain/reports/ReportStatus";
import SendReport from "./SendReport";
import { FORM_STRUCTURE } from "../../../../domain/reports/FieldGroup";
import {
  DATE_TIME_OFFSET_FORMAT,
  getCurrentTimestamp,
} from "../../../../shared/utils/date";
import { Id } from "../../../../domain/Id";
import {
  getReport,
  saveReport,
  subscribeToStorageEvent,
} from "../../localReportStorage";
import IconButton from "../../misc-components/IconButton";
import Routes from "../../routes";
import { SIMPLE_MODE } from "../../constants";
import { USER_SETTINGS } from "../../../../shared/mock/userSettings";
import { showErrorNotification } from "../../utils/notifications";
import ReportFormatConfig from "../../../../domain/reports/ReportFormatConfig";
import reportTypeToReportFormatConfigs
  from "../../../../domain/reports/reportTypeToReportFormatConfigs";
import CloudSyncStatus from "../../../../domain/CloudSyncStatus";

type Props = {};

type Hook = {
  report: Report | undefined,
  reportLoading: boolean,
  reportError: FetchErrors | undefined,
  setReport: (report: Report) => void,
  onEditReport: () => void,
  onDeleteReport: () => void,
  deleteLoading: boolean,
  reportFormatConfigs: ReportFormatConfig[],
  retrySync: () => Promise<void>,
  retrySyncLoading: boolean,
};

export const useReportFormPage = (_props: Props): Hook => {
  const { id } = useParams<{ id: Id }>();
  const [report, setReport] = useState<Report>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const history = useHistory();

  const {
    data: fetchedReport,
    loading: reportLoading,
    error: reportError,
    refresh: refreshReport,
  } = useFetch(
    useCallback(() => getReport(id), [id]),
    { onlySetLoadingFirstFetch: true },
  );

  useEffect(() => {
    const unsubscribe = subscribeToStorageEvent("sync", refreshReport);
    return unsubscribe;
  }, []);

  useEffect(() => setReport(fetchedReport), [fetchedReport]);

  const reportFormatConfigs = report === undefined
    ? []
    : reportTypeToReportFormatConfigs(report.type);

  const onEditReport = (): void => {
    if (report === undefined) {
      return;
    }
    history.push(Routes.EditReport.generate(report.id));
  };

  const onDeleteReport = async (): Promise<void> => {
    if (report === undefined) {
      return;
    }

    if (deleteLoading === undefined) {
      return;
    }

    setDeleteLoading(true);

    report.deleted = true;
    report.updatedAtTimestamp = getCurrentTimestamp();

    (await saveReport(report)).match(
      () => {
        setDeleteLoading(false);
        history.push(Routes.ReportsList.generate());
      },
      (error: Error) => {
        setDeleteLoading(false);
        showErrorNotification("Failed to delete report.", error);
      },
    );
  };

  const [retrySyncLoading, setRetrySyncLoading] = useState(false);

  const retrySync = useCallback(async () => {
    if (report == null) {
      return;
    }

    setRetrySyncLoading(true);

    try {
      const updatedReport = report;

      updatedReport.cloudStatus = CloudSyncStatus.AwaitingConnection;
      updatedReport.updatedAtTimestamp = getCurrentTimestamp();

      await saveReport(updatedReport);
    } finally {
      setRetrySyncLoading(false);
    }
  }, [report]);

  return {
    setReport,
    report,
    reportLoading,
    reportError,
    onEditReport,
    onDeleteReport,
    deleteLoading,
    reportFormatConfigs,
    retrySync,
    retrySyncLoading,
  };
};

/* ------------------------------------------------------------ */

const StyledReportFormPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1400px;
    }
    
    & .ant-collapse-item-active .ant-collapse-header i {
      visibility: hidden;
    }
`;

const ReportMetaDataWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.1em;
  }
  
  & > div {
    display: flex;
    margin-bottom: 5px;
  }

  & > div > :first-child {
    width: 100px;
    font-weight: bold;
    text-align: right;
    margin-right: 10px;
  }
`;

const ViewReportPage: React.FC<Props> = (props) => {
  const hook = useReportFormPage(props);

  if (hook.reportLoading) {
    return (
      <DataStatus
        isLoading={true}
        loadingText="Loading report..."
      />
    );
  }

  if (hook.reportError) {
    // TODO: Better error text
    return (
      <DataStatus
        isError={true}
        errorText="Could not load report."
      />
    );
  }

  if (hook.report === undefined) {
    return null;
  }

  const isDraft = hook.report.status === ReportStatus.Draft;
  const anyFormatsSent = hook.report.reportFormatsToSend.some((format) => format.sent);

  return (
    <StyledReportFormPage>
      <div>
        <h1>Report Details</h1>
        <div style={{
          display: "flex",
        }}>
          <Affix offsetTop={70}>
            <Card
              title="Report Info"
              style={{
                height: "fit-content",
                minWidth: "300px",
                marginRight: "10px",
              }}
            >
              <ReportMetaDataWrapper>
                <div>
                  <span>Report type:</span>
                  <span>{hook.report.type}</span>
                </div>
                <div>
                  <span>Created at:</span>
                  <span>{hook.report.createdAt.toFormat(DATE_TIME_OFFSET_FORMAT)}</span>
                </div>
                <div>
                  <span>Status:</span>
                  {
                    hook.report.cloudStatus !== undefined &&
                    <CloudStatusText cloudStatus={hook.report.cloudStatus} tooltipMode="underline" />
                  }
                </div>
                {
                  !hook.report.deleted && (
                    <>
                      <Divider style={{
                        marginTop: "10px",
                      }} />
                      {
                        hook.report.cloudStatus !== CloudSyncStatus.Synced && (
                          <IconButton
                            onClick={hook.retrySync}
                            icon={faRefresh}
                            type="primary"
                            loading={hook.retrySyncLoading}
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            Retry Sync
                          </IconButton>
                        )
                      }
                      {
                        anyFormatsSent && (
                          <Popconfirm
                            title={"This report has already been sent. If it's edited, you\nwill have to send it again. Do you want to continue?"}
                            onConfirm={hook.onEditReport}
                          >
                            <IconButton
                              icon={faEdit}
                              type="primary"
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              Revise Report
                            </IconButton>
                          </Popconfirm>
                        )
                      }
                      {
                        !anyFormatsSent && (
                          <IconButton
                            icon={faEdit}
                            type="primary"
                            onClick={hook.onEditReport}
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            Edit Report
                          </IconButton>
                        )
                      }
                      <Popconfirm
                        title="Are you sure you want to delete this report?"
                        onConfirm={hook.onDeleteReport}
                      >
                        <IconButton
                          icon={faTrash}
                          danger={true}
                          type="primary"
                          loading={hook.deleteLoading}
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          Delete Report
                        </IconButton>
                      </Popconfirm>
                    </>
                  )
                }
              </ReportMetaDataWrapper>
            </Card>
          </Affix>
          { hook.report.deleted && (
            <div style={{
              flex: "1 1 0px",
              background: "white",
              display: "flex",
              justifyContent: "center",
            }}>
              <DataStatus
                isEmpty={true}
                emptyText="This report has been deleted"
              />
            </div>
          )}
          { !hook.report.deleted && (
            <Tabs
              defaultActiveKey={isDraft ? "data" : "send"}
              style={{
                flex: "1 1 0px",
              }}
              type="card"
              className="better-card-tab"
              tabBarStyle={{
                marginBottom: 0,
              }}
            >
              <Tabs.TabPane
                tab="Report Data"
                key="data"
                style={{
                  padding: "24px",
                  background: "white",
                }}
              >
                <ReportForm
                  formStructure={FORM_STRUCTURE}
                  formItemProviders={FORM_ITEM_PROVIDERS}
                  reportFormatConfigs={hook.reportFormatConfigs}
                  userSettings={USER_SETTINGS}
                  existingReport={hook.report}
                  disabled={true}
                  reportType={hook.report.type}
                />
              </Tabs.TabPane>
              { !SIMPLE_MODE && (
                <Tabs.TabPane
                  tab="Send Report"
                  key="send"
                  style={{
                    padding: "24px",
                    background: "white",
                  }}
                >
                  {isDraft && (
                    <div>
                      <span>You cannot send the report while it&apos;s a draft. Please edit and finalize the report to send it.</span>
                    </div>
                  )}
                  {!isDraft && (
                    <SendReport
                      report={hook.report}
                      setReport={hook.setReport}
                    />
                  )}
                </Tabs.TabPane>
              )}
            </Tabs>
          )}
        </div>
      </div>
    </StyledReportFormPage>
  );
};

export default ViewReportPage;
