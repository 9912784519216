import React from "react";
import { FormItemProvider } from "../../FormItemProvider";
import ROBItemsFormItem from "./ROBItemsFormItem";
import ROBItem from "../../../../../domain/ROBItem";
import FuelType from "../../../../../domain/FuelType";

const ROBItemsFormItemProvider: FormItemProvider<ROBItem[]> = (props) => {
  const { settings } = props;
  const { requiredROBFuelTypes } = settings;

  return (
    <ROBItemsFormItem
      {...props}
      fuelTypes={Object.values(FuelType)}
      requiredROBFuelTypes={requiredROBFuelTypes}
    />
  );
};

export default React.memo(ROBItemsFormItemProvider);