import { ok } from "neverthrow";
import React from "react";
import { eqeqeq } from "../../../../../shared/utils/equality";
import identity from "../../../../../shared/utils/identity";
import { FormItemProvider, FormItemProviderProps } from "../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../InputToResult";
import GenericTextFormItemProvider from "../_generic/text/GenericTextFormItemProvider";

const fieldName = "Berth Name";

const BerthNameFormItemProvider: FormItemProvider<string> = (props: FormItemProviderProps<string>) => (
  <GenericTextFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="BerthName"
    textarea={false}
    inputToResult={notEmptyInputToResultWrapper(fieldName, ok)}
    outputToInput={identity}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(BerthNameFormItemProvider);
