import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { CargoQuantity } from "../../../../../../domain/units/CargoQuantity";
import GenericQuantityFormItemProvider
  from "../../_generic/quantity/GenericQuantityFormItemProvider";

const fieldName = "To be loaded/discharged";

const ToBeLoadedDischargedFormItemProvider: FormItemProvider<CargoQuantity> = (props: FormItemProviderProps<CargoQuantity>) => (
  <GenericQuantityFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="ToBeLoadedDischarged"
  />
);

export default React.memo(ToBeLoadedDischargedFormItemProvider);
