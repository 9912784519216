import React, {
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import {
  err,
  ok,
  Result,
} from "neverthrow";
import SpeedInstructions, { SpeedInstructionOptions } from "../../../../../../domain/SpeedInstructions";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { FormItemProps } from "../../../FormItem";
import StandardInputContainer from "../../../StandardInputContainer";
import SuggestionsWrapper from "../../../SuggestionsWrapper";
import Suggestion from "../../../Suggestion";
import SelectInput from "../../../SelectInput";
import TextInput from "../../../TextInput";
import {
  Outputs,
  useCompositeFormItem,
} from "../../../useCompositeFormItem";
import ValidationError from "../../../../../../domain/errors/ValidationError";
import { Option } from "../../../../Options";

const fieldName = "Speed Instructions";

type SpeedInstructionsFormItemProps = FormItemProps<SpeedInstructions> & {

};

const StyledSpeedInstructionsFormItem = styled.div`
  display: flex;
  flex-direction: column;
  
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

type SpeedInstructionsChildInputTypes = {
  option: {
    input: SpeedInstructionOptions,
    output: SpeedInstructionOptions,
  },
  otherValue: {
    input: string | undefined,
    output: string | undefined | null,
  },
};

const outputToChildOutputs = (output: SpeedInstructions): Outputs<SpeedInstructionsChildInputTypes> => ({
  option: output.option,
  otherValue: output.otherValue,
});

const childOutputsEqual = (o1: Outputs<SpeedInstructionsChildInputTypes>, o2: Outputs<SpeedInstructionsChildInputTypes>): boolean => {
  return o1.option === o2.option && o1.otherValue === o2.otherValue;
};

const childOutputsToResult = (childOutputs: Outputs<SpeedInstructionsChildInputTypes>): Result<SpeedInstructions, ValidationError[]> => {
  if (
    childOutputs.option === SpeedInstructionOptions.Other &&
    (childOutputs.otherValue === undefined || childOutputs.otherValue === "")
  ) {
    return err([new ValidationError(
      `"${SpeedInstructionOptions.Other}" speed instruction needs additional details.`,
      { userFacing: true },
    )]);
  }
  return ok(new SpeedInstructions(childOutputs.option, childOutputs.otherValue ?? undefined));
};

const SpeedInstructionsFormItem = (props: SpeedInstructionsFormItemProps): React.ReactElement => {
  const hook = useCompositeFormItem<SpeedInstructionsChildInputTypes, SpeedInstructions>({
    onDirtyChange: props.onDirtyChange,
    required: props.required,
    dirty: props.dirty,
    suggestions: props.suggestions,
    result: props.result,
    onResultChange: props.onResultChange,
    dataKeys: ["option", "otherValue"],
    outputToChildOutputs,
    childOutputsEqual,
    childOutputsToResult,
  });

  const options = useMemo(() => (
    Object.entries(SpeedInstructionOptions)
      .map(([key, val]): Option<SpeedInstructionOptions> => ({
        value: SpeedInstructionOptions[key],
        label: val,
      }))
  ), []);

  const onOptionChange = useCallback((option: SpeedInstructionOptions | undefined): void => {
    if (option === undefined) {
      hook.option.onResultChange(undefined);
      return;
    }
    if (option !== SpeedInstructionOptions.Other) {
      hook.otherValue.onResultChange(ok(null));
    }
    hook.option.onResultChange(ok(option));
  }, []);

  const displayOther = (
    hook.option.result?.isOk() &&
    hook.option.result.value === SpeedInstructionOptions.Other
  );

  return (
    <StandardInputContainer
      required={props.required}
      fieldName={fieldName}
      isOk={hook.isOk}
      isError={hook.isError}
      errors={hook.error}
    >
      <SuggestionsWrapper<SpeedInstructions>
        pickSuggestion={hook.pickSuggestion}
        showSuggestions={hook.showSuggestions}
        suggestions={props.suggestions}
      >
        <StyledSpeedInstructionsFormItem>
          <SelectInput<SpeedInstructionOptions>
            options={options}
            searchable={true}
            value={hook.option.result?.isOk() ? hook.option.result.value : undefined}
            onChange={onOptionChange}
            placeholder={fieldName}
            isError={!displayOther && hook.isError}
          />
          {
            displayOther && (
              <TextInput
                onChange={(val: string | undefined): void => hook.otherValue.onResultChange(ok(val))}
                isError={hook.isError}
                value={hook.otherValue.result?.isOk() ? hook.otherValue.result.value ?? undefined : undefined}
                placeholder={`${fieldName} Details`}
              />
            )
          }
        </StyledSpeedInstructionsFormItem>
      </SuggestionsWrapper>
    </StandardInputContainer>
  );
};

const SpeedInstructionsFormItemProvider: FormItemProvider<SpeedInstructions> = (props: FormItemProviderProps<SpeedInstructions>) => {
  const suggestions: Suggestion<SpeedInstructions>[] = useMemo(() => {
    const prevValue = props.previousReport?.data?.SpeedInstructions;
    if (prevValue === undefined || prevValue === null) {
      return [];
    }
    const suggestionsText = prevValue.option !== SpeedInstructionOptions.Other
      ? `${prevValue.option}`
      : `Other (${prevValue.otherValue})`;
    return [{
      text: suggestionsText,
      value: prevValue,
    }];
  }, []);
  
  return (
    <SpeedInstructionsFormItem
      required={props.required}
      result={props.result}
      onResultChange={props.onResultChange}
      onDirtyChange={props.onDirtyChange}
      dirty={props.dirty}
      suggestions={suggestions}
    />
  );
};

export default React.memo(SpeedInstructionsFormItemProvider);