import React from "react";
import { FormItemProvider } from "../../../FormItemProvider";
import OrderedConsumptionItemsFormItem from "./OrderedConsumptionItemsFormItem";
import FuelType from "../../../../../../domain/FuelType";
import OrderedConsumptionItem from "../../../../../../domain/OrderedConsumptionItem";

const OrderedConsumptionItemsFormItemProvider: FormItemProvider<OrderedConsumptionItem[]> = (props) => {
  const { settings } = props;
  const { requiredOrderedConsumptionFuelTypes } = settings;

  return (
    <OrderedConsumptionItemsFormItem
      {...props}
      fuelTypes={Object.values(FuelType)}
      requiredOrderedConsumptionFuelTypes={requiredOrderedConsumptionFuelTypes}
    />
  );
};

export default React.memo(OrderedConsumptionItemsFormItemProvider);