import React from "react";
import { Result } from "neverthrow";
import styled from "styled-components";
import { Col, Grid, Row } from "antd";
import {
  FormItem,
  FormItemProps,
} from "../../../../FormItem";
import StandardInputContainer from "../../../../StandardInputContainer";
import Longitude, { LongHemisphere } from "../../../../../../../domain/position/Longitude";
import { Degrees } from "../../../../../../../domain/units/Degrees";
import { Seconds } from "../../../../../../../domain/units/Seconds";
import { Minutes } from "../../../../../../../domain/units/Minutes";
import {
  Outputs,
  useCompositeFormItem,
  UseCompositeFormItemHook,
} from "../../../../useCompositeFormItem";
import ValidationError from "../../../../../../../domain/errors/ValidationError";
import DegreesInput from "../shared/DegreesInput";
import { eqeqeq } from "../../../../../../../shared/utils/equality";
import MinutesInput from "../shared/MinutesInput";
import SecondsInput from "../shared/SecondsInput";
import HemisphereInput from "../shared/HemisphereInput";
import identity from "../../../../../../../shared/utils/identity";
import Hemisphere from "../../../../../../../domain/position/Hemisphere";
import { unwrapValue } from "../../../../../../../shared/utils/unwrapValue";
import { notEmptyInputToResultWrapper } from "../../../../InputToResult";

type ChildInputTypes = {
  degrees: {
    input: number,
    output: Degrees,
  },
  minutes: {
    input: number,
    output: Minutes,
  },
  seconds: {
    input: number,
    output: Seconds,
  },
  hemisphere: {
    input: Hemisphere,
    output: LongHemisphere,
  }
};

const childOutputsEqual = (outputs1: Outputs<ChildInputTypes>, outputs2: Outputs<ChildInputTypes>): boolean => (
  outputs1.degrees.equals(outputs2.degrees) &&
  outputs1.minutes.equals(outputs2.minutes) &&
  outputs1.seconds.equals(outputs2.seconds) &&
  outputs1.hemisphere === outputs2.hemisphere
);

const childOutputsToResult = (outputs: Outputs<ChildInputTypes>): Result<Longitude, ValidationError[]> => (
  Longitude.fromDegreesMinutesSeconds(outputs)
);

const outputToChildOutputs = (output: Longitude): Outputs<ChildInputTypes> => (
  output.toDegreesMinuteSeconds()
);

export type DMSFormItemProps =
  & FormItemProps<Longitude>;

export type DMSFormItemHook =
  {
    degreesInputToResult: (input: number | undefined) => Result<Degrees, ValidationError[]>,
    minutesInputToResult: (input: number | undefined) => Result<Minutes, ValidationError[]>,
    secondsInputToResult: (input: number | undefined) => Result<Seconds, ValidationError[]>,
    hemisphereInputToResult: (input: Hemisphere | undefined) => Result<LongHemisphere, ValidationError[]>,
  }
  & UseCompositeFormItemHook<ChildInputTypes, Longitude>;

/* ------------------------------------------------------------ */

export const useDMSLongitudeFormItem = (props: DMSFormItemProps): DMSFormItemHook => {
  const hook = useCompositeFormItem<ChildInputTypes, Longitude>({
    ...props,
    dataKeys: ["degrees", "minutes", "seconds", "hemisphere"],
    outputToChildOutputs,
    childOutputsToResult,
    childOutputsEqual,
  });

  const degreesInputToResult = notEmptyInputToResultWrapper("Longitude", (input: number) => (
    Longitude.validateDMSDegrees(new Degrees(input))
  ));

  const minutesInputToResult = notEmptyInputToResultWrapper("Longitude", (input: number) => (
    Longitude.validateDMSMinutes(new Degrees(input))
  ));

  const secondsInputToResult = notEmptyInputToResultWrapper("Longitude", (input: number) => (
    Longitude.validateDMSSeconds(new Degrees(input))
  ));

  const hemisphereInputToResult = notEmptyInputToResultWrapper("Longitude", Longitude.validateHemisphere);

  return {
    ...hook,
    degreesInputToResult,
    minutesInputToResult,
    secondsInputToResult,
    hemisphereInputToResult,
  };
};

/* ------------------------------------------------------------ */

const InputsWrapper = styled.div`
  & {
    display: flex;
    flex-direction: row;
  }
  
  & > *:not(:last-child) {
    flex: 2 1 0px;
    margin-right: 10px;
  }
  
  & > *:last-child {
    flex: 1 1 0px;
  }
`;

const DMSLongitudeFormItem: FormItem<Longitude> = (props) => {
  const hook = useDMSLongitudeFormItem({
    ...props,
  });

  const screens = Grid.useBreakpoint();

  return (
    <StandardInputContainer
      fieldName="Longitude"
      required={props.required}
      isError={hook.isError}
      isOk={hook.isOk}
    >
      {screens.xs ? (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <DegreesInput
              result={hook.degrees.result}
              onResultChange={hook.degrees.onResultChange}
              dirty={hook.degrees.dirty}
              onDirtyChange={hook.degrees.onDirtyChange}
              outputToInput={unwrapValue}
              inputToResult={hook.degreesInputToResult}
              inputsEqual={eqeqeq}
              required={props.required}
              decimal={false}
            />
          </Col>
          <Col span={24}>
            <MinutesInput
              result={hook.minutes.result}
              onResultChange={hook.minutes.onResultChange}
              dirty={hook.minutes.dirty}
              onDirtyChange={hook.minutes.onDirtyChange}
              outputToInput={unwrapValue}
              inputToResult={hook.minutesInputToResult}
              inputsEqual={eqeqeq}
              required={props.required}
              decimal={false}
            />
          </Col>
          <Col span={24}>
            <SecondsInput
              result={hook.seconds.result}
              onResultChange={hook.seconds.onResultChange}
              dirty={hook.seconds.dirty}
              onDirtyChange={hook.seconds.onDirtyChange}
              outputToInput={unwrapValue}
              inputToResult={hook.secondsInputToResult}
              inputsEqual={eqeqeq}
              required={props.required}
              decimal={true}
            />
          </Col>
          <Col span={24}>
            <HemisphereInput<LongHemisphere>
              result={hook.hemisphere.result}
              onResultChange={hook.hemisphere.onResultChange}
              dirty={hook.hemisphere.dirty}
              onDirtyChange={hook.hemisphere.onDirtyChange}
              outputToInput={identity}
              inputToResult={hook.hemisphereInputToResult}
              hemisphereChoices={[Hemisphere.E, Hemisphere.W]}
              inputsEqual={eqeqeq}
              required={props.required}
            />
          </Col>
        </Row>
      ) : (
        <InputsWrapper>
          <DegreesInput
            result={hook.degrees.result}
            onResultChange={hook.degrees.onResultChange}
            dirty={hook.degrees.dirty}
            onDirtyChange={hook.degrees.onDirtyChange}
            outputToInput={unwrapValue}
            inputToResult={hook.degreesInputToResult}
            inputsEqual={eqeqeq}
            required={props.required}
            decimal={false}
          />
          <MinutesInput
            result={hook.minutes.result}
            onResultChange={hook.minutes.onResultChange}
            dirty={hook.minutes.dirty}
            onDirtyChange={hook.minutes.onDirtyChange}
            outputToInput={unwrapValue}
            inputToResult={hook.minutesInputToResult}
            inputsEqual={eqeqeq}
            required={props.required}
            decimal={false}
          />
          <SecondsInput
            result={hook.seconds.result}
            onResultChange={hook.seconds.onResultChange}
            dirty={hook.seconds.dirty}
            onDirtyChange={hook.seconds.onDirtyChange}
            outputToInput={unwrapValue}
            inputToResult={hook.secondsInputToResult}
            inputsEqual={eqeqeq}
            required={props.required}
            decimal={true}
          />
          <HemisphereInput<LongHemisphere>
            result={hook.hemisphere.result}
            onResultChange={hook.hemisphere.onResultChange}
            dirty={hook.hemisphere.dirty}
            onDirtyChange={hook.hemisphere.onDirtyChange}
            outputToInput={identity}
            inputToResult={hook.hemisphereInputToResult}
            hemisphereChoices={[Hemisphere.E, Hemisphere.W]}
            inputsEqual={eqeqeq}
            required={props.required}
          />
        </InputsWrapper>
      )}
    </StandardInputContainer>
  );
};

export default DMSLongitudeFormItem;