import React from "react";
import { DateTime } from "luxon";
import GenericDateTimeFormItemProvider from "../../_generic/date/GenericDateTimeFormItemProvider";
import { FormItemProviderProps } from "../../../FormItemProvider";
import { DATE_TIME_OFFSET_FORMAT } from "../../../../../../shared/utils/date";

const outputToSuggestionText = (output: DateTime): string => output.toFormat(DATE_TIME_OFFSET_FORMAT);

type Props = FormItemProviderProps<DateTime>;

const fieldName = "2nd ETA";
const fieldType = "ETADate2";

const ETADate2FormItemProvider = (props: Props): React.ReactElement => (
  <GenericDateTimeFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType={fieldType}
    suggestPrevious={true}
    outputToSuggestionText={outputToSuggestionText}
  />
);

export default ETADate2FormItemProvider;