import {
  ok,
  Result,
} from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<Degrees>()
export class Degrees extends NumberUnit {
  static serialize(degrees: Degrees): Result<Serialized, SerializationError> {
    return ok({ value: degrees.value, __className: Degrees.name });
  }

  static deserialize(serialized: Serialized): Result<Degrees, SerializationError> {
    return ok(new Degrees(serialized.value));
  }
}