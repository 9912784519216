import { Tooltip } from "antd";
import React, { CSSProperties } from "react";

const TooltipSpan: React.FC<{
  tooltip: string,
  style?: CSSProperties,
}> = (props) => {
  return (
    <Tooltip title={props.tooltip}>
      <span
        style={{
          cursor: "help",
          textDecoration: "underline",
          textDecorationStyle: "dotted",
          ...props.style,
        }}>
        {props.children}
      </span>
    </Tooltip>
  );
};

export default TooltipSpan;