import React, {
  useCallback,
} from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import ReportForm from "../../report-form/report-form/ReportForm";
import { FORM_ITEM_PROVIDERS } from "../../report-form/formItemProviders";

import { FORM_STRUCTURE } from "../../../../domain/reports/FieldGroup";
import Report from "../../../../domain/reports/Report";
import FetchErrors from "../../../../shared/errors/FetchErrors";
import { Id } from "../../../../domain/Id";
import useFetch from "../../hooks/useFetch";
import {
  getPreviousReport,
  getReport,
} from "../../localReportStorage";
import DataStatus from "../../misc-components/DataStatus";
import { USER_SETTINGS } from "../../../../shared/mock/userSettings";
import ReportFormatConfig from "../../../../domain/reports/ReportFormatConfig";
import reportTypeToReportFormatConfigs from "../../../../domain/reports/reportTypeToReportFormatConfigs";

type Props = {};

type Hook = {
  report: Report | undefined,
  loading: boolean,
  error: FetchErrors | undefined,
  previousReport: Report | undefined,
  reportFormatConfigs: ReportFormatConfig[],
};

export const useEditReportPage = (_props: Props): Hook => {
  const { id } = useParams<{ id: Id }>();

  const {
    data: report,
    loading: reportLoading,
    error: reportError,
  } = useFetch(useCallback(() => getReport(id), [id]));

  const {
    data: previousReport,
    loading: _previousReportLoading,
    error: _previousReportError,
  } = useFetch(useCallback(() => getPreviousReport(report), [report]));

  const reportFormatConfigs = report === undefined
    ? []
    : reportTypeToReportFormatConfigs(report.type);

  return {
    report,
    loading: reportLoading,
    error: reportError,
    previousReport,
    reportFormatConfigs,
  };
};

/* ------------------------------------------------------------ */

const StyledReportFormPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
  }
`;

const EditReportPage: React.FC<Props> = (props) => {
  const hook = useEditReportPage(props);

  return (
    <StyledReportFormPage>
      <div>
        <h1>Edit Report</h1>
        {
          hook.loading && (
            <DataStatus
              isLoading={true}
              loadingText="Loading report..."
            />
          )
        }
        {
          hook.error && (
            (
              <DataStatus
                isError={true}
                errorText="Could not load report."
              />
            )
          )
        }
        {
          hook.report !== undefined && (
            <ReportForm
              formStructure={FORM_STRUCTURE}
              formItemProviders={FORM_ITEM_PROVIDERS}
              reportFormatConfigs={hook.reportFormatConfigs}
              userSettings={USER_SETTINGS}
              previousReport={hook.previousReport}
              existingReport={hook.report}
              blockNavigation={true}
              reportType={hook.report.type}
            />
          )
        }
      </div>
    </StyledReportFormPage>
  );
};

export default EditReportPage;
