import { WrappedFormItemProviders } from "./FormItemProvider";
import VesselNameFormItemProvider from "./form-item-providers/general/vessel-name/VesselNameFormItemProvider";
import IMONumberFormItemProvider from "./form-item-providers/general/imo-number/IMONumberFormItemProvider";
import LoadConditionFormItemProvider from "./form-item-providers/condition/current/LoadConditionFormItemProvider";
import DraftMidFormItemProvider from "./form-item-providers/condition/draft-mid/DraftForeFormItemProvider";
import BallastWeightFormItemProvider from "./form-item-providers/condition/ballast-weight/BallastWeightFormItemProvider";
import FreshWaterFormItemProvider from "./form-item-providers/condition/fresh-water/FreshWaterFormItemProvider";
import DraftForeFormItemProvider from "./form-item-providers/condition/draft-fore/DraftForeFormItemProvider";
import CargoWeightFormItemProvider from "./form-item-providers/condition/cargo-weight/CargoWeightFormItemProvider";
import DraftAftFormItemProvider from "./form-item-providers/condition/draft-aft/AftDraftFormItemProvider";
import ConsumptionItemsFormItemProvider from "./form-item-providers/consumption/ConsumptionItemsFormItemProvider";
import ConsumptionItemsSOSPFormItemProvider from "./form-item-providers/consumption/ConsumptionItemsSOSPFormItemProvider";
import ConsumptionItemsEOSPFormItemProvider from "./form-item-providers/consumption/ConsumptionItemsEOSPFormItemProvider";
import ROBItemsFormItemProvider from "./form-item-providers/rob/ROBItemsFormItemProvider";
import OrderedConsumptionItemsFormItemProvider from "./form-item-providers/cp/ordered-consumption/OrderedConsumptionItemsFormItemProvider";
import ReportDateFormItemProvider from "./form-item-providers/general/report-date/ReportDateFormItemProvider";
import CallSignFormItemProvider from "./form-item-providers/general/call-sign/CallSignFormItemProvider";
import PositionFormItemProvider from "./form-item-providers/position/position/PositionFormItemProvider";
import RemarksFormItemProvider from "./form-item-providers/general/remarks/RemarksFormItemProvider";
import CaptainFormItemProvider from "./form-item-providers/general/captain/CaptainFormItemProvider";
import SeaDirectionFormItemProvider from "./form-item-providers/weather/sea-direction/SeaDirectionFormItemProvider";
import HeadingFormItemProvider from "./form-item-providers/navigation/heading/HeadingFormItemProvider";
import CPDateFormItemProvider from "./form-item-providers/cp/cp-date/CPDateFormItemProvider";
import MainEnginePowerFormItemProvider from "./form-item-providers/performance/main-engine-power/MainEnginePowerFormItemProvider";
import MainEngineLoadFormItemProvider from "./form-item-providers/performance/main-engine-load/MainEngineLoadFormItemProvider";
import BadWeatherDistanceFormItemProvider from "./form-item-providers/weather/bad-weather-distance/BadWeatherDistanceFormItemProvider";
import AverageSpeedFormItemProvider from "./form-item-providers/navigation/average-speed/AverageSpeedFormItemProvider";
import VoyageChartererFormItemProvider from "./form-item-providers/cp/voyage-charterer/VoyageChartererFormItemProvider";
import WindForceFormItemProvider from "./form-item-providers/weather/wind-force/WindForceFormItemProvider";
import VisibilityFormItemProvider from "./form-item-providers/weather/visibility/VisibilityFormItemProvider";
import SwellDirectionFormItemProvider from "./form-item-providers/weather/swell-direction/SwellDirectionFormItemProvider";
import VoyageNumberFormItemProvider from "./form-item-providers/cp/voyage-number/VoyageNumberFormItemProvider";
import SwellHeightFormItemProvider from "./form-item-providers/weather/swell-height/SwellHeightFormItemProvider";
import CurrentSpeedFormItemProvider from "./form-item-providers/weather/current-speed/CurrentSpeedFormItemProvider";
import BadWeatherHoursFormItemProvider from "./form-item-providers/weather/bad-weather-hours/BadWeatherHoursFormItemProvider";
import ObservedDistanceFormItemProvider from "./form-item-providers/navigation/observed-distance/ObservedDistanceFormItemProvider";
import OrderedRPMFormItemProvider from "./form-item-providers/cp/ordered-rpm/OrderedRPMFormItemProvider";
import WindDirectionFormItemProvider from "./form-item-providers/weather/wind-direction/WindDirectionFormItemProvider";
import DeparturePortFormItemProvider from "./form-item-providers/ports/departure-port/DeparturePortFormItemProvider";
import EngineDistanceFormItemProvider from "./form-item-providers/navigation/engine-distance/EngineDistanceFormItemProvider";
import CurrentDirectionFormItemProvider from "./form-item-providers/weather/current-direction/CurrentDirectionFormItemProvider";
import ArrivalPortFormItemProvider from "./form-item-providers/ports/arrival-port/ArrivalPortFormItemProvider";
import OrderedSpeedFormItemProvider from "./form-item-providers/cp/ordered-speed/OrderedSpeedFormItemProvider";
import SlipFormItemProvider from "./form-item-providers/performance/slip/SlipFormItemProvider";
import SteamingHoursFormItemProvider from "./form-item-providers/navigation/steaming-hours/SteamingHoursFormItemProvider";
import MainEngineMCRFormItemProvider from "./form-item-providers/performance/main-engine-mcr/MainEngineMCRFormItemProvider";
import ArrivalPortETAFormItemProvider from "./form-item-providers/ports/arrival-port-eta/ArrivalPortETAFormItemProvider";
import SeaHeightFormItemProvider from "./form-item-providers/weather/sea-height/SeaHeightFormItemProvider";
import WindSpeedFormItemProvider from "./form-item-providers/weather/wind-speed/WindSpeedFormItemProvider";
import SpeedInstructionsFormItemProvider from "./form-item-providers/cp/speed-instructions/SpeedInstructionsFormItemProvider";
import AverageRPMFormItemProvider from "./form-item-providers/performance/average-rpm/AverageRPMFormItemProvider";
import CurrentRPMFormItemProvider from "./form-item-providers/performance/current-rpm/CurrentRPMFormItemProvider";
import SeaStateFormItemProvider
  from "./form-item-providers/weather/sea-state/SeaStateFormItemProvider";
import ETAPort1FormItemProvider
  from "./form-item-providers/navigation/etas/ETAPort1FormItemProvider";
import ETADate1FormItemProvider
  from "./form-item-providers/navigation/etas/ETADate1FormItemProvider";
import ETAPort2FormItemProvider
  from "./form-item-providers/navigation/etas/ETAPort2FormItemProvider";
import ETADate2FormItemProvider
  from "./form-item-providers/navigation/etas/ETADate2FormItemProvider";
import CurrentPortFormItemProvider
  from "./form-item-providers/ports/current-port/CurrentPortFormItemProvider";
import DeparturePortETDFormItemProvider
  from "./form-item-providers/ports/departure-port-etd/DeparturePortETDFormItemProvider";
import StartOfBunkeringTimeFormItemProvider
  from "./form-item-providers/bunkering/start-time/StartOfBunkeringTimeFormItemProvider";
import EndOfBunkeringTimeFormItemProvider
  from "./form-item-providers/bunkering/end-time/EndOfBunkeringTimeFormItemProvider";
import BunkersReceivedFormItemProvider
  from "./form-item-providers/bunkering/bunkers-received/BunkersReceivedFormItemProvider";
import CargoNameFormItemProvider
  from "./form-item-providers/cargo/cargo-name/CargoNameFormItemProvider";
import CommencementDateFormItemProvider
  from "./form-item-providers/cargo/commencement-date/CargoCommencementDateFormItemProvider";
import CompletionDateFormItemProvider
  from "./form-item-providers/cargo/completion-date/CargoCompletionDateFormItemProvider";
import ShipQuantityFormItemProvider
  from "./form-item-providers/cargo/ship-quantity/ShipQuantityFormItemProvider";
import ShoreQuantityFormItemProvider
  from "./form-item-providers/cargo/shore-quantity/ShoreQuantityFormItemProvider";
import BillOfLadingQuantityFormItemProvider
  from "./form-item-providers/cargo/bill-of-lading-quantity/BillOfLadingQuantityFormItemProvider";
import NamedLocationFormItemProvider
  from "./form-item-providers/position/delivery-or-redelivery-location/NamedLocationFormItemProvider";
import StatementOfFactsActivitiesFormItemProvider from "./form-item-providers/statement-of-facts/StatementOfFactsActivitiesFormItemProvider";
import NextVoyageConditionFormItemProvider from "./form-item-providers/condition/next/NextVoyageConditionFormItemProvider";
import AnchorageFormItemProvider from "./form-item-providers/ports/anchorage/AnchorageFormItemProvider";
import BerthingActionsItemsFormItemProvider from "./form-item-providers/berthing/BerthingActionsItemsFormItemProvider";
import TugCountFormItemProvider from "./form-item-providers/berthing/TugCountFormItemProvider";
import BerthNameFormItemProvider from "./form-item-providers/berthing/BerthNameFormItemProvider";
import PresentSpeedFormItemProvider from "./form-item-providers/navigation/present-speed/PresentSpeedFormItemProvider";
import TotalLoadedDischargedFormItemProvider from "./form-item-providers/cargo/quantity/TotalLoadedDischargedFormItemProvider";
import TotalToBeLoadedDischargedFormItemProvider from "./form-item-providers/cargo/quantity/TotalToBeLoadedDischargedFormItemProvider";
import TotalLoadedDischargedLastDayFormItemProvider from "./form-item-providers/cargo/quantity/TotalLoadedDischargedLastDayFormItemProvider";

export const FORM_ITEM_PROVIDERS: WrappedFormItemProviders = {
  // General
  "VesselName": { provider: VesselNameFormItemProvider },
  "IMONumber": { provider: IMONumberFormItemProvider },
  "CallSign": { provider: CallSignFormItemProvider },
  "Captain": { provider: CaptainFormItemProvider },
  "ReportDate": { provider: ReportDateFormItemProvider },
  "Remarks": { provider: RemarksFormItemProvider },
  // Position
  "Position": { provider: PositionFormItemProvider, usesFieldStates: true },
  "NamedLocation": { provider: NamedLocationFormItemProvider, usesFieldStates: true },
  // Condition
  "DraftFore": { provider: DraftForeFormItemProvider },
  "DraftMid": { provider: DraftMidFormItemProvider },
  "DraftAft": { provider: DraftAftFormItemProvider },
  "LoadCondition": { provider: LoadConditionFormItemProvider },
  "NextCondition": { provider: NextVoyageConditionFormItemProvider },
  "CargoWeight": { provider: CargoWeightFormItemProvider },
  "BallastWeight": { provider: BallastWeightFormItemProvider },
  "FreshWater": { provider: FreshWaterFormItemProvider },
  // CONSUMPTION
  "ConsumptionItems": { provider: ConsumptionItemsFormItemProvider },
  "ConsumptionItemsSOSP": { provider: ConsumptionItemsSOSPFormItemProvider },
  "ConsumptionItemsEOSP": { provider: ConsumptionItemsEOSPFormItemProvider },
  // ROB
  "ROBItems": { provider: ROBItemsFormItemProvider },
  // OrderedConsumption
  "OrderedConsumptionItems": { provider: OrderedConsumptionItemsFormItemProvider },
  // Navigation
  "Heading": { provider: HeadingFormItemProvider },
  "PresentSpeed": { provider: PresentSpeedFormItemProvider },
  "AverageSpeed": { provider: AverageSpeedFormItemProvider },
  "ObservedDistance": { provider: ObservedDistanceFormItemProvider },
  "EngineDistance": { provider: EngineDistanceFormItemProvider },
  "SteamingHours": { provider: SteamingHoursFormItemProvider },
  // ETA
  "ETAPort1": { provider: ETAPort1FormItemProvider },
  "ETADate1": { provider: ETADate1FormItemProvider },
  "ETAPort2": { provider: ETAPort2FormItemProvider },
  "ETADate2": { provider: ETADate2FormItemProvider },
  // CP
  "VoyageNumber": { provider: VoyageNumberFormItemProvider },
  "VoyageCharterer": { provider: VoyageChartererFormItemProvider },
  "CPDate": { provider: CPDateFormItemProvider },
  "OrderedSpeed": { provider: OrderedSpeedFormItemProvider },
  "SpeedInstructions": { provider: SpeedInstructionsFormItemProvider },
  "OrderedRPM": { provider: OrderedRPMFormItemProvider },
  // PORTS
  "Anchorage": { provider: AnchorageFormItemProvider },
  "CurrentPort": { provider: CurrentPortFormItemProvider },
  "DeparturePort": { provider: DeparturePortFormItemProvider },
  "DeparturePortETD": { provider: DeparturePortETDFormItemProvider },
  "ArrivalPort": { provider: ArrivalPortFormItemProvider },
  "ArrivalPortETA": { provider: ArrivalPortETAFormItemProvider },
  // BUNKERING
  "StartOfBunkeringTime": { provider: StartOfBunkeringTimeFormItemProvider },
  "EndOfBunkeringTime": { provider: EndOfBunkeringTimeFormItemProvider },
  "BunkersReceived": { provider: BunkersReceivedFormItemProvider },
  // PERFORMANCE
  "CurrentRPM": { provider: CurrentRPMFormItemProvider },
  "AverageRPM": { provider: AverageRPMFormItemProvider },
  "Slip": { provider: SlipFormItemProvider },
  "MainEnginePower": { provider: MainEnginePowerFormItemProvider },
  "MainEngineLoad": { provider: MainEngineLoadFormItemProvider },
  "MainEngineMCR": { provider: MainEngineMCRFormItemProvider },
  // WEATHER
  "SeaHeight": { provider: SeaHeightFormItemProvider },
  "SeaDirection": { provider: SeaDirectionFormItemProvider },
  "SeaState": { provider: SeaStateFormItemProvider },
  "WindForce": { provider: WindForceFormItemProvider },
  "WindSpeed": { provider: WindSpeedFormItemProvider },
  "WindDirection": { provider: WindDirectionFormItemProvider },
  "SwellHeight": { provider: SwellHeightFormItemProvider },
  "SwellDirection": { provider: SwellDirectionFormItemProvider },
  "CurrentSpeed": { provider: CurrentSpeedFormItemProvider },
  "CurrentDirection": { provider: CurrentDirectionFormItemProvider },
  "BadWeatherHours": { provider: BadWeatherHoursFormItemProvider },
  "BadWeatherDistance": { provider: BadWeatherDistanceFormItemProvider },
  "Visibility": { provider: VisibilityFormItemProvider },
  // BERTHING
  "AnchorOrBerthActionsItems": { provider: BerthingActionsItemsFormItemProvider },
  "NumberOfTugs": { provider: TugCountFormItemProvider },
  "BerthName": { provider: BerthNameFormItemProvider },
  // CARGO
  "CargoName": { provider: CargoNameFormItemProvider },
  "CargoCommencementDate": { provider: CommencementDateFormItemProvider },
  "CargoCompletionDate": { provider: CompletionDateFormItemProvider },
  "CargoShipsQuantity": { provider: ShipQuantityFormItemProvider },
  "CargoShoresQuantity": { provider: ShoreQuantityFormItemProvider },
  "CargoBillOfLadingQuantity": { provider: BillOfLadingQuantityFormItemProvider },
  "TotalLoadedDischarged": { provider: TotalLoadedDischargedFormItemProvider },
  "TotalLoadedDischargedDay": { provider: TotalLoadedDischargedLastDayFormItemProvider },
  "ToBeLoadedDischarged": { provider: TotalToBeLoadedDischargedFormItemProvider },
  // STATEMENT OF FACTS
  "StatementOfFactsActivities": { provider: StatementOfFactsActivitiesFormItemProvider },
};
