import React from "react";

const NotFoundPage: React.FC = () => {
  return (
    <div style={{
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: "200px",
      fontSize: "1.5em",
    }}>
      Page not found
    </div>
  );
};

export default NotFoundPage;