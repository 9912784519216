import React from "react";
import {
  NavLink,
  Link,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faFilePlus } from "@fortawesome/pro-duotone-svg-icons/faFilePlus";
import { faEnvelope } from "@fortawesome/pro-duotone-svg-icons/faEnvelope";
import { faList } from "@fortawesome/pro-duotone-svg-icons/faList";
import {
  Col, Grid, Row, Space, 
} from "antd";
import Routes from "../routes";
import ConnectionIndicator from "./ConnectionIndicator";
import LogoutButton from "./LogoutButton";
import {
  APP_NAME,
} from "../../../shared/utils/constants";

const StyledHeader = styled.div`
  display: flex;
  background-color: hsl(209, 100%, 15%);
  align-items: center;
  padding: 10px;
  
  & > :first-child {
    display: flex;
    flex: 1 1 0px;
  }
 
  & > div > a {
    text-align: center;
    font-weight: 500;
    font-size: 1.3em;
    color: hsl(0, 0%, 80%);
  }
  
  & > div > a:hover {
    color: hsl(0, 0%, 95%);
  }

  & > div > a:hover span {
    text-decoration: underline;
  }
  
  & > div > a span {
    margin-left: 5px;
  }
  
  & > div > a > div {
    padding: 2.5px 25px;
  }
  
  & > div > a.active > div {
    color: hsl(0, 0%, 90%);
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
  }
  
  & > div > h1 {
    font-size: 1.7em;
    margin: 0 20px 0 0;
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
  
  & > div > h1 > a {
    color: hsl(0, 0%, 100%);
  }
`;

const MobileHeader = styled.div`
  background-color: white;
  padding:15px;
  border-bottom: 1px solid hsl(0deg 0% 90%)
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  z-index: 1000;
`;

const Header: React.FC = () => {
  const screens = Grid.useBreakpoint();

  if (screens.xs) {
    return (
      <MobileHeader>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Space size="large">
              <NavLink to={Routes.SelectReport.generate()}>
                <div><FontAwesomeIcon icon={faFilePlus} /> <span>New Report</span></div>
              </NavLink>
              <NavLink to={Routes.ReportsList.generate()}>
                <div><FontAwesomeIcon icon={faList} /> <span>Previous Reports</span></div>
              </NavLink>
              <LogoutButton />
            </Space>
          </Col>
          <Col span={24}>
            <ConnectionIndicator />
          </Col>
        </Row>
      </MobileHeader>
    );
  } 
  return (
    <StyledHeader>
      <div>
        <h1><Link to={Routes.Home.generate()}>{APP_NAME}</Link></h1>
        <NavLink to={Routes.SelectReport.generate()}>
          <div><FontAwesomeIcon icon={faFilePlus} /> <span>New Report</span></div>
        </NavLink>
        <NavLink to={Routes.ReportsList.generate()}>
          <div><FontAwesomeIcon icon={faList} /> <span>Previous Reports</span></div>
        </NavLink>
        <NavLink to={Routes.Support.generate()}>
          <div><FontAwesomeIcon icon={faEnvelope} /> <span>Support</span></div>
        </NavLink>
      </div>
      <ConnectionIndicator />
      <LogoutButton />
    </StyledHeader>
  );
};

export default Header;