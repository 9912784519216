import { Result } from "neverthrow";
import {
  serializableDecorator,
  SerializationError,
  Serialized,
} from "../../shared/utils/serialization";
import { NumberUnit } from "./NumberUnit";

@serializableDecorator<Minutes>()
export class Minutes extends NumberUnit {
  static serialize(unit: Minutes): Result<Serialized, SerializationError> {
    return super._serialize(unit, Minutes);
  }

  static deserialize(serialized: Serialized): Result<Minutes, SerializationError> {
    return super._deserialize(serialized, Minutes);
  }
}