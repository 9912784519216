import React from "react";
import styled from "styled-components";
import { red } from "@ant-design/colors";
import RequiredError from "../../../domain/errors/RequiredError";
import BaseError from "../../../shared/errors/BaseError";

const StyledErrorWrapper = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  
  ${({ isError }): any => isError && `
    & input.error,
    & .error:not(.ant-picker) input,
    & .ant-picker.error,
    & .error .ant-radio-inner,
    & .error .ant-select-selector,
    & .error .ant-table {
      border: 1px solid red;
    }
  `}
`;

const Errors = styled.div`
  color: ${red.primary};
  display: flex;
  flex-direction: column;
  
  & > span {
    white-space: pre-line;
  }
`;

const ErrorWrapper: React.FC<{
  isError: boolean,
  errors?: BaseError[]
  displayRequiredErrors?: boolean,
  defaultErrorText?: string,
}> = ({
  isError,
  errors,
  displayRequiredErrors = false,
  defaultErrorText = "An error occurred.",
  children,
}) => {
  const userFacingErrors = (errors ?? [])
    .filter((e) => e.userFacing);
  const notRequiredErrors = userFacingErrors
    .filter((e) => displayRequiredErrors || !(e instanceof RequiredError));

  return (
    <StyledErrorWrapper isError={isError}>
      {children}
      {isError && (
        <Errors>
          {
            notRequiredErrors
              .map((e) => (
                <span key={e.message}>{e.message}</span>
              ))
          }
          {
            (userFacingErrors.length === 0 && errors !== undefined && errors.length > 0) && (
              <span>{defaultErrorText}</span>
            )
          }
        </Errors>
      )}
    </StyledErrorWrapper>
  );
};

export default ErrorWrapper;