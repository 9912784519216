import styled from "styled-components";

export const ReportGroupBox = styled.div`
    padding: 15px 25px 15px 25px;
    background: white;
    border-radius: 5px;
    border: 1px solid hsl(0deg 0% 90%);
`;

export const ReportGroupBoxContents = styled.div`

`;