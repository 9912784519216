import React from "react";
import { red } from "@ant-design/colors";

const RequiredStar: React.FC = () => (
  <span style={{
    color: red.primary,
  }}>
    *
  </span>
);

export default RequiredStar;