import React, { useEffect } from "react";
import { Input } from "antd";

import useUndo from "../hooks/useUndo";

type Props = {
  placeholder: string,
  isError: boolean,
  value: string | undefined,
  onChange: (value: string | undefined) => void,
  textarea?: boolean,
  capitalize?: boolean,
  disabled?: boolean,
};

/* ------------------------------------------------------------ */

const TextInput: React.FC<Props> = (props) => {
  const TextInputComponent = props.textarea ? Input.TextArea : Input;

  const onChange = (newValue: string): void => {
    props.onChange(newValue === "" ? undefined : newValue);
  };

  const [handleChange, handleKeyDown, addNewValue] = useUndo(onChange, props.value);

  useEffect(() => addNewValue(props.value), [props.value]);

  return (
    <TextInputComponent
      className={props.isError ? "error" : undefined}
      placeholder={props.placeholder}
      value={props.value ?? ""}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      rows={props.textarea ? 4 : undefined}
      disabled={props.disabled}
    />
  );
};

export default TextInput;