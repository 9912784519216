import TextReportGenerator from "../TextReportGenerator";
import ReportData from "../../ReportData";
import { dedent } from "../../../../shared/utils/indentation";
import { saveClassByClassNameDecorator } from "../../../../shared/utils/classNameToConstructor";
import { DisplayedField } from "../../DisplayedField";

const DEFAULT_VERSION = "1";

const TITLE: string = "Test Report";

const FIELDS: DisplayedField[] = [
  { fieldType: "VesselName", required: true },
  { fieldType: "Captain", required: true },
  { fieldType: "VoyageNumber", required: false },
  { fieldType: "Position", required: true },
];

@saveClassByClassNameDecorator()
class TestTextReportGenerator extends TextReportGenerator {
  public constructor() {
    super(TITLE, FIELDS, DEFAULT_VERSION);
  }

  getReportText(data: ReportData, _version: string | undefined): string {
    const text = dedent(`
      ----- General -----
      Vessel: ${data.VesselName ?? ""}
      Captain: ${data.Captain ?? ""}
      Voyage Number: ${data.VoyageNumber ?? ""}
      
      ----- Position -----
      Lat: ${data.Position?.lat.toDMSString() ?? ""}
      Long ${data.Position?.long.toDMSString() ?? ""}
    `);

    return text;
  }
}

export default TestTextReportGenerator;