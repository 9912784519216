import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { CargoQuantity } from "../../../../../../domain/units/CargoQuantity";
import GenericQuantityFormItemProvider
  from "../../_generic/quantity/GenericQuantityFormItemProvider";

const fieldName = "Quantity by ship's figure";

const ShipQuantityFormItemProvider: FormItemProvider<CargoQuantity> = (props: FormItemProviderProps<CargoQuantity>) => (
  <GenericQuantityFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="CargoShipsQuantity"
  />
);

export default React.memo(ShipQuantityFormItemProvider);
