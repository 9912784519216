import React from "react";
import ErrorWrapper from "../../../ErrorWrapper";
import {
  useSingleFormItem,
  UseSingleFormItemProps,
} from "../../../useSingleFormItem";
import { FormItemProps } from "../../../FormItem";
import TextInput from "../../../TextInput";

type Props =
  & UseSingleFormItemProps<string | undefined, string | undefined>
  & FormItemProps<string | undefined>;

const LocationNameInput: React.FC<Props> = (props) => {
  const hook = useSingleFormItem<string | undefined, string | undefined>(props);

  return (
    <ErrorWrapper
      isError={hook.isError}
      errors={hook.error}
    >
      <TextInput
        placeholder="Custom Location"
        value={hook.input}
        onChange={hook.onInputChange}
        isError={hook.isError}
      />
    </ErrorWrapper>
  );
};

export default LocationNameInput;