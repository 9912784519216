import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import CallSign from "../../../../../../domain/CallSign";
import GenericTextFormItemProvider from "../../_generic/text/GenericTextFormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Call Sign";

const CallSignFormItemProvider: FormItemProvider<CallSign> = (props: FormItemProviderProps<CallSign>) => (
  <GenericTextFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="CallSign"
    autoFillPrevious={true}
    suggestPrevious={true}
    outputToSuggestionText={unwrapValue}
    inputToResult={notEmptyInputToResultWrapper(fieldName, CallSign.fromString)}
    outputToInput={unwrapValue}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(CallSignFormItemProvider);