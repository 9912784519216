import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { CargoQuantity } from "../../../../../../domain/units/CargoQuantity";
import GenericQuantityFormItemProvider
  from "../../_generic/quantity/GenericQuantityFormItemProvider";

const fieldName = "Total loaded/discharged";

const TotalLoadedDischargedFormItemProvider: FormItemProvider<CargoQuantity> = (props: FormItemProviderProps<CargoQuantity>) => (
  <GenericQuantityFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="TotalLoadedDischarged"
  />
);

export default React.memo(TotalLoadedDischargedFormItemProvider);
