import React, { CSSProperties } from "react";
import {
  Button,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  loading?: boolean,
  onClick?: () => void,
  icon: IconProp,
  type?: "default" | "primary" | "ghost" | "dashed" | "link" | "text",
  danger?: boolean,
  shape?: "circle" | "round",
  disabled?: boolean,
  style?: CSSProperties,
  href?: string,
  download?: string,
  size?: "large" | "middle" | "small",
  target?: string,
};

const IconButton: React.FC<Props> = ({
  icon,
  children,
  ...buttonProps
}) => (
  <Button
    {...buttonProps}
    icon={(
      <span style={{
        marginRight: children !== undefined ? "10px" : undefined,
      }}>
        <FontAwesomeIcon
          icon={icon}
          fixedWidth={true}
        />
      </span>
    )}
  >
    {children}
  </Button>
);

export default IconButton;
