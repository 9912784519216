import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIsVisible } from "../../hooks/useIsVisible";
import {
  DisplayedField,
  DisplayedFieldGroup,
} from "../../../../domain/reports/DisplayedField";
import { FieldStates } from "./fieldStates";

type FillStatus = "wait" | "process" | "finish" | "error";

export type FieldGroupWithStatus = DisplayedFieldGroup & {
  fillStatus: FillStatus,
};

type UseProgressStepsProps = {
  displayedFieldGroups: DisplayedFieldGroup[],
  fieldStates: FieldStates | undefined,
};

type UseProgressStepsHook = {
  onProgressStepClick: (newCurrentStep: number) => void,
  currentProgressStep: number,
  headersListRef: React.MutableRefObject<Element[]>,
  displayedFieldGroupsWithStatus: FieldGroupWithStatus[],
};

const getFieldGroupStatus = (
  fieldGroup: DisplayedFieldGroup,
  fieldStates: FieldStates | undefined,
  currentStep: number,
  groupStep: number,
): FillStatus => {
  if (fieldStates === undefined) {
    return "wait";
  }

  const fieldsInputsInGroup = fieldGroup.subGroups
    .map((sg) => sg.fields.map((field: DisplayedField) => fieldStates[field.fieldType]))
    .flat();

  if (fieldsInputsInGroup.some((fieldInput) => (
    (fieldInput.result?.isErr() && fieldInput.dirty.value) ||
    (fieldInput.result === undefined && fieldInput.dirty.value && fieldInput.required)
  ))) {
    return "error";
  }
  if (fieldsInputsInGroup.every((fieldInput) => fieldInput.result?.isOk() || !fieldInput.required)) {
    return "finish";
  }
  if (currentStep === groupStep) {
    return "process";
  }
  return "wait";
};

export const useProgressSteps = (props: UseProgressStepsProps): UseProgressStepsHook => {
  const [currentProgressStep, setCurrentProgressStep] = useState(0);
  const [isScrollingIntoView, setIsScrollingIntoView] = useState(false);
  const [isScrollingIntoViewSalt, setIsScrollingIntoViewSalt] = useState(Math.random());

  const headersListRef = useRef<HTMLHeadingElement[]>([...new Array(props.displayedFieldGroups.length)]);

  const isVisibleList = headersListRef.current.map((ref) => useIsVisible(ref));

  const displayedFieldGroupsWithStatus: FieldGroupWithStatus[] = useMemo(
    () => props.displayedFieldGroups.map((fieldGroup, i) => ({
      ...fieldGroup,
      fillStatus: getFieldGroupStatus(fieldGroup, props.fieldStates, currentProgressStep, i),
    })),
    [props.displayedFieldGroups, props.fieldStates, currentProgressStep],
  );
  
  // Update current step based on which groups are visible
  useEffect(() => {
    const newCurrentStep = isVisibleList.reduce<number | undefined>((acc, isVisible, i) => {
      if (isVisible && (acc === undefined || i < acc)) {
        return i;
      }
      return acc;
    }, undefined);
    if (newCurrentStep === undefined) {
      return;
    }
    if (isScrollingIntoView) {
      return;
    }
    setCurrentProgressStep(newCurrentStep);
  }, [JSON.stringify(isVisibleList)]);

  // Set "isScrollingIntoView" after delay
  useEffect(() => {
    if (!isScrollingIntoView) {
      return (): void => {};
    }

    const timeout = setTimeout(() => {
      setIsScrollingIntoView(false);
    }, 1000);

    return (): void => clearTimeout(timeout);
  }, [isScrollingIntoView, isScrollingIntoViewSalt]);

  const onProgressStepClick = (newCurrentStep: number): void => {
    setCurrentProgressStep(newCurrentStep);
    setIsScrollingIntoView(true);
    setIsScrollingIntoViewSalt(Math.random());
    headersListRef.current?.[newCurrentStep]?.scrollIntoView({ behavior: "smooth" });
  };

  return {
    currentProgressStep,
    onProgressStepClick,
    headersListRef,
    displayedFieldGroupsWithStatus,
  };
};