import React from "react";
import styled from "styled-components";
import { SUPPORT_EMAIL } from "../../../../shared/utils/constants";

type SupportProps = {};

type SupportHook = {};

export const useSupportPage = (_props: SupportProps): SupportHook => {
  return {};
};

/* ------------------------------------------------------------ */

const StyledSupportPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
  }
  
  & > div > p {
    font-size: 1.2em;
  }
`;

const SupportPage: React.FC<SupportProps> = (props) => {
  const _hook = useSupportPage(props);

  return (
    <StyledSupportPage>
      <div>
        <h1>Support</h1>
        <p>If you have any questions or feedback, please contact support at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.</p>
      </div>
    </StyledSupportPage>
  );
};

export default SupportPage;