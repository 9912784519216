import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import Direction from "../../../../../../domain/Direction";
import { Degrees } from "../../../../../../domain/units/Degrees";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Present Course";

const HeadingFormItemProvider: FormItemProvider<Direction> = (props: FormItemProviderProps<Direction>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="Heading"
    addonAfter="°"
    allowNegative={false}
    inputToResult={
      notEmptyInputToResultWrapper(
        fieldName,
        (input: number) => Direction.fromDegrees(new Degrees(input), fieldName),
      )
    }
    outputToInput={(output: Direction): number => output.toDegrees().value}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(HeadingFormItemProvider);