import _ from "lodash";
import ReportFormatConfig from "../../../domain/reports/ReportFormatConfig";
import {
  FieldTypeGroup,
  FieldTypeSubGroup,
} from "../../../domain/reports/FieldGroup";
import {
  DisplayedField,
  DisplayedFieldGroup,
  DisplayedFieldSubGroup,
} from "../../../domain/reports/DisplayedField";

const GLOBALLY_REQUIRED_FIELDS: DisplayedField[] = [
  { fieldType: "ReportDate", required: true },
  { fieldType: "IMONumber", required: true },
];

export const getDisplayedFields = (reportFormatConfigs: ReportFormatConfig[]): DisplayedField[] => {
  const formatFields = reportFormatConfigs.reduce((fields: DisplayedField[], reportFormatConfig) => {
    return [
      ...fields,
      ...reportFormatConfig.generator.fields,
    ];
  }, []);
  const allFields = [...formatFields, ...GLOBALLY_REQUIRED_FIELDS];
  return _.uniqBy(allFields, (field: DisplayedField) => field.fieldType);
};

export const getFieldGroupsToDisplay = (
  formStructure: FieldTypeGroup[],
  fieldsToDisplay: DisplayedField[],
): DisplayedFieldGroup[] => {
  return formStructure
    .reduce((fieldGroups: DisplayedFieldGroup[], fieldTypeGroup: FieldTypeGroup) => {
      const displayedSubGroups = fieldTypeGroup.subGroups
        .reduce((fieldSubGroups: DisplayedFieldSubGroup[], fieldTypeSubGroup: FieldTypeSubGroup) => {
          const subGroupFieldsToDisplay = fieldTypeSubGroup.fieldTypes.reduce((acc: DisplayedField[], ft) => {
            const field = fieldsToDisplay.find((f) => f.fieldType === ft);
            if (field === undefined) {
              return acc;
            }
            return [...acc, field];
          }, []);

          if (subGroupFieldsToDisplay.length === 0) {
            return fieldSubGroups;
          }

          const newFieldSubGroup: DisplayedFieldSubGroup = {
            fields: subGroupFieldsToDisplay,
          };

          return [...fieldSubGroups, newFieldSubGroup];
        }, []);

      if (displayedSubGroups.length === 0) {
        return fieldGroups;
      }

      const newFieldGroup: DisplayedFieldGroup = {
        header: fieldTypeGroup.header,
        subGroups: displayedSubGroups,
      };

      return [...fieldGroups, newFieldGroup];
    }, []);
};
