import {
  err,
  ok,
  Result,
} from "neverthrow";
import { SerializationError } from "./serialization";

const classNameToConstructorMap: { [key: string]: (new () => any) } = {};

export function getClassByClassName<T>(className: string, Type: Function): Result<T, SerializationError> {
  const constructor = classNameToConstructorMap[className];
  if (constructor === undefined) {
    return err(new SerializationError(`No constructor found for class name "${className}" Perhaps the side effect was "optimized" away by the compiler, in which case you can explicitly run them by importing the module, like in "/domain/reports/generators/__side-effects.ts".`));
  }
  const instance = new constructor();
  if (!(instance instanceof Type)) {
    return err(new SerializationError(`Instantiated class ${instance.constructor.name} is not a ${Type.name}.`));
  }
  return ok(instance as T);
}

export function saveClassByClassNameDecorator() {
  return (constructor: (new () => any)): void => {
    classNameToConstructorMap[constructor.name] = constructor;
  };
}
