import {
  useCallback,
  useEffect,
  useState,
} from "react";
import { Result } from "neverthrow";
import logger from "../../../shared/utils/logger";

type UseFetchOptions = {
  onlySetLoadingFirstFetch?: boolean,
};

const DEFAULT_FETCH_OPTIONS: UseFetchOptions = {
  onlySetLoadingFirstFetch: false,
};

const useFetch = <T, E>(fetchData: () => Promise<Result<T, E>>, options: UseFetchOptions = DEFAULT_FETCH_OPTIONS): {
  data?: T,
  loading: boolean,
  error?: E,
  refresh: () => void,
} => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<E>();
  const [salt, setSalt] = useState<number>(Math.random());
  const [_latestRequestId, setLatestRequestId] = useState<number>();

  const ensureLatestRequest = (requestId: number, callback: () => void): void => {
    setLatestRequestId((latestRequestId) => {
      if (requestId === latestRequestId) {
        callback();
      }
      return latestRequestId;
    });
  };

  const handle = useCallback(async () => {
    if (salt === undefined || !options.onlySetLoadingFirstFetch) {
      setLoading(true);
    }
    setError(undefined);
    const requestId = Math.random();
    setLatestRequestId(requestId);

    (await fetchData()).match(
      (fetchedData: T) => {
        ensureLatestRequest(requestId, () => {
          setData(fetchedData);
          setError(undefined);
          setLoading(false);
        });
      },
      (fetchError: E) => {
        ensureLatestRequest(requestId, () => {
          logger.error("Error during fetch.", fetchError);
          setError(fetchError);
          setData(undefined);
          setLoading(false);
        });
      },
    );
  }, [salt, fetchData]);

  const refresh = (): void => {
    setSalt(Math.random());
  };

  useEffect(() => {
    handle();
  }, [handle]);

  return {
    data,
    loading,
    error,
    refresh,
  };
};

export default useFetch;