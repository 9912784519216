import { ok } from "neverthrow";
import React from "react";
import {
  FormItemProvider,
  FormItemProviderProps,
} from "../../../FormItemProvider";
import { notEmptyInputToResultWrapper } from "../../../InputToResult";
import { NauticalMiles } from "../../../../../../domain/units/NauticalMiles";
import GenericNumberFormItemProvider from "../../_generic/number/GenericNumberFormItemProvider";
import { unwrapValue } from "../../../../../../shared/utils/unwrapValue";
import { eqeqeq } from "../../../../../../shared/utils/equality";

const fieldName = "Observed Distance";

const ObservedDistanceFormItemProvider: FormItemProvider<NauticalMiles> = (props: FormItemProviderProps<NauticalMiles>) => (
  <GenericNumberFormItemProvider
    {...props}
    fieldName={fieldName}
    fieldType="ObservedDistance"
    addonAfter="NM"
    allowNegative={false}
    integersOnly={false}
    inputToResult={notEmptyInputToResultWrapper(fieldName, (input: number) => ok(new NauticalMiles(input)))}
    outputToInput={unwrapValue}
    inputsEqual={eqeqeq}
  />
);

export default React.memo(ObservedDistanceFormItemProvider);